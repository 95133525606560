
import { inject, Injectable } from '@angular/core';

import { MomentTimezoneService } from '@bp/frontend/features/moment/services';

@Injectable({
	providedIn: 'root',
})
export class MomentBasePipe {

	protected _momentTimezoneService = inject(MomentTimezoneService);

}
