import { AsyncSubject } from 'rxjs';

export class AsyncVoidSubject extends AsyncSubject<void> {

	override complete(): void {
		// eslint-disable-next-line unicorn/no-useless-undefined
		this.next(undefined);

		super.complete();
	}

}
