import { timer } from 'rxjs';
import { mergeMap, map, startWith } from 'rxjs/operators';

import { Component, ChangeDetectionStrategy } from '@angular/core';

import { FADE } from '@bp/frontend/animations';

const PULSATING_DURATION = 30 * 1000;
const PULSATING_REPEAT_IN = 5 * 60 * 1000;

@Component({
	selector: 'bp-attention-drawing-dot',
	templateUrl: './attention-drawing-dot.component.html',
	styleUrls: [ './attention-drawing-dot.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class AttentionDrawingDotComponent {

	protected readonly _showPulsation$ = timer(0, PULSATING_REPEAT_IN)
		.pipe(mergeMap(() => timer(PULSATING_DURATION).pipe(
			map(() => false),
			startWith(true),
		)));

}
