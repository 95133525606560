/* eslint-disable rxjs/no-internal */
import { Scheduler } from 'rxjs/internal/Scheduler';
import type { Subscription } from 'rxjs/internal/Subscription';
import { Action } from 'rxjs/internal/scheduler/Action';

import { ZoneService } from '../zone.service';

class OutsideAngularAction<T> extends Action<T> {
	constructor(
		// eslint-disable-next-line @typescript-eslint/naming-convention
		protected scheduler: Scheduler,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		protected job: (this: Action<T>, state?: T) => void,
	) {
		super(scheduler, job);
	}

	override schedule(state?: T): Subscription {
		if (this.closed)
			return this;

		if (ZoneService.isInAngularZone())
			ZoneService.runOutsideAngular(() => void this.job(state));
		else
			this.job(state);

		return this;
	}
}

export const outside = new Scheduler(OutsideAngularAction);
