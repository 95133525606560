import { inject, OnChanges, Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { MatRipple } from '@angular/material/core';

import { $ } from '@bp/frontend/utilities/dom';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'a[disabled]',
})
export class DisabledDirective implements OnChanges {

	private readonly __matRippleDirective = inject(MatRipple, { optional: true });

	private readonly __$host = <HTMLElement>inject(ElementRef).nativeElement;

	@Input() disabled!: boolean | '' | null;

	@HostBinding('class.disabled')
	@Input()
	bpDisabled!: boolean | '';

	private get __$veil(): HTMLElement {
		return (this.__$cachedVeil ??= this.__createVeil());
	}

	private __$cachedVeil?: HTMLElement;

	private __storedPointerEventsStyle!: string | null;

	private __storedTabIndex!: string | null;

	private __veiled = false;

	ngOnChanges(): void {
		if (!!this.disabled || !!this.bpDisabled)
			this.__setVeil();
		else
			this.__removeVeil();
	}

	private __setVeil(): void {
		if (this.__veiled)
			return;

		// TODO The tabindex -1 removes only the host from the pages tab order, children can be tabbed
		this.__storedPointerEventsStyle = this.__$host.style.pointerEvents;

		this.__storedTabIndex = this.__$host.getAttribute('tabindex');

		this.__$host.setAttribute('tabindex', '-1');

		this.__$host.setAttribute('disabled', '');

		if (getComputedStyle(this.__$host).position === 'static')
			this.__$host.style.position = 'relative';

		this.__$host.style.pointerEvents = 'none';

		this.__$host.append(this.__$veil);

		if (this.__matRippleDirective)
			this.__matRippleDirective.disabled = true;

		this.__veiled = true;
	}

	private __removeVeil(): void {
		if (!this.__veiled)
			return;

		this.__$host.style.pointerEvents = this.__storedPointerEventsStyle!;

		if (this.__storedTabIndex === null)
			this.__$host.removeAttribute('tabindex');
		else
			this.__$host.setAttribute('tabindex', this.__storedTabIndex);

		this.__$host.removeAttribute('disabled');

		this.__$veil.remove();

		this.__veiled = false;

		if (this.__matRippleDirective)
			this.__matRippleDirective.disabled = false;
	}

	private __createVeil(): HTMLSpanElement {
		const $veil = document.createElement('span');

		$.css($veil, {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			'pointer-events': 'all',
			cursor: 'not-allowed',
		});

		$veil.addEventListener('click', clickEvent => {
			clickEvent.stopPropagation();

			clickEvent.preventDefault();
		});

		return $veil;
	}
}
