import { inRange } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({ name: 'bpInRange' })
export class InRangePipe implements PipeTransform {

	transform(value: number, start: number, end?: number): boolean {
		return inRange(value, start, end);
	}

}
