import { BehaviorSubject } from 'rxjs';

import { ZoneService } from '../zone.service';

export class NgZoneBehaviorSubject<T> extends BehaviorSubject<T> {

	constructor(value?: T) {
		super(value!);
	}

	override next(value: T): void {
		ZoneService.runInAngularZone(() => void super.next(value));
	}

}
