/* eslint-disable @typescript-eslint/naming-convention */
export default {
	AFN: '971',
	EUR: '978',
	ALL: '008',
	DZD: '012',
	USD: '840',
	AOA: '973',
	XCD: '951',
	ARS: '032',
	AMD: '051',
	AWG: '533',
	AUD: '036',
	AZN: '944',
	BSD: '044',
	BHD: '048',
	BDT: '050',
	BBD: '052',
	BYN: '933',
	BZD: '084',
	XOF: '952',
	BMD: '060',
	INR: '356',
	BTN: '064',
	BOB: '068',
	BOV: '984',
	BAM: '977',
	BWP: '072',
	NOK: '578',
	BRL: '986',
	BND: '096',
	BGN: '975',
	BIF: '108',
	CVE: '132',
	KHR: '116',
	XAF: '950',
	CAD: '124',
	KYD: '136',
	CLP: '152',
	CLF: '990',
	CNY: '156',
	COP: '170',
	COU: '970',
	KMF: '174',
	CDF: '976',
	NZD: '554',
	CRC: '188',
	HRK: '191',
	CUP: '192',
	CUC: '931',
	ANG: '532',
	CZK: '203',
	DKK: '208',
	DJF: '262',
	DOP: '214',
	EGP: '818',
	SVC: '222',
	ERN: '232',
	SZL: '748',
	ETB: '230',
	FKP: '238',
	FJD: '242',
	XPF: '953',
	GMD: '270',
	GEL: '981',
	GHS: '936',
	GIP: '292',
	GTQ: '320',
	GBP: '826',
	GNF: '324',
	GYD: '328',
	HTG: '332',
	HNL: '340',
	HKD: '344',
	HUF: '348',
	ISK: '352',
	IDR: '360',
	XDR: '960',
	IRR: '364',
	IQD: '368',
	ILS: '376',
	JMD: '388',
	JPY: '392',
	JOD: '400',
	KZT: '398',
	KES: '404',
	KPW: '408',
	KRW: '410',
	KWD: '414',
	KGS: '417',
	LAK: '418',
	LBP: '422',
	LSL: '426',
	ZAR: '710',
	LRD: '430',
	LYD: '434',
	CHF: '756',
	MOP: '446',
	MKD: '807',
	MGA: '969',
	MWK: '454',
	MYR: '458',
	MVR: '462',
	MRU: '929',
	MUR: '480',
	XUA: '965',
	MXN: '484',
	MXV: '979',
	MDL: '498',
	MNT: '496',
	MAD: '504',
	MZN: '943',
	MMK: '104',
	NAD: '516',
	NPR: '524',
	NIO: '558',
	NGN: '566',
	OMR: '512',
	PKR: '586',
	PAB: '590',
	PGK: '598',
	PYG: '600',
	PEN: '604',
	PHP: '608',
	PLN: '985',
	QAR: '634',
	RON: '946',
	RUB: '643',
	RWF: '646',
	SHP: '654',
	WST: '882',
	STN: '930',
	SAR: '682',
	RSD: '941',
	SCR: '690',
	SLL: '694',
	SGD: '702',
	XSU: '994',
	SBD: '090',
	SOS: '706',
	SSP: '728',
	LKR: '144',
	SDG: '938',
	SRD: '968',
	SEK: '752',
	CHE: '947',
	CHW: '948',
	SYP: '760',
	TWD: '901',
	TJS: '972',
	TZS: '834',
	THB: '764',
	TOP: '776',
	TTD: '780',
	TND: '788',
	TRY: '949',
	TMT: '934',
	UGX: '800',
	UAH: '980',
	AED: '784',
	USN: '997',
	UYU: '858',
	UYI: '940',
	UYW: '927',
	UZS: '860',
	VUV: '548',
	VES: '928',
	VND: '704',
	YER: '886',
	ZMW: '967',
	ZWL: '932',
	AFA: '004',
	FIM: '246',
	ALK: '008',
	ADP: '020',
	ESP: '724',
	FRF: '250',
	AOK: '024',
	AON: '024',
	AOR: '982',
	ARA: '032',
	ARP: '032',
	ARY: '032',
	RUR: '810',
	ATS: '040',
	AYM: '945',
	AZM: '031',
	BYB: '112',
	BYR: '974',
	BEC: '993',
	BEF: '056',
	BEL: '992',
	BOP: '068',
	BAD: '070',
	BRB: '076',
	BRC: '076',
	BRE: '076',
	BRN: '076',
	BRR: '987',
	BGJ: '100',
	BGK: '100',
	BGL: '100',
	BUK: '104',
	HRD: '191',
	CYP: '196',
	CSJ: '203',
	CSK: '200',
	ECS: '218',
	ECV: '983',
	GQE: '226',
	EEK: '233',
	XEU: '954',
	GEK: '268',
	DDM: '278',
	DEM: '276',
	GHC: '288',
	GHP: '939',
	GRD: '300',
	GNE: '324',
	GNS: '324',
	GWE: '624',
	GWP: '624',
	ITL: '380',
	ISJ: '352',
	IEP: '372',
	ILP: '376',
	ILR: '376',
	LAJ: '418',
	LVL: '428',
	LVR: '428',
	LSM: '426',
	ZAL: '991',
	LTL: '440',
	LTT: '440',
	LUC: '989',
	LUF: '442',
	LUL: '988',
	MGF: '450',
	MVQ: '462',
	MLF: '466',
	MTL: '470',
	MTP: '470',
	MRO: '478',
	MXP: '484',
	MZE: '508',
	MZM: '508',
	NLG: '528',
	NIC: '558',
	PEH: '604',
	PEI: '604',
	PES: '604',
	PLZ: '616',
	PTE: '620',
	ROK: '642',
	ROL: '642',
	STD: '678',
	CSD: '891',
	SKK: '703',
	SIT: '705',
	RHD: '716',
	ESA: '996',
	ESB: '995',
	SDD: '736',
	SDP: '736',
	SRG: '740',
	CHC: '948',
	TJR: '762',
	TPE: '626',
	TRL: '792',
	TMM: '795',
	UGS: '800',
	UGW: '800',
	UAK: '804',
	SUR: '810',
	USS: '998',
	UYN: '858',
	UYP: '858',
	VEB: '862',
	VEF: '937',
	VNC: '704',
	YDD: '720',
	YUD: '890',
	YUM: '891',
	YUN: '890',
	ZRN: '180',
	ZRZ: '180',
	ZMK: '894',
	ZWC: '716',
	ZWD: '716',
	ZWN: '942',
	ZWR: '935',
};
