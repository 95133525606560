import { isObject } from 'lodash-es';

import { HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { toPlainObject } from '@bp/shared/utilities/core';
import { transformKeysToCase } from '@bp/shared/models/common';

import { TurnstileService } from '@bp/frontend/features/turnstile';

import { HttpConfigService } from './http-config.service';
import { HttpConfig } from './models';

@Injectable()
export abstract class HttpBaseInterceptorService {

	protected readonly _httpConfigService = inject(HttpConfigService);

	protected readonly _turnstileService = inject(TurnstileService);

	protected _buildHttpEventBody<T>(
		httpEvent: HttpRequest<T> | HttpResponse<T>,
		config?: HttpConfig,
	): T | null {
		const { body } = httpEvent;

		if (!isObject(body) || body instanceof Blob)
			return body;

		let plainObjectBody = toPlainObject(body);

		if (config?.bodyNamingStrategy) {
			plainObjectBody = transformKeysToCase(
				plainObjectBody,
				httpEvent instanceof HttpRequest ? config.bodyNamingStrategy : 'camelcase',
			);
		}

		return plainObjectBody;
	}

}
