<span class="flag-wrapper" [bpTooltip]="compact && country && !hideTooltip ? country.name : ''">
	<bp-svg-icon
		*ngIf="worldwide || countryIsWorldwide; else defaultTpl"
		name="earth"
		class="flag"
		[ngClass]="{ round: round }"
	/>

	<ng-template #defaultTpl>
		<span class="flag fi fi-{{ country?.lowerCaseCode || '' }}" [ngClass]="{ 'fis round': round }"></span>
	</ng-template>
</span>

<span *ngIf="!compact" class="capitalize country-name">
	{{ country?.name }}
</span>
