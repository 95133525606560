import { of, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { ERRORS_MAP, ErrorsMapKey, IValidationErrors } from '@bp/shared/features/validation/models';

import { ErrorsTextsProviderBaseService } from './errors-texts-provider-base.service';

@Injectable({ providedIn: 'root' })
export class ErrorsTextsProviderService extends ErrorsTextsProviderBaseService {

	renderFirstErrorTextAndObserveChanges(
		validationErrors: IValidationErrors,
		controlName?: number | string | null,
	): Observable<string> {
		return of(this.renderFirstErrorText(validationErrors, controlName));
	}

	renderFirstErrorText(
		validationErrors: IValidationErrors,
		controlName?: number | string | null,
	): string {
		return this._renderValidationErrorsAsTexts(validationErrors, controlName)[0];
	}

	protected _fetchErrorTextWithMasks(validatorName: string, controlName?: number | string | null): string | null {
		const controlSpecificError: string | null = controlName
			? ERRORS_MAP[<ErrorsMapKey>`${ validatorName }.${ controlName }`]
			: null;

		const generalError = <string | undefined>ERRORS_MAP[<ErrorsMapKey>validatorName];

		return controlSpecificError ?? generalError ?? null;

	}

}
