import { ToastrModule } from 'ngx-toastr';

import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import {
	MatLegacyProgressSpinnerModule as MatProgressSpinnerModule
} from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { RouterModule } from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { SharedFeaturesMomentPipesModule } from '@bp/frontend/features/moment/pipes';
import { PaymentCardBrandIconComponent } from '@bp/frontend/components/payment-card/brand';

import { AlertComponent, AlertMessagesComponent, ErrorMessagesComponent } from './alert';
import {
	BurgerBtnComponent as BurgerButtonComponent, PendingBtnComponent as PendingButtonComponent, PendingIconBtnComponent as PendingIconButtonComponent
} from './buttons';
import { DatepickerCalendarHeaderComponent } from './datepicker-calendar-header';
import { FilterComponent, FilterControlDirective } from './filter';
import { HintComponent } from './hint';
import { ImgComponent, ImgErrorDirective, ImgPlaceholderDirective } from './img';
import { CursorPageAdaptorDirective, PaginatorComponent } from './paginator';
import { StatusBarComponent, StatusBarContainerDirective } from './status-bar';
import { ToastComponent, TOAST_ANIMATION_CONFIG } from './toast';
import { AttentionDrawingDotComponent } from './attention-drawing-dot';
import { StatusBadgeComponent } from './status-badge';
import { PlusMinusBtnComponent } from './plus-minus-btn';
import { PictureComponent } from './picture';
import { ChevronRotatable180DegreesComponent } from './chevron-rotatable-180-degrees';
import { CountryComponent } from './country';
import { BoxedItemsComponent } from './boxed-items';

const EXPOSED_MODULES = [
	MatMomentDateModule,
	A11yModule,
];

const EXPOSED_COMPONENTS = [
	AlertComponent,
	AlertMessagesComponent,
	ErrorMessagesComponent,
	PaginatorComponent,
	StatusBarComponent,
	FilterControlDirective,
	FilterComponent,
	ImgComponent,
	ImgErrorDirective,
	ImgPlaceholderDirective,
	ToastComponent,
	DatepickerCalendarHeaderComponent,
	HintComponent,
	PendingIconButtonComponent,
	PendingButtonComponent,
	BurgerButtonComponent,
	AttentionDrawingDotComponent,
	StatusBadgeComponent,
	PlusMinusBtnComponent,
	PictureComponent,
	ChevronRotatable180DegreesComponent,
	BoxedItemsComponent,
];

const EXPOSED_DIRECTIVES = [
	StatusBarContainerDirective,
	CursorPageAdaptorDirective,
];

const EXPOSED = [
	EXPOSED_COMPONENTS,
	EXPOSED_DIRECTIVES,
];

const STANDALONE = [
	CountryComponent,
	PaymentCardBrandIconComponent,
];

@NgModule({
	imports: [
		EXPOSED_MODULES,

		CommonModule,
		RouterModule,
		ToastrModule,
		MatSelectModule,

		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatRippleModule,

		SharedPipesModule,
		SharedFeaturesMomentPipesModule,
		SharedDirectivesCoreModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesTooltipModule,

		STANDALONE,
	],
	exports: [ EXPOSED, EXPOSED_MODULES, STANDALONE ],
	declarations: EXPOSED,
})
export class SharedComponentsCoreModule {
	static forRoot(): ModuleWithProviders<SharedComponentsCoreModule> {
		return {
			ngModule: SharedComponentsCoreModule,
			providers: [
				ToastrModule.forRoot({
					toastComponent: ToastComponent,
					timeOut: 5000,
					preventDuplicates: true,
					includeTitleDuplicates: true,
					closeButton: true,
					resetTimeoutOnDuplicate: true,
					maxOpened: 5,
					progressBar: true,
					onActivateTick: true,
					easeTime: TOAST_ANIMATION_CONFIG.params.timing * 1000,
				}).providers!,
				{ provide: MAT_DATE_LOCALE, useValue: navigator.language || navigator.languages[0] },
			],
		};
	}
}
