import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { HttpBaseInterceptorService } from './http-base.interceptor.service';

@Injectable()
export class HttpMockResponseRetryInterceptor extends HttpBaseInterceptorService implements HttpInterceptor {

	private readonly __httpClient = inject(HttpClient);

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next
			.handle(request)
			.pipe(
				catchError((error: unknown) => error instanceof HttpErrorResponse && (error.status === 501)
					? this.__httpClient.request(
						request.clone({
							url: this.__buildMockedRequestUrlWithBackendBaseSegment(request),
						}),
					)
					: throwError(() => error)),
			);
	}

	private __buildMockedRequestUrlWithBackendBaseSegment(request: HttpRequest<unknown>): string {
		return `${ this._httpConfigService.backendBaseSegment }${ request.url.startsWith('/') ? '' : '/' }${ request.url }`.replace('api', 'api-mock');
	}

}

@Injectable()
export class NoopInterceptor implements HttpInterceptor {

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request);
	}

}
