// eslint-disable-next-line @typescript-eslint/naming-convention
import Cookies from 'js-cookie';

import { inject, Injectable } from '@angular/core';

import { EnvironmentService } from '@bp/frontend/services/environment';

@Injectable({ providedIn: 'root' })
export class CookiesService {

	private readonly __environment = inject(EnvironmentService);

	private readonly __prefix = '__bp__';

	get(key: string): string | undefined {
		return Cookies.get(this.__deriveKey(key));
	}

	set(key: string, value: string, options?: Cookies.CookieAttributes | undefined): void {
		Cookies.set(this.__deriveKey(key), value, {
			...options,
			expires: options?.expires ?? 365,
			domain: options?.domain ?? this.__getDefaultDomain(),
		});
	}

	remove(key: string, options?: Cookies.CookieAttributes | undefined): void {
		Cookies.remove(this.__deriveKey(key), {
			...options,
			domain: options?.domain ?? this.__getDefaultDomain(),
		});
	}

	private __getDefaultDomain(): string | undefined {
		return `${ this.__environment.isDeployed ? '.' : '' }${ this.__environment.mainDomain }`;
	}

	private __deriveKey(key: string): string {
		return `${ this.__prefix }${ key }`;
	}

}
