import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ShowTooltipOnViewportOverflowDirective } from './show-tooltip-on-viewport-overflow.directive';
import { TooltipShownOnViewportOverflowDirective } from './tooltip-shown-on-viewport-overflow.directive';
import { TooltipDirective } from './tooltip.directive';

const exposed = [
	TooltipDirective,
	ShowTooltipOnViewportOverflowDirective,
	TooltipShownOnViewportOverflowDirective,
];

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: exposed,
	exports: exposed,
})
export class SharedFeaturesTooltipModule {
}
