import { isNil, isObject, isString } from 'lodash-es';

import { PrimitiveConstructable } from '@bp/shared/models/core';

import type { ICurrency } from '../currency.interface';

import { CRYPTO_CURRENCY_CODES_AND_NAMES, CryptoCurrencyCode, CryptoCurrencyDefinition } from './crypto-currency-codes-and-names';

export class CryptoCurrency extends PrimitiveConstructable<CryptoCurrencyCode> implements ICurrency {

	static list: CryptoCurrency[] = Object
		.entries(CRYPTO_CURRENCY_CODES_AND_NAMES)
		.map(v => <[ CryptoCurrencyCode, CryptoCurrencyDefinition ]>v)
		.map(([ code, definition ]) => new CryptoCurrency({
			code,
			description: definition.name,
			symbol: definition.symbol ?? code,
			isDeprecated: !!definition.isDeprecated,
		}));

	static listWithoutDeprecated: CryptoCurrency[] = CryptoCurrency.list
		.filter(v => !v.isDeprecated);

	static fromCodes(codes: CryptoCurrencyCode[]): CryptoCurrency[] {
		return codes.map(v => new CryptoCurrency(v));
	}

	readonly code!: CryptoCurrencyCode;

	readonly displayName!: string;

	readonly description?: string;

	readonly symbol!: string;

	readonly isDeprecated!: boolean;

	constructor(dtoOrCode: CryptoCurrencyCode | Pick<CryptoCurrency, 'code' | 'description' | 'isDeprecated' | 'symbol'>) {
		super(isString(dtoOrCode)
			? <CryptoCurrencyCode> dtoOrCode.toUpperCase()
			: dtoOrCode.code);

		if (this._isCached())
			return this;

		if (isObject(dtoOrCode))
			Object.assign(this, dtoOrCode);

		this.displayName = this.__buildDisplayName();

		this._whenCryptoCodeCodeInvalidThrowNotFoundError();

		this._cacheAndFreezeInstance();
	}

	protected _getScope(): string {
		return 'crypto-currency';
	}

	private __buildDisplayName(): string {
		return (this.code === this.symbol ? this.code : `${ this.symbol } ⸱ ${ this.code }`);
	}

	private _whenCryptoCodeCodeInvalidThrowNotFoundError(): void {
		if (isNil(CRYPTO_CURRENCY_CODES_AND_NAMES[this.code]))
			throw new Error(`Such crypto currency doesn't exist - "${ this._id }"`);
	}
}

Object.freeze(CryptoCurrency.list);
