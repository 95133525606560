!function (e, r) {
  "object" == typeof exports && "object" == typeof module ? module.exports = r() : "function" == typeof define && define.amd ? define([], r) : "object" == typeof exports ? exports.textMaskCore = r() : e.textMaskCore = r();
}(this, function () {
  return function (e) {
    function r(n) {
      if (t[n]) return t[n].exports;
      var o = t[n] = {
        exports: {},
        id: n,
        loaded: !1
      };
      return e[n].call(o.exports, o, o.exports, r), o.loaded = !0, o.exports;
    }
    var t = {};
    return r.m = e, r.c = t, r.p = "", r(0);
  }([function (e, r, t) {
    "use strict";

    function n(e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }
    Object.defineProperty(r, "__esModule", {
      value: !0
    });
    var o = t(3);
    Object.defineProperty(r, "conformToMask", {
      enumerable: !0,
      get: function () {
        return n(o).default;
      }
    });
    var i = t(2);
    Object.defineProperty(r, "adjustCaretPosition", {
      enumerable: !0,
      get: function () {
        return n(i).default;
      }
    });
    var a = t(5);
    Object.defineProperty(r, "createTextMaskInputElement", {
      enumerable: !0,
      get: function () {
        return n(a).default;
      }
    });
  }, function (e, r) {
    "use strict";

    Object.defineProperty(r, "__esModule", {
      value: !0
    }), r.placeholderChar = "_", r.strFunction = "function";
  }, function (e, r) {
    "use strict";

    function t(e) {
      var r = e.previousConformedValue,
        t = void 0 === r ? o : r,
        i = e.previousPlaceholder,
        a = void 0 === i ? o : i,
        u = e.currentCaretPosition,
        l = void 0 === u ? 0 : u,
        s = e.conformedValue,
        f = e.rawValue,
        d = e.placeholderChar,
        c = e.placeholder,
        p = e.indexesOfPipedChars,
        v = void 0 === p ? n : p,
        h = e.caretTrapIndexes,
        m = void 0 === h ? n : h;
      if (0 === l || !f.length) return 0;
      var y = f.length,
        g = t.length,
        b = c.length,
        C = s.length,
        P = y - g,
        k = P > 0,
        x = 0 === g,
        O = P > 1 && !k && !x;
      if (O) return l;
      var T = k && (t === s || s === c),
        w = 0,
        M = void 0,
        S = void 0;
      if (T) w = l - P;else {
        var j = s.toLowerCase(),
          _ = f.toLowerCase(),
          V = _.substr(0, l).split(o),
          A = V.filter(function (e) {
            return j.indexOf(e) !== -1;
          });
        S = A[A.length - 1];
        var N = a.substr(0, A.length).split(o).filter(function (e) {
            return e !== d;
          }).length,
          E = c.substr(0, A.length).split(o).filter(function (e) {
            return e !== d;
          }).length,
          F = E !== N,
          R = void 0 !== a[A.length - 1] && void 0 !== c[A.length - 2] && a[A.length - 1] !== d && a[A.length - 1] !== c[A.length - 1] && a[A.length - 1] === c[A.length - 2];
        !k && (F || R) && N > 0 && c.indexOf(S) > -1 && void 0 !== f[l] && (M = !0, S = f[l]);
        for (var I = v.map(function (e) {
            return j[e];
          }), J = I.filter(function (e) {
            return e === S;
          }).length, W = A.filter(function (e) {
            return e === S;
          }).length, q = c.substr(0, c.indexOf(d)).split(o).filter(function (e, r) {
            return e === S && f[r] !== e;
          }).length, L = q + W + J + (M ? 1 : 0), z = 0, B = 0; B < C; B++) {
          var D = j[B];
          if (w = B + 1, D === S && z++, z >= L) break;
        }
      }
      if (k) {
        for (var G = w, H = w; H <= b; H++) if (c[H] === d && (G = H), c[H] === d || m.indexOf(H) !== -1 || H === b) return G;
      } else if (M) {
        for (var K = w - 1; K >= 0; K--) if (s[K] === S || m.indexOf(K) !== -1 || 0 === K) return K;
      } else for (var Q = w; Q >= 0; Q--) if (c[Q - 1] === d || m.indexOf(Q) !== -1 || 0 === Q) return Q;
    }
    Object.defineProperty(r, "__esModule", {
      value: !0
    }), r.default = t;
    var n = [],
      o = "";
  }, function (e, r, t) {
    "use strict";

    function n() {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : l,
        r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : u,
        t = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
      if (!(0, i.isArray)(r)) {
        if (("undefined" == typeof r ? "undefined" : o(r)) !== a.strFunction) throw new Error("Text-mask:conformToMask; The mask property must be an array.");
        r = r(e, t), r = (0, i.processCaretTraps)(r).maskWithoutCaretTraps;
      }
      var n = t.guide,
        s = void 0 === n || n,
        f = t.previousConformedValue,
        d = void 0 === f ? l : f,
        c = t.placeholderChar,
        p = void 0 === c ? a.placeholderChar : c,
        v = t.placeholder,
        h = void 0 === v ? (0, i.convertMaskToPlaceholder)(r, p) : v,
        m = t.currentCaretPosition,
        y = t.keepCharPositions,
        g = s === !1 && void 0 !== d,
        b = e.length,
        C = d.length,
        P = h.length,
        k = r.length,
        x = b - C,
        O = x > 0,
        T = m + (O ? -x : 0),
        w = T + Math.abs(x);
      if (y === !0 && !O) {
        for (var M = l, S = T; S < w; S++) h[S] === p && (M += p);
        e = e.slice(0, T) + M + e.slice(T, b);
      }
      for (var j = e.split(l).map(function (e, r) {
          return {
            char: e,
            isNew: r >= T && r < w
          };
        }), _ = b - 1; _ >= 0; _--) {
        var V = j[_].char;
        if (V !== p) {
          var A = _ >= T && C === k;
          V === h[A ? _ - x : _] && j.splice(_, 1);
        }
      }
      var N = l,
        E = !1;
      e: for (var F = 0; F < P; F++) {
        var R = h[F];
        if (R === p) {
          if (j.length > 0) for (; j.length > 0;) {
            var I = j.shift(),
              J = I.char,
              W = I.isNew;
            if (J === p && g !== !0) {
              N += p;
              continue e;
            }
            if (r[F].test(J)) {
              if (y === !0 && W !== !1 && d !== l && s !== !1 && O) {
                for (var q = j.length, L = null, z = 0; z < q; z++) {
                  var B = j[z];
                  if (B.char !== p && B.isNew === !1) break;
                  if (B.char === p) {
                    L = z;
                    break;
                  }
                }
                null !== L ? (N += J, j.splice(L, 1)) : F--;
              } else N += J;
              continue e;
            }
            E = !0;
          }
          g === !1 && (N += h.substr(F, P));
          break;
        }
        N += R;
      }
      if (g && O === !1) {
        for (var D = null, G = 0; G < N.length; G++) h[G] === p && (D = G);
        N = null !== D ? N.substr(0, D + 1) : l;
      }
      return {
        conformedValue: N,
        meta: {
          someCharsRejected: E
        }
      };
    }
    Object.defineProperty(r, "__esModule", {
      value: !0
    });
    var o = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    };
    r.default = n;
    var i = t(4),
      a = t(1),
      u = [],
      l = "";
  }, function (e, r, t) {
    "use strict";

    function n() {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : f,
        r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : s.placeholderChar;
      if (!o(e)) throw new Error("Text-mask:convertMaskToPlaceholder; The mask property must be an array.");
      if (e.indexOf(r) !== -1) throw new Error("Placeholder character must not be used as part of the mask. Please specify a character that is not present in your mask as your placeholder character.\n\n" + ("The placeholder character that was received is: " + JSON.stringify(r) + "\n\n") + ("The mask that was received is: " + JSON.stringify(e)));
      return e.map(function (e) {
        return e instanceof RegExp ? r : e;
      }).join("");
    }
    function o(e) {
      return Array.isArray && Array.isArray(e) || e instanceof Array;
    }
    function i(e) {
      return "string" == typeof e || e instanceof String;
    }
    function a(e) {
      return "number" == typeof e && void 0 === e.length && !isNaN(e);
    }
    function u(e) {
      return "undefined" == typeof e || null === e;
    }
    function l(e) {
      for (var r = [], t = void 0; t = e.indexOf(d), t !== -1;) r.push(t), e.splice(t, 1);
      return {
        maskWithoutCaretTraps: e,
        indexes: r
      };
    }
    Object.defineProperty(r, "__esModule", {
      value: !0
    }), r.convertMaskToPlaceholder = n, r.isArray = o, r.isString = i, r.isNumber = a, r.isNil = u, r.processCaretTraps = l;
    var s = t(1),
      f = [],
      d = "[]";
  }, function (e, r, t) {
    "use strict";

    function n(e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }
    function o(e) {
      var r = {
        previousConformedValue: void 0,
        previousPlaceholder: void 0
      };
      return {
        state: r,
        update: function (t) {
          var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : e,
            o = n.inputElement,
            s = n.mask,
            d = n.guide,
            m = n.pipe,
            g = n.placeholderChar,
            b = void 0 === g ? v.placeholderChar : g,
            C = n.keepCharPositions,
            P = void 0 !== C && C,
            k = n.showMask,
            x = void 0 !== k && k;
          if ("undefined" == typeof t && (t = o.value), t !== r.previousConformedValue) {
            ("undefined" == typeof s ? "undefined" : l(s)) === y && void 0 !== s.pipe && void 0 !== s.mask && (m = s.pipe, s = s.mask);
            var O = void 0,
              T = void 0;
            if (s instanceof Array && (O = (0, p.convertMaskToPlaceholder)(s, b)), s !== !1) {
              var w = a(t),
                M = o.selectionEnd,
                S = r.previousConformedValue,
                j = r.previousPlaceholder,
                _ = void 0;
              if (("undefined" == typeof s ? "undefined" : l(s)) === v.strFunction) {
                if (T = s(w, {
                  currentCaretPosition: M,
                  previousConformedValue: S,
                  placeholderChar: b
                }), T === !1) return;
                var V = (0, p.processCaretTraps)(T),
                  A = V.maskWithoutCaretTraps,
                  N = V.indexes;
                T = A, _ = N, O = (0, p.convertMaskToPlaceholder)(T, b);
              } else T = s;
              var E = {
                  previousConformedValue: S,
                  guide: d,
                  placeholderChar: b,
                  pipe: m,
                  placeholder: O,
                  currentCaretPosition: M,
                  keepCharPositions: P
                },
                F = (0, c.default)(w, T, E),
                R = F.conformedValue,
                I = ("undefined" == typeof m ? "undefined" : l(m)) === v.strFunction,
                J = {};
              I && (J = m(R, u({
                rawValue: w
              }, E)), J === !1 ? J = {
                value: S,
                rejected: !0
              } : (0, p.isString)(J) && (J = {
                value: J
              }));
              var W = I ? J.value : R,
                q = (0, f.default)({
                  previousConformedValue: S,
                  previousPlaceholder: j,
                  conformedValue: W,
                  placeholder: O,
                  rawValue: w,
                  currentCaretPosition: M,
                  placeholderChar: b,
                  indexesOfPipedChars: J.indexesOfPipedChars,
                  caretTrapIndexes: _
                }),
                L = W === O && 0 === q,
                z = x ? O : h,
                B = L ? z : W;
              r.previousConformedValue = B, r.previousPlaceholder = O, o.value !== B && (o.value = B, i(o, q));
            }
          }
        }
      };
    }
    function i(e, r) {
      document.activeElement === e && (g ? b(function () {
        return e.setSelectionRange(r, r, m);
      }, 0) : e.setSelectionRange(r, r, m));
    }
    function a(e) {
      if ((0, p.isString)(e)) return e;
      if ((0, p.isNumber)(e)) return String(e);
      if (void 0 === e || null === e) return h;
      throw new Error("The 'value' provided to Text Mask needs to be a string or a number. The value received was:\n\n " + JSON.stringify(e));
    }
    Object.defineProperty(r, "__esModule", {
      value: !0
    });
    var u = Object.assign || function (e) {
        for (var r = 1; r < arguments.length; r++) {
          var t = arguments[r];
          for (var n in t) Object.prototype.hasOwnProperty.call(t, n) && (e[n] = t[n]);
        }
        return e;
      },
      l = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
        return typeof e;
      } : function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      };
    r.default = o;
    var s = t(2),
      f = n(s),
      d = t(3),
      c = n(d),
      p = t(4),
      v = t(1),
      h = "",
      m = "none",
      y = "object",
      g = "undefined" != typeof navigator && /Android/i.test(navigator.userAgent),
      b = "undefined" != typeof requestAnimationFrame ? requestAnimationFrame : setTimeout;
  }]);
});