import { Directive, ElementRef, inject, Input, OnChanges } from '@angular/core';

@Directive({ selector: '[bpScrollIntoView]' })
export class ScrollIntoViewDirective implements OnChanges {

	@Input()
	bpScrollIntoView?: { instant?: boolean; scroll: boolean };

	private readonly __$host = <HTMLElement> inject(ElementRef).nativeElement;

	ngOnChanges(): void {
		setTimeout(() => {
			if (this.bpScrollIntoView?.scroll) {
				this.__$host.scrollIntoView({
					behavior: this.bpScrollIntoView.instant ? 'auto' : 'smooth',
					block: 'center',
					inline: 'center',
				});
			}
		}, 100); // time to render the host
	}

}
