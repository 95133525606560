import { query, transition, trigger, useAnimation } from '@angular/animations';

import { fadeIn } from './fade';
import { NORMAL } from './variables';

export const FADE_IN_CHILDREN = trigger('fadeInChildren', [
	transition('* => *', [ // Each time the binding value changes
		query(
			':enter',
			[
				useAnimation(fadeIn, NORMAL),
			],
			{ optional: true },
		),
	]),
]);
