/// <reference path="../../../../../../node_modules/moment-timezone/index.d.ts" />

import moment from 'moment';

import { Injectable } from '@angular/core';

import { OptionalBehaviorSubject } from '@bp/frontend/rxjs';
import { Timezone, Timezones } from '@bp/frontend/features/moment/models';

@Injectable({
	providedIn: 'root',
})
export class MomentTimezoneService {

	private readonly _timezone$ = new OptionalBehaviorSubject<Timezone>();

	readonly timezone$ = this._timezone$.asObservable();

	setTimezone(timezone: Timezone): void {
		moment.tz.setDefault(timezone.name);

		this._timezone$.next(timezone);
	}

	setGuessedTimezone(): void {
		this.setTimezone(Timezones.guessed);
	}

}
