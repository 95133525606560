import { toggleableStateKeptInSessionStorageFactory } from './toggleable-state-kept-in-session-storage-factory';

const CONSOLE_LOGGING_VERBOSE_IS_ACTIVE_STORAGE_KEY = 'console-logging-verbose-is-active';

export class ConsoleLoggingVerboseState
	extends toggleableStateKeptInSessionStorageFactory(CONSOLE_LOGGING_VERBOSE_IS_ACTIVE_STORAGE_KEY) {

}

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
(<any>globalThis).ConsoleLoggingVerboseState = ConsoleLoggingVerboseState;
