/// <reference path="../../../../../../typings.d.ts" />
import { isString } from 'lodash-es';

import { PrimitiveConstructable } from '@bp/shared/models/core';

import { ICurrency } from '../currency.interface';

import fiatCurrenciesSymbols from './fiat-currencies-symbols';
import { FiatCurrencyCode, FIAT_CURRENCIES_CODES } from './fiat-currency-codes';

let intlGetDisplayName: Intl.DisplayNames | undefined;

export function tryGetCurrencyNameInEnglish(this: void, iso: string): string | undefined {
	try {
		intlGetDisplayName ??= new Intl.DisplayNames([ 'en' ], { type: 'currency' });

		return intlGetDisplayName.of(iso);
	} catch {
		return undefined;
	}
}

export class FiatCurrency extends PrimitiveConstructable<FiatCurrencyCode> implements ICurrency {

	static readonly list: FiatCurrency[] = FIAT_CURRENCIES_CODES.map(it => new FiatCurrency(it));

	static fromCodes(codes: FiatCurrencyCode[]): FiatCurrency[] {
		return codes.map(v => new FiatCurrency(v));
	}

	readonly currentLocaleFormatParts!: Intl.NumberFormatPart[];

	readonly code!: FiatCurrencyCode;

	/**
	 * proper currency name in english or '{symbol} {code}' if not found
	 */
	readonly displayName!: string;

	readonly description?: string;

	readonly symbol!: string;

	readonly thousandsSeparatorSymbol!: string;

	readonly allowDecimal!: boolean;

	readonly decimalSeparatorSymbol!: string;

	readonly decimalLimit!: number;

	constructor(dtoOrCode: FiatCurrencyCode | { code: FiatCurrencyCode }) {
		super(isString(dtoOrCode)
			? <FiatCurrencyCode> dtoOrCode.toUpperCase()
			: dtoOrCode.code);

		if (this._isCached())
			return this;

		this.code = this._id;

		this.__whenCurrencyCodeCodeInvalidThrowNotFoundError();

		try {
			this.currentLocaleFormatParts = new Intl.NumberFormat(
				undefined,
				{ style: 'currency', currency: this.code },
			)
				.formatToParts(1000000);

			this.decimalLimit = this.currentLocaleFormatParts.find(it => it.type === 'fraction')?.value.length ?? 0;

			this.thousandsSeparatorSymbol = this.currentLocaleFormatParts.find(it => it.type === 'group')!.value;

			this.decimalSeparatorSymbol = this.currentLocaleFormatParts.find(it => it.type === 'decimal')?.value ?? '';
		} catch {
			console.error('Failed to get currency format from Intl.NumberFormat. Using fallback values.');

			this.decimalLimit = 2;

			this.thousandsSeparatorSymbol = '';

			this.decimalSeparatorSymbol = '.';
		}

		this.symbol = (<Record<string, string | undefined>>fiatCurrenciesSymbols)[this.code] ?? this.code;

		this.displayName = this.__buildDisplayName();

		this.description = tryGetCurrencyNameInEnglish(this.code);

		this.allowDecimal = this.decimalLimit !== 0;

		this._cacheAndFreezeInstance();
	}

	private __buildDisplayName(): string {
		return (this.code === this.symbol ? this.code : `${ this.symbol } ⸱ ${ this.code }`);
	}

	protected _getScope(): string {
		return 'currency';
	}

	private __whenCurrencyCodeCodeInvalidThrowNotFoundError(): void {
		if (!FIAT_CURRENCIES_CODES.includes(this.code))
			throw new Error(`Such currency doesn't exist - "${ this._id }"`);
	}

}

Object.freeze(FiatCurrency.list);
