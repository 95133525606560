import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[bpHover]',
})
export class HoverDirective {

	@Output('bpHover') readonly hover = new EventEmitter<boolean>();

	@HostListener('mouseenter')
	onEnter(): void {
		this.hover.emit(true);
	}

	@HostListener('mouseleave')
	onLeave(): void {
		this.hover.emit(false);
	}
}
