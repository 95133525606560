import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'join',
})
export class JoinPipe implements PipeTransform {
	transform<T>(array: T[] | null | undefined, separator?: string | undefined): string | null {
		return array ? array.join(separator) : null;
	}
}
