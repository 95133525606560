/* eslint-disable rxjs/no-internal */
import { AsyncAction } from 'rxjs/internal/scheduler/AsyncAction';
import { AsyncScheduler } from 'rxjs/internal/scheduler/AsyncScheduler';
import type { SchedulerAction } from 'rxjs/internal/types';

import { ZoneService } from '../zone.service';

class InsideAngularAsyncAction<T> extends AsyncAction<T> {
	constructor(
		// eslint-disable-next-line @typescript-eslint/naming-convention
		protected override scheduler: AsyncScheduler,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		protected override work: (this: SchedulerAction<T>, state?: T) => void,
	) {
		super(scheduler, work);
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	protected override requestAsyncId(scheduler: AsyncScheduler, id?: any, delay: number = 0): any {
		if (!ZoneService.isInAngularZone())
			return ZoneService.runInAngularZone(() => super.requestAsyncId(scheduler, id, delay));

		return super.requestAsyncId(scheduler, id, delay);
	}
}

export const asyncInside = new AsyncScheduler(InsideAngularAsyncAction);
