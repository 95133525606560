import pluralize from 'pluralize';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({ name: 'pluralizeBy' })
export class PluralizePipe implements PipeTransform {

	transform(word: string, count: number, inclusive?: 'inclusive'): string {
		return pluralize(word, count, !!inclusive);
	}

}
