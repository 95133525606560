import { toggleableStateKeptInSessionStorageFactory } from './toggleable-state-kept-in-session-storage-factory';

const CONSOLE_LOGGING_IS_ACTIVE_STORAGE_KEY = 'console-logging-is-active';

export class ConsoleLoggingState
	extends toggleableStateKeptInSessionStorageFactory(CONSOLE_LOGGING_IS_ACTIVE_STORAGE_KEY) {

}

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
(<any>globalThis).ConsoleLoggingState = ConsoleLoggingState;
