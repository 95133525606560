<mat-form-field
	[color]="color"
	[appearance]="_matAppearance"
	[hideRequiredMarker]="true"
	[floatLabel]="_floatLabel"
	[class.has-suffix]="!hideClearButton"
>
	<mat-label>
		{{ label | bpTranslate | async }}

		<span
			*ngIf="required && !_defaultOptions?.hideRequiredMarker"
			class="mat-placeholder-required mat-form-field-required-marker"
		>
			*
		</span>

		<bp-hint *ngIf="longHint" size="sm" [message]="longHint" />
	</mat-label>

	<input
		matInput
		[attr.name]="name"
		[matDatepicker]="picker"
		[formControl]="_internalControl"
		[placeholder]="$any(placeholder | bpTranslate | async)"
		[required]="!!required"
		[min]="min"
		[max]="max"
		(click)="picker.open()"
		(dateChange)="set($event.value)"
	/>

	<mat-error [bpFieldError]="name" [bpFieldErrorSuppress]="hideErrorText" />

	<mat-hint *ngIf="hint">
		{{ hint | bpTranslate | async }}
	</mat-hint>

	<span *ngIf="!hideClearButton" matSuffix class="display-contents">
		<div class="clear-button-wrapper">
			<div [class.show]="!!_internalControl.value">
				<button type="button" tabindex="-1" mat-icon-button (click)="_eraseInternalControlValue()">
					<mat-icon>close</mat-icon>
				</button>
			</div>
		</div>
	</span>
</mat-form-field>

<mat-datepicker
	#picker
	[calendarHeaderComponent]="DatepickerCalendarHeaderComponent"
	[startAt]="startAt"
	[startView]="startView ?? 'month'"
/>
