import { snakeCase } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'bpSnakeCase',
})
export class SnakeCasePipe implements PipeTransform {
	transform(value?: string): string {
		return snakeCase(value);
	}
}
