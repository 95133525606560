import type { BehaviorSubject, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

export function delayWhileNotifierTrue<T>(notifier$: BehaviorSubject<boolean>): MonoTypeOperatorFunction<T> {
	return (source$: Observable<T>) => source$.pipe(
		switchMap(sourceValue => notifier$.value
			? notifier$.pipe(
				filter(notification => !notification),
				map(() => sourceValue),
			)
			: of(sourceValue)),
	);

}
