import { Pipe, PipeTransform } from '@angular/core';

import { toPlainObject } from '@bp/shared/utilities/core';

@Pipe({
	name: 'toPlainObject',
})
export class ToPlainObjectPipe implements PipeTransform {
	transform(value: object): object {
		return toPlainObject(value);
	}
}
