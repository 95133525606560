import countriesList from 'countries-list';

const { countries } = countriesList;

export const COUNTRY_CODES = <const>[
	...<(keyof typeof countries)[]> Object.keys(countries),
	'ALL',
];

export type CountryCode = typeof COUNTRY_CODES[ number ];
