import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

import { NORMAL } from './variables';

export const HUB_ITEM_FLY_IN_OUT = trigger('hubItemFlyInOut', [
	state('in', style({ transform: 'translateX(0)' })),
	transition(
		':enter',
		[
			animate('0.25s', keyframes([
				style({ opacity: 0, transform: 'translateX(-100%)' }),
				style({ opacity: 0.7, transform: 'translateX(10px)' }),
				style({ opacity: 1, transform: 'translateX(0)' }),
			])),
		],
		NORMAL,
	),
	transition(
		':leave',
		[
			animate('0.25s', keyframes([
				style({ opacity: 1, transform: 'translateX(0)' }),
				style({ opacity: 0.7, transform: 'translateX(-10px)' }),
				style({ opacity: 0, transform: 'translateX(100%)' }),
			])),
		],
		NORMAL,
	),
]);
