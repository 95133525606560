/* eslint-disable @typescript-eslint/naming-convention */
export default {
	AFN: '؋',
	ALL: 'L',
	AMD: '֏',
	ANG: 'ƒ',
	AOA: 'Kz',
	ARS: 'AR$',
	AUD: 'AU$',
	AWG: 'ƒ',
	AZN: '₼',
	BAM: 'KM',
	BBD: 'BBD$',
	BDT: '৳',
	BGN: 'лв',
	BIF: 'FBu',
	BMD: 'BM$',
	BND: 'BN$',
	BOB: '$b',
	BOV: 'BOV',
	BRL: 'R$',
	BSD: 'BSD$',
	BTC: '₿',
	BWP: 'P',
	BYN: 'Br',
	BYR: 'Br',
	BZD: 'BZ$',
	CAD: 'CA$',
	CDF: 'FC',
	CHE: 'CHE',
	CHF: 'CHF',
	CHW: 'CHW',
	CLF: 'CLF',
	CLP: 'CLP$',
	CNH: '¥',
	CNY: '¥',
	COP: 'COP$',
	COU: 'COU',
	CRC: '₡',
	CUC: 'CUC$',
	CUP: '₱',
	CVE: 'CVE$',
	CZK: 'Kč',
	DJF: 'Fdj',
	DKK: 'kr',
	DOP: 'RD$',
	DZD: 'دج',
	EEK: 'kr',
	EGP: '£',
	ERN: 'Nfk',
	ETB: 'Br',
	ETH: 'Ξ',
	EUR: '€',
	FJD: 'FJD$',
	FKP: '£',
	GBP: '£',
	GEL: '₾',
	GGP: '£',
	GHC: '₵',
	GHS: 'GH₵',
	GIP: '£',
	GMD: 'D',
	GNF: 'FG',
	GTQ: 'Q',
	GYD: 'GYD$',
	HKD: 'HKD$',
	HNL: 'L',
	HRK: 'kn',
	HTG: 'G',
	HUF: 'Ft',
	IDR: 'Rp',
	ILS: '₪',
	IMP: '£',
	INR: '₹',
	IRR: '﷼',
	ISK: 'kr',
	JEP: '£',
	JMD: 'J$',
	JOD: 'JD',
	JPY: '¥',
	KES: 'KSh',
	KGS: 'лв',
	KHR: '៛',
	KMF: 'CF',
	KPW: '₩',
	KRW: '₩',
	KWD: 'KD',
	KYD: 'KYD$',
	KZT: '₸',
	LAK: '₭',
	LBP: '£',
	LKR: '₨',
	LRD: 'LRD$',
	LSL: 'M',
	LTC: 'Ł',
	LTL: 'Lt',
	LVL: 'Ls',
	LYD: 'LD',
	MAD: 'MAD',
	MDL: 'lei',
	MGA: 'Ar',
	MKD: 'ден',
	MMK: 'K',
	MNT: '₮',
	MOP: 'MOP$',
	MRO: 'UM',
	MRU: 'UM',
	MUR: '₨',
	MVR: 'Rf',
	MWK: 'MK',
	MXN: 'MXN$',
	MXV: 'MXV',
	MYR: 'RM',
	MZN: 'MT',
	NAD: 'NAD$',
	NGN: '₦',
	NIO: 'C$',
	NOK: 'kr',
	NPR: '₨',
	NZD: 'NZD$',
	OMR: '﷼',
	PGK: 'K',
	PHP: '₱',
	PKR: '₨',
	PLN: 'zł',
	PYG: 'Gs',
	QAR: '﷼',
	RMB: '￥',
	RON: 'lei',
	RUB: '₽',
	RWF: 'R₣',
	SAR: '﷼',
	SBD: 'SBD$',
	SCR: '₨',
	SEK: 'kr',
	SGD: 'S$',
	SHP: '£',
	SLL: 'Le',
	SOS: 'S',
	SRD: 'SRD$',
	SSP: '£',
	STD: 'Db',
	STN: 'Db',
	SVC: 'SVC$',
	SYP: '£',
	SZL: 'E',
	THB: '฿',
	TJS: 'SM',
	TMT: 'T',
	TOP: 'T$',
	TRL: '₤',
	TRY: '₺',
	TTD: 'TT$',
	TVD: 'TVD$',
	TWD: 'NT$',
	TZS: 'TSh',
	UAH: '₴',
	UGX: 'USh',
	USD: '$',
	UYI: 'UYI',
	UYU: '$U',
	UYW: 'UYW',
	UZS: 'лв',
	VEF: 'Bs',
	VND: '₫',
	VUV: 'VT',
	WST: 'WS$',
	XAF: 'FCFA',
	XBT: 'Ƀ',
	XCD: 'XCD$',
	XOF: 'CFA',
	XPF: '₣',
	XSU: 'Sucre',
	XUA: 'XUA',
	YER: '﷼',
	ZAR: 'R',
	ZMW: 'ZK',
	ZWD: 'Z$',
	ZWL: 'ZWL$',
};
