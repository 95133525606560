<bp-input
	#origin="matAutocompleteOrigin"
	matAutocompleteOrigin
	inputmode="tel"
	[label]="label"
	[hint]="hint"
	[nativeAutocomplete]="nativeAutocomplete"
	[color]="color"
	[formControl]="_internalControl"
	[placeholder]="placeholder"
	[floatLabel]="floatLabel"
	[name]="name"
	[pending]="pending"
	[required]="!!required"
	[appearance]="appearance"
	[mask]="_phoneNumberMask"
>
	<button
		bpInputPrefix
		class="reset-default-decoration dial-code-button"
		type="button"
		[class.disable-focus]="countriesAutocomplete.isOpen"
		(focusin)="$event.preventDefault(); $event.stopPropagation()"
		(keydown.arrowDown)="_openSearchCountryPanel($event)"
		(click)="_openSearchCountryPanel($event)"
	>
		<input
			#dialCodeCountryInput
			type="text"
			tabindex="-1"
			[autocomplete]="_valueToDisableNativeAutocomplete"
			[value]="_dialCodeCountrySearchControl.value"
			[matAutocomplete]="countriesAutocomplete"
			[matAutocompleteConnectedTo]="origin"
		/>
		<bp-country hideTooltip compact [src]="_dialCodeCountry.country" />
	</button>
</bp-input>

<mat-autocomplete
	#countriesAutocomplete="matAutocomplete"
	class="dial-code-countries-autocomplete-panel"
	(opened)="virtualScrollViewport.checkViewportSize(); _focusSearchCountryInput()"
>
	<div cdkMonitorSubtreeFocus (cdkFocusChange)="_closePanelOnFocusOut($event)">
		<div cdkTrapFocus>
			<bp-input
				#searchCountryInput
				class="dial-code-countries-autocomplete-panel-search-input"
				appearance="standard"
				[formControl]="_dialCodeCountrySearchControl"
				[placeholder]="('Start typing to find your country' | bpTranslate | async)!"
			/>

			<cdk-virtual-scroll-viewport
				#virtualScrollViewport
				itemSize="48"
				minBufferPx="200"
				[@fadeInListStaggered]="_filteredCountriesWithDialCodes.length"
			>
				<mat-option
					*cdkVirtualFor="let filteredCountryWithDialCode of _filteredCountriesWithDialCodes"
					[class.mat-active]="filteredCountryWithDialCode === _dialCodeCountry"
					[class.mat-selected]="filteredCountryWithDialCode === _dialCodeCountry"
					(onSelectionChange)="_onSelectionChange($event, filteredCountryWithDialCode)"
				>
					<bp-country class="mr-2" compact hideTooltip [src]="filteredCountryWithDialCode.country" />

					{{ filteredCountryWithDialCode.country.displayName }}
					{{ filteredCountryWithDialCode.dialCode.internationalDialCodePrefix }}
				</mat-option>

				<mat-option *ngIf="_filteredCountriesWithDialCodes.length === 0" disabled>
					<div class="text-center">
						{{ 'No matching countries ' | bpTranslate | async }}
					</div>
				</mat-option>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
</mat-autocomplete>
