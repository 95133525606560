
import fiatCurrenciesNumericCodes from './fiat-currencies-numeric-codes';
import { FiatCurrency } from './fiat-currency';
import { FiatCurrencyCode } from './fiat-currency-codes';

export function getFiatCurrencyFromNumericCode(currencyNumericCode: string): FiatCurrency {
	const fiatCurrencyCode = <FiatCurrencyCode | undefined > Object.entries(fiatCurrenciesNumericCodes)
		.find(([ , code ]) => code === currencyNumericCode)?.[0];

	if (!fiatCurrencyCode)
		throw new Error(`Fiat currency with numeric code ${ currencyNumericCode } not found`);

	return new FiatCurrency(fiatCurrencyCode);
}
