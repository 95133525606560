/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Observable } from 'rxjs';

import { ErrorHandler, Injectable } from '@angular/core';

import { MetaReducer } from '@ngrx/store';

import { IEnvironmentConfig } from '@bp/shared/typings';

import { BpError } from '@bp/frontend/models/core';
import { ConsoleLoggingState } from '@bp/frontend/services/persistent-state-keepers';

import { IReporter, IReporterUser } from './reporter.interface';
import { TelemetryReporter } from './telemetry.reporter';
import { ReportEventSource } from './report-event-source.enum';

type ReportTags = Parameters<IReporter['setTags']>[0];

@Injectable({
	providedIn: 'root',
})
export class TelemetryService implements IReporter {

	static logToReporterMetaReducer: MetaReducer | null;

	private static __instance?: TelemetryService;

	private static __reporter?: TelemetryReporter;

	private static __tags: ReportTags = {};

	static init(environment: IEnvironmentConfig): void {
		this.__reporter = new TelemetryReporter({
			environment,
			captureConsoleErrors: !ConsoleLoggingState.isActive && environment.isDeployed,
			shouldSanitizeSensitiveData: environment.isProduction,
		});

		this.logToReporterMetaReducer = this.__reporter.logMetaReducer ?? null;
	}

	static registerReporter(name: string, reporter: IReporter): void {
		if (!this.__reporter) {
			console.warn(
				'Failed to register reporter, because telemetry service is not inited. '
				+ 'By default telemetry service is not instantiated with local backend enabled.',
			);

			return;
		}

		this.__reporter.registerReporter(name, reporter);
	}

	static initLogrocketReporterOnDemand(): void {
		this.__reporter?.initLogrocketReporterOnDemand();
	}

	static captureError(error: unknown, payload?: unknown, source = ReportEventSource.Manual): void {
		const bpError = error instanceof BpError ? error : BpError.fromUnknown(error);

		if (bpError.isHttpError)
			source = ReportEventSource.Api;

		if (ConsoleLoggingState.isActive)
			console.error(bpError.originalError ?? bpError);
		else {
			payload && TelemetryService.warn('error-payload', payload);

			TelemetryService.__reporter?.captureError(bpError, source);
		}
	}

	static captureMessage(message: string, payload?: unknown): void {
		if (ConsoleLoggingState.isActive)
			console.warn(message, { payload });
		else {
			payload && TelemetryService.log(message, payload);

			TelemetryService.__reporter?.captureMessage(message);
		}
	}

	static routerErrorHandler(this: void, error: any): void {
		TelemetryService.captureError(error, null, ReportEventSource.Router);
	}

	static appErrorHandler(this: void, error: any): void {
		TelemetryService.captureError(error, null, ReportEventSource.App);
	}

	static log(message: string, ...optionalParams: any[]): void {
		if (ConsoleLoggingState.isActive)
			console.debug(message, ...optionalParams);
		else
			TelemetryService.__reporter?.log(message, ...optionalParams);
	}

	static warn(message: string, ...optionalParams: any[]): void {
		if (ConsoleLoggingState.isActive)
			console.warn(message, ...optionalParams);
		else
			TelemetryService.__reporter?.warn(message, ...optionalParams);
	}

	static track(event: string, payload: any): void {
		if (ConsoleLoggingState.isActive)
			return;

		TelemetryService.__reporter?.track(event, payload);
	}

	static setTags(tags: ReportTags): void {
		TelemetryService.__tags = {
			...TelemetryService.__tags,
			...tags,
		};

		if (ConsoleLoggingState.isActive)
			TelemetryService.log('Tags', tags);
		else
			TelemetryService.__reporter?.setTags(tags);
	}

	static getTags(): ReportTags {
		return TelemetryService.__tags;
	}

	private readonly __reporter = TelemetryService.__reporter;

	logMetaReducer = this.__reporter?.logMetaReducer ?? null;

	userSessionRecordingUrl$?: Observable<string> | undefined = this.__reporter?.userSessionRecordingUrl$;

	constructor() {
		if (TelemetryService.__instance)
			return TelemetryService.__instance;

		return (TelemetryService.__instance = this);
	}

	identifyUser(user: IReporterUser): void {
		this.__reporter?.identifyUser(user);
	}

	captureError(error: unknown, payload?: any): void {
		TelemetryService.captureError(error, payload);
	}

	captureMessage(message: string, payload?: any): void {
		TelemetryService.captureMessage(message, payload);
	}

	log(message: string, ...optionalParams: any[]): void {
		TelemetryService.log(message, ...optionalParams);
	}

	warn(message: string, ...optionalParams: any[]): void {
		TelemetryService.warn(message, ...optionalParams);
	}

	track(event: string, payload: any): void {
		TelemetryService.track(event, payload);
	}

	setTags(tags: Parameters<IReporter['setTags']>[0]): void {
		TelemetryService.setTags(tags);
	}

}

export class AppErrorHandler implements ErrorHandler {
	handleError(error: any): void {
		TelemetryService.appErrorHandler(error);
	}
}
