
import { AppStorageService } from './app-storage.service';
import { IAppStorageServiceConfig } from './models';

export class LocalStorageService extends AppStorageService {

	constructor(config: Omit<IAppStorageServiceConfig, 'storageAreaType'>) {
		super({
			...config,
			storageAreaType: 'localStorage',
		});
	}

}
