import { Observable } from 'rxjs';

import { IValidationErrors } from '@bp/shared/features/validation/models';
import { ErrorsTextsRendererService } from '@bp/shared/features/validation/error-text-renderer';

export abstract class ErrorsTextsProviderBaseService extends ErrorsTextsRendererService {

	abstract renderFirstErrorTextAndObserveChanges(
		validationErrors: IValidationErrors,
		controlName?: number | string | null,
	): Observable<string>;

	abstract renderFirstErrorText(
		validationErrors: IValidationErrors,
		controlName?: number | string | null,
	): string;

}
