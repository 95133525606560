import { isBoolean } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'bpIsBoolean',
})
export class IsBooleanPipe implements PipeTransform {
	transform(value: unknown): boolean {
		return isBoolean(value);
	}
}
