<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->

<mat-form-field
	[color]="color"
	[appearance]="_matAppearance"
	[hideRequiredMarker]="true"
	[floatLabel]="isIncomingSecretValueUsedAsPlaceholder ? 'always' : _floatLabel"
	[class.incoming-secret-value-as-placeholder]="isIncomingSecretValueUsedAsPlaceholder"
	[class.form-field-pending]="pending || _internalControl.pending"
	[class.error-hidden]="hideErrorText"
	[class.has-prefix]="!!prefix"
	[class.has-suffix]="!!suffix || !hideClearButton"
	[class.auto-grow]="autoGrow"
>
	<mat-label *ngIf="label || customLabel">
		<span [ngSwitch]="!!customLabel">
			<span *ngSwitchDefault>{{ label | bpTranslate | async }}</span>
			<ng-content *ngSwitchCase="true" select="bp-input-label" />
		</span>

		<!-- Custom required asterisk to avoid cases when it's not rendered due to disabled form -->
		<span
			*ngIf="required && !_defaultOptions?.hideRequiredMarker"
			class="mat-placeholder-required mat-form-field-required-marker"
		>
			*
		</span>

		<bp-hint *ngIf="longHint" size="sm" [message]="longHint" />
	</mat-label>

	<ng-content />

	<textarea
		*ngIf="textarea; else inputTpl"
		matInput
		[attr.maxlength]="maxLength"
		[attr.data-private]="shouldAddSecretAttribute"
		[class.private]="shouldAddSecretAttribute"
		[name]="_name"
		[formControl]="_internalControl"
		[placeholder]="
			isIncomingSecretValueUsedAsPlaceholder ? $$incomingSecretValue : $any(placeholder | bpTranslate | async)
		"
		[required]="!!required"
		(blur)="onInputBlur()"
	>
	</textarea>

	<ng-template #inputTpl>
		<input
			*ngIf="autocomplete; else defaultInputTpl"
			matInput
			size="1"
			[attr.enterkeyhint]="nativeAutocomplete ? null : 'go'"
			[attr.inputmode]="inputmode"
			[attr.maxlength]="maxLength"
			[attr.data-private]="shouldAddSecretAttribute"
			[class.private]="shouldAddSecretAttribute"
			[autocomplete]="_attrAutocompleteValue"
			[name]="_name"
			[type]="$$inputType"
			[formControl]="_internalControl"
			[placeholder]="
				isIncomingSecretValueUsedAsPlaceholder ? $$incomingSecretValue : $any(placeholder | bpTranslate | async)
			"
			[required]="!!required"
			[value]="value"
			[matAutocomplete]="autocomplete"
			(blur)="onInputBlur()"
		/>

		<ng-template #defaultInputTpl>
			<input
				matInput
				size="1"
				[attr.enterkeyhint]="nativeAutocomplete ? null : 'go'"
				[attr.inputmode]="inputmode"
				[attr.maxlength]="maxLength"
				[attr.data-private]="shouldAddSecretAttribute"
				[class.private]="shouldAddSecretAttribute"
				[autocomplete]="_attrAutocompleteValue"
				[name]="_name"
				[type]="$$inputType"
				[formControl]="_internalControl"
				[required]="!!required"
				[placeholder]="
					isIncomingSecretValueUsedAsPlaceholder
						? $$incomingSecretValue
						: $any(placeholder | bpTranslate | async)
				"
				[bpTextMaskPlaceholderFromMask]="!isIncomingSecretValueUsedAsPlaceholder"
				[bpTextMask]="$$mask"
				(blur)="onInputBlur()"
			/>
		</ng-template>
	</ng-template>

	<mat-hint *ngIf="hint || customHint" class="display-contents">
		<span class="display-contents" [ngSwitch]="!!customHint">
			<span *ngSwitchDefault>{{ hint | bpTranslate | async }}</span>
			<ng-content *ngSwitchCase="true" select="bp-input-hint, [bpInputHint]" />
		</span>
	</mat-hint>

	<span *ngIf="prefix" matPrefix class="display-contents">
		<ng-content select="bp-input-prefix, [bpInputPrefix]" />
	</span>

	<span *ngIf="hasSearchIcon" matPrefix class="display-contents">
		<mat-icon class="material-icons-round search-icon">search</mat-icon>
	</span>

	<span *ngIf="suffix || !hideClearButton" matSuffix class="display-contents">
		<ng-content select="bp-input-suffix, [bpInputSuffix]" />

		<div *ngIf="!hideClearButton" class="clear-button-wrapper">
			<div
				[class.show]="
					(isIncomingSecretValueUsedAsPlaceholder || !!_internalControl.value) && !_internalControl.disabled
				"
			>
				<button type="button" tabindex="-1" mat-icon-button (click)="_eraseInternalControlValue()">
					<mat-icon>close</mat-icon>
				</button>
			</div>
		</div>
	</span>

	<mat-error [bpFieldError]="name" [bpFieldErrorSuppress]="hideErrorText" />
</mat-form-field>
