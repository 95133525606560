import { isArray, isEmpty, uniq } from 'lodash-es';

import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import type { CountryCode } from '@bp/shared/models/countries';
import { Countries, Country } from '@bp/shared/models/countries';
import { bpQueueMicrotask } from '@bp/shared/utilities/core';

import { FADE_IN_LIST_STAGGERED } from '@bp/frontend/animations';
import { FormFieldControlComponent } from '@bp/frontend/components/core';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

import { InputComponent } from '../input';

@Component({
	selector: 'bp-country-selector',
	templateUrl: './country-selector.component.html',
	styleUrls: [ './country-selector.component.scss' ],
	host: {
		'(focusout)': 'onTouched()',
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CountrySelectorComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: CountrySelectorComponent,
			multi: true,
		},
	],
	animations: [ FADE_IN_LIST_STAGGERED ],
})
export class CountrySelectorComponent extends FormFieldControlComponent<Country | null, string | null> implements OnChanges {

	@Input() excluded!: Country[];

	@Input() override label?: string | null = 'Country or code';

	@Input() hasWorldwide = false;

	@Input()
	get countries() {
		return this._countries;
	}

	set countries(value: Country[] | null) {
		this._countries = value ?? Countries.listWithWorldwide;
	}

	private _countries = this._getCountriesAccordingToHasWorldwideFlag(Countries.listWithWorldwide);

	@Input() panelClass!: string;

	@ViewChild(InputComponent) private readonly _input?: InputComponent;

	override name = 'country';

	filtered = Countries.listWithWorldwide;

	override throttle = 0;

	worldwide = Countries.worldwide;

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		const { excluded, hasWorldwide, countries, value } = changes;

		if (excluded) {
			this._countries = this._getCountriesAccordingToHasWorldwideFlag(isArray(this.excluded)
				? Countries.listWithWorldwide.filter(it => !this.excluded.includes(it))
				: Countries.listWithWorldwide);
		}

		if (countries) {
			this._countries = this._getCountriesAccordingToHasWorldwideFlag(
				isEmpty(this._countries) ? Countries.listWithWorldwide : this._countries,
			);

			this.filtered = this._countries;
		}

		if (hasWorldwide || excluded || countries) {
			this._countries = this._getCountriesAccordingToHasWorldwideFlag(this._countries);

			this.filtered = this._getCountriesAccordingToHasWorldwideFlag(this.filtered);
		}

		if (value) {
			const country = !this.value || !this.hasWorldwide && this.value === Countries.worldwide
				? null
				: this.value;

			this._filterCountries(country?.name);

			this.writeValue(country);
		}
	}

	focus(): void {
		this._input?.focus();
	}

	// #region Implementation of the ControlValueAccessor interface
	override writeValue(value?: Country | CountryCode | object | null): void {
		bpQueueMicrotask(() => {
			this._setIncomingValue(value instanceof Country
				? value
				: (value && Countries.findByCodeString(<string>value.valueOf())) ?? null);

			this._setIncomingValueToInternalControl(this._getInternalControlValue() ?? '');
		});
	}
	// #endregion Implementation of the ControlValueAccessor interface

	/*
	 * #region Implementation of the Validator interface
	 * tslint:disable-next-line: no-unnecessary-type-annotation
	 */
	protected override _validator: ValidatorFn | null = ({ value }: AbstractControl): ValidationErrors | null => !value && this._internalControl.value
		? { countryNotFound: true }
		: null;

	// #endregion Implementation of the Validator interface

	private _getInternalControlValue() {
		return this.value?.name;
	}

	protected override _onInternalControlValueChange(input: string | null) {
		this._filterCountries(input);

		if (this.value?.name === input)
			return;

		this.setValue(Countries.findByName(input));
	}

	private _getCountriesAccordingToHasWorldwideFlag(list: Country[]) {
		return this.hasWorldwide
			? uniq([ Countries.worldwide, ...list ])
			: list.filter(v => v !== Countries.worldwide);
	}

	private _filterCountries(input?: string | null) {
		const loweredInput = input?.toLowerCase();

		this.filtered = loweredInput
			? this._countries.filter(
				it => it.lowerCaseName?.includes(loweredInput) || it.lowerCaseCode === loweredInput,
			)
			: this._countries;
	}
}
