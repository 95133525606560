import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';

import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';

import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';

@Component({
	selector: 'bp-payment-card-brand-icon',
	standalone: true,
	imports: [
		CommonModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesSvgIconsModule,
	],
	templateUrl: './payment-card-brand-icon.component.html',
	styleUrls: [ './payment-card-brand-icon.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardBrandIconComponent implements OnChanges, OnInit {

	@Input() src!: PaymentCardBrand | string;

	@Input() noTooltip?: BooleanInput;

	protected _brand!: PaymentCardBrand | null;

	ngOnChanges(): void {
		this._brand = this.src instanceof PaymentCardBrand
			? this.src
			: PaymentCardBrand.parse(this.src);
	}

	ngOnInit(): void {
		this.noTooltip = coerceBooleanProperty(this.noTooltip);
	}

}
