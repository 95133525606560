import type { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { subscribeOn } from 'rxjs/operators';

import { BpScheduler } from '../schedulers';

export function subscribeOutsideNgZone<T>(): MonoTypeOperatorFunction<T> {
	return (source$: Observable<T>) => source$.pipe(
		subscribeOn(BpScheduler.outside),
	);
}
