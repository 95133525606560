import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';

import { FADE } from '@bp/frontend/animations';
import { BaseDialogComponent } from '@bp/frontend/components/dialogs';

@Component({
	selector: 'bp-turnstile-dialog',
	standalone: true,
	imports: [ MatLegacyDialogModule ],
	templateUrl: './turnstile-dialog.component.html',
	styleUrls: [ './turnstile-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class TurnstileDialogComponent extends BaseDialogComponent {

	constructor() {
		super();

		this._dialogRef.addPanelClass('turnstile-dialog-overlay-pane');
	}

	protected _canCloseWithTrueOnEnter(): boolean {
		return false;
	}

}
