<ng-container *ngFor="let item of items">
	<span
		*bpDelayedRender="0; stagger: 3; allStagger: true"
		class="boxed-value"
		@fadeInSemiSlow
		[bpTooltip]="item.description"
	>
		{{ item!.displayName || item }}
	</span>
</ng-container>
