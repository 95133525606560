<ng-container [ngSwitch]="mode">
	<mat-select
		*ngSwitchCase="'select'"
		placeholder="Period"
		[panelClass]="['mat-primary', panelClass]"
		[value]="selected"
		[disableOptionCentering]="true"
		(valueChange)="select($event)"
	>
		<mat-option>-- Period --</mat-option>
		<mat-option *ngFor="let shortcut of dateRangeShortcuts" [value]="shortcut" [bpTooltip]="shortcut.description">
			{{ shortcut.displayName }}
		</mat-option>
	</mat-select>

	<mat-button-toggle-group
		*ngSwitchCase="'buttons-group'"
		class="button-toggle-group-rounded"
		[value]="selected"
		(valueChange)="select($event)"
	>
		<mat-button-toggle
			*ngFor="let shortcut of dateRangeShortcuts"
			[value]="shortcut"
			[bpTooltip]="shortcut.dateRange.formatted"
		>
			{{ shortcut.displayName }}
		</mat-button-toggle>
	</mat-button-toggle-group>

	<ng-container *ngSwitchDefault>
		<button
			*ngFor="let shortcut of dateRangeShortcuts"
			class="link interactive"
			type="button"
			[bpTooltip]="shortcut.description"
			[class.active]="shortcut === selected"
			(click)="select(shortcut)"
		>
			{{ shortcut.displayName }}
		</button>
	</ng-container>
</ng-container>
