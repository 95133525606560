import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { networkIsOnline$ } from './network-is-online.observable';

export function waitUntilNetworkIsOnline$(): Observable<boolean> {
	return networkIsOnline$()
		.pipe(
			first(isOnline => isOnline),
		);
}
