
import fiatCurrenciesNumericCodes from './fiat-currencies-numeric-codes';
import { FiatCurrency } from './fiat-currency';

export function getFiatCurrencyNumericCode(currency: FiatCurrency): string {
	const numericCode = fiatCurrenciesNumericCodes[currency.code];

	if (!numericCode)
		throw new Error(`Fiat currency numeric code for currency code ${ currency.code } not found`);

	return numericCode;
}
