import { isNil, omitBy } from 'lodash-es';

import { HttpParams } from '@angular/common/http';

import { MetadataQueryParamsBase } from '@bp/shared/models/metadata';

export function toHttpParams(query?: MetadataQueryParamsBase): HttpParams | undefined {
	return query
		? new HttpParams({
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			fromObject: <any> omitBy(query, isNil),
		})
		: undefined;
}
