import { AppStorageService } from './app-storage.service';
import { IAppStorageServiceConfig } from './models';

export class SessionStorageService extends AppStorageService {

	constructor(config: Omit<IAppStorageServiceConfig, 'storageAreaType'>) {
		super({
			...config,
			storageAreaType: 'sessionStorage',
		});
	}

}
