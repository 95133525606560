import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BaseDialogComponent } from '../base-dialog';

@Component({
	selector: 'bp-logout-confirm-dialog',
	templateUrl: './logout-confirm-dialog.component.html',
	styleUrls: [ './logout-confirm-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutConfirmDialogComponent extends BaseDialogComponent {

	protected _canCloseWithTrueOnEnter(): boolean {
		return true;
	}

}
