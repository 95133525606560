import m from 'moment';
import { interval, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { Memoize } from 'typescript-memoize';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { momentMapper } from '@bp/shared/models/metadata';

import { MomentBasePipe } from './moment-base.pipe';

@Pipe({
	standalone: true,
	name: 'momentFromNow',
})
export class MomentFromNowPipe extends MomentBasePipe implements PipeTransform {

	@Memoize((momentInput: m.MomentInput, withoutSuffix?: 'withoutSuffix') => `${ momentInput }-${ withoutSuffix }`)
	transform(momentInput: m.MomentInput, withoutSuffix?: 'withoutSuffix'): Observable<string> {
		return this._momentTimezoneService.timezone$.pipe(
			switchMap(() => this.__momentFromNowUpdatedEach30Seconds(momentInput, withoutSuffix)),
		);
	}

	private __momentFromNowUpdatedEach30Seconds(
		momentInput: m.MomentInput,
		withoutSuffix: string | undefined,
	): Observable<string> {
		const moment = momentMapper(momentInput);
		const getMomentFromNow = (): string => moment.fromNow(!!withoutSuffix);

		return interval(1000 * 30)
			.pipe(
				map(getMomentFromNow),
				startWith(getMomentFromNow()),
			);
	}

}
