import { NgModule } from '@angular/core';

import { MomentFormatPipe } from './moment-format.pipe';
import { MomentFromNowPipe } from './moment-from-now.pipe';
import { MomentIsSameOrBeforeNowPipe } from './moment-is-same-or-before-now.pipe';
import { MomentToNowPipe } from './moment-to-now.pipe';

const exposed = [
	MomentFormatPipe,
	MomentFromNowPipe,
	MomentToNowPipe,
	MomentIsSameOrBeforeNowPipe,
];

@NgModule({
	imports: exposed,
	exports: exposed,
})
export class SharedFeaturesMomentPipesModule {}
