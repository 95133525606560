import fastdom from 'fastdom';
import { Observable } from 'rxjs';

import { subscribeOutsideNgZone } from '../operators';

export function fromMeasure<T>(measure: () => T) {
	return new Observable<T>(subscriber => {
		const id = fastdom.measure(() => {
			subscriber.next(measure());

			subscriber.complete();
		});

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		(<any> fastdom).catch = (error: any) => void subscriber.error(error);

		return () => fastdom.clear(id);
	})
		.pipe(subscribeOutsideNgZone());
}
