import { Enumeration } from '@bp/shared/models/core/enum';

export class ToastType extends Enumeration {

	static info = new ToastType();

	static success = new ToastType();

	static warning = new ToastType();

	static error = new ToastType();

}
