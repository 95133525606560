import { Observable } from 'rxjs';

import { OnChanges, ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { IValidationErrors } from '@bp/shared/features/validation/models';

import { SLIDE } from '@bp/frontend/animations';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';

import { ErrorsTextsProviderService } from '../errors-texts-provider';

@Component({
	selector: 'bp-validation-error',
	templateUrl: './validation-error.component.html',
	styleUrls: [ './validation-error.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
})
export class ValidationErrorComponent extends Destroyable implements OnChanges {

	@Input('errors') errors!: IValidationErrors | null;

	@Input() animate = true;

	@Input() formControlName?: number | string | null;

	@HostBinding('class.mat-error') matError = true;

	errorText$!: Observable<string> | null;

	constructor(private readonly _errorTextsProvider: ErrorsTextsProviderService) {
		super();
	}

	ngOnChanges(): void {
		if (this.errors) {
			this.errorText$ = this._errorTextsProvider
				.renderFirstErrorTextAndObserveChanges(
					this.errors,
					this.formControlName,
				)
				.pipe(takeUntilDestroyed(this));
		} else
			this.errorText$ = null;
	}

}
