import { Countries } from './countries';
import { Country } from './country';
import { State } from './state';

export function countryMapper(input: Country | string): Country | null {
	return input instanceof Country ? input : Countries.findByCodeString(input);
}

export function countriesMapper(input: Country[] | string[]): Country[] {
	return input.map(countryMapper).filter((country): country is Country => !!country);
}

export function stateMapper(
	stateOrStateCode: State | string,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	_instanceDTO: any,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	thisInstance: { Country: Country } | { country: Country },
): State | null {
	if (stateOrStateCode instanceof State)
		return stateOrStateCode;

	const stateCode = stateOrStateCode.toUpperCase();

	if (!stateCode)
		return null;

	const country = 'country' in thisInstance ? thisInstance.country : thisInstance.Country;

	return country.states?.find(it => it.code === stateCode) ?? null;
}
