import { ICurrency } from './currency.interface';

export const ANY_CURRENCY_STUB: ICurrency = {
	code: 'ANY',
	displayName: 'Any',
	symbol: 'ANY',
	toJSON() {
		return this.code;
	},
	valueOf() {
		return this.code;
	},
	toString() {
		return this.displayName;
	},
};
