import { isObject, isString, isNil, compact, map, omit, pick } from 'lodash-es';

import {
	IValidationErrors, ValidationErrorValue, ValidationErrorTemplateVariables
} from '@bp/shared/features/validation/models';

export abstract class ErrorsTextsRendererService {

	protected _renderErrorText(
		validatorName: string,
		controlName?: number | string | null,
		validationError?: ValidationErrorValue,
	): string | null {
		if (isString(validationError))
			return validationError;

		const errorText = this._fetchErrorTextWithMasks(validatorName, controlName);

		return errorText && isObject(validationError)
			? this._substituteMasksIfAny(validationError, errorText)
			: errorText;
	}

	protected _renderValidationErrorsAsTexts(
		validationErrors: IValidationErrors,
		controlName?: number | string | null,
	): string[] {
		validationErrors = this.__moveRequiredValidationErrorToBottom(validationErrors);

		/*
		 * In case if we have an error for the control but don't have
		 * predefined error msg for the error we get [undefined], thus we compact it
		 */
		return compact(map(
			validationErrors,
			(validationError, validatorName) => this._renderErrorText(
				validatorName,
				controlName,
				validationError,
			),
		));
	}

	protected abstract _fetchErrorTextWithMasks(
		validatorName: string,
		controlName?: number | string | null,
	): string | null;

	protected _substituteMasksIfAny(
		validationErrorTemplateVariable: ValidationErrorTemplateVariables,
		errorTextWithMasks: string,
	): string {
		const masks = errorTextWithMasks.match(/\{\{\w+\}\}/gu);

		return masks
			? masks
				.map(mask => ({
					mask,
					maskValue: validationErrorTemplateVariable[
					<keyof ValidationErrorTemplateVariables>mask.replace(/\{\{|\}\}/ug, '')
					],
				}))
				.reduce(
					(errorText, { mask, maskValue }) => isNil(maskValue)
						? errorText
						: errorText.replace(mask, `${ maskValue }`),
					errorTextWithMasks,
				)
			: errorTextWithMasks;
	}

	private __moveRequiredValidationErrorToBottom(errors: IValidationErrors): IValidationErrors {
		const requiredValidationRuleName = 'required';

		return <IValidationErrors> {
			...omit(errors, requiredValidationRuleName),
			...pick(errors, requiredValidationRuleName),
		};
	}
}
