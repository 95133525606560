import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { SharedComponentsControlsModule } from '@bp/frontend/components/controls';
import { SharedPipesModule } from '@bp/frontend/pipes';

import {
	ActionConfirmDialogComponent, DisableConfirmDialogComponent, DeleteConfirmDialogComponent
} from './action-confirm-dialog';
import { DiscardChangesConfirmDialogComponent } from './discard-changes-confirm-dialog';
import { LogoutConfirmDialogComponent } from './logout-confirm-dialog';

const EXPOSED_DIALOGS = [
	DeleteConfirmDialogComponent,
	DisableConfirmDialogComponent,
	ActionConfirmDialogComponent,
	DiscardChangesConfirmDialogComponent,
	LogoutConfirmDialogComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MatDialogModule,
		MatButtonModule,
		ReactiveFormsModule,

		SharedPipesModule,
		SharedComponentsControlsModule,
	],
	exports: EXPOSED_DIALOGS,
	declarations: EXPOSED_DIALOGS,
})
export class SharedComponentsDialogsModule { }
