<mat-form-field
	*ngIf="multiple !== undefined"
	[color]="color"
	[appearance]="_matAppearance"
	[hideRequiredMarker]="true"
	[floatLabel]="_floatLabel"
	[class.error-hidden]="hideErrorText"
>
	<mat-label *ngIf="label">
		{{ label | bpTranslate | async }}

		<span
			*ngIf="required && !_defaultOptions?.hideRequiredMarker"
			class="mat-placeholder-required mat-form-field-required-marker"
		>
			*
		</span>

		<bp-hint *ngIf="longHint" size="sm" [message]="longHint" />
	</mat-label>

	<mat-select
		[formControl]="_internalControl"
		[placeholder]="(placeholder | bpTranslate | async) ?? ''"
		[required]="!!required"
		[multiple]="multiple"
		[panelClass]="panelClass"
		[disableOptionCentering]="disableOptionCentering"
		(selectionChange)="selectionChange.emit($event)"
		(openedChange)="_onPanelOpenedChange$.next($event)"
	>
		<mat-select-trigger>
			<ng-container *ngIf="multiple; else singleValueTpl">
				{{ _internalControl.value?.length }}
				{{ itemKind | pluralizeBy: _internalControl.value?.length }} selected
			</ng-container>

			<ng-template #singleValueTpl>
				<span [bpTooltip]="_hasDescription(_internalControl.value) ? _internalControl.value.description : null">
					{{ _internalControl.value | bpDisplayValue: itemDisplayValuePropertyName | bpTranslate | async }}
				</span>
			</ng-template>
		</mat-select-trigger>

		<mat-option *ngIf="!required" class="reset-option" [ngClass]="optionClass!" [value]="_resetOption">
			{{ resetOptionText | bpTranslate | async }}
		</mat-option>

		<mat-option
			*ngFor="let item of _sortedItems$ | async"
			bpTooltipShownOnViewportOverflow
			[ngClass]="optionClass!"
			[class.has-description]="_hasDescription(item)"
			[value]="useItemValueOf ? (item | bpValueOf) : item"
			[bpTooltip]="item | bpDisplayValue: itemDisplayValuePropertyName | bpTranslate | async"
		>
			<div bpShowTooltipOnViewportOverflow bpViewportOverflownObserver class="mat-option-text-content">
				<ng-template
					*ngIf="_customOptionTpl; else defaultSelectOptionViewTpl"
					[ngTemplateOutlet]="_customOptionTpl"
					[ngTemplateOutletContext]="{ $implicit: item }"
				/>

				<ng-template #defaultSelectOptionViewTpl>
					{{ item | bpDisplayValue: itemDisplayValuePropertyName | bpTranslate | async }}
				</ng-template>
			</div>

			<div *ngIf="_hasDescription(item)" class="description mat-caption">
				{{ item.description }}
			</div>
		</mat-option>
	</mat-select>

	<mat-hint *ngIf="hint">{{ hint | bpTranslate | async }}</mat-hint>

	<mat-error [bpFieldError]="name" [bpFieldErrorSuppress]="hideErrorText" />
</mat-form-field>
