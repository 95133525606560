import type { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

export function filterWhileNotifierTrue<T>(notifier$: Observable<boolean>): MonoTypeOperatorFunction<T> {
	return (source$: Observable<T>) => source$
		.pipe(
			withLatestFrom(notifier$),
			filter(([ , notifierState ]) => !!notifierState),
			map(([ sourceValue ]) => sourceValue),
		);

}
