import { isEmpty } from 'lodash-es';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function mapToNullOnEmpty<T>(source$: Observable<T | null | undefined>): Observable<T | null> {
	return <Observable<T | null>> <unknown> source$.pipe(
		map(sourceValue => isEmpty(sourceValue) ? null : sourceValue),
	);
}

