import type { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { observeOn } from 'rxjs/operators';

import { BpScheduler } from '../schedulers';

export function observeInsideNgZone<T>(): MonoTypeOperatorFunction<T> {
	return (source$: Observable<T>) => source$.pipe(
		observeOn(BpScheduler.inside),
	);
}
