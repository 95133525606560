<picture>
	<ng-container *ngFor="let source of sources">
		<source
			*ngIf="source.webp"
			type="image/webp"
			srcset="{{source.webp}}, {{source.webp2x}} 2x"
			[attr.media]="source.breakpoint ? source.breakpoint : null"
		/>

		<source
			*ngIf="source.png"
			srcset="{{source.png}}, {{source.png2x}} 2x"
			[attr.media]="source.breakpoint ? source.breakpoint : null"
		/>

		<source
			*ngIf="source.svg"
			srcset="{{source.svg}}"
			[attr.media]="source.breakpoint ? source.breakpoint : null"
		/>
	</ng-container>

	<img alt="{{ alt || imageName }}" [attr.loading]="eager ? null : 'lazy'" />
</picture>
