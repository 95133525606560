<mat-form-field
	[color]="color"
	[appearance]="_matAppearance"
	[hideRequiredMarker]="true"
	[floatLabel]="_floatLabel"
	[class.form-field-pending]="pending"
>
	<mat-label *ngIf="label">
		{{ label }}

		<span
			*ngIf="required && !_defaultOptions?.hideRequiredMarker"
			class="mat-placeholder-required mat-form-field-required-marker"
		>
			*
		</span>

		<bp-hint *ngIf="longHint" size="sm" [message]="longHint" />
	</mat-label>

	<mat-chip-list #chipList [formControl]="_externalControl!">
		<mat-chip
			*ngFor="let valueChip of value"
			#chip
			@fade
			[color]="color"
			[bpTooltip]="valueChip.description"
			[removable]="true"
			(removed)="remove(valueChip)"
		>
			@if (_customChipTpl) {
				<ng-container *ngTemplateOutlet="_customChipTpl; context: { $implicit: valueChip }" />
			} @else {
				<div
					bpTooltipShownOnViewportOverflow
					class="text-ellipsis chip-text"
					bpTooltipPlacement="bottom"
					[bpTooltip]="valueChip | bpDisplayValue: itemDisplayPropertyName | bpTranslate | async"
				>
					<span bpShowTooltipOnViewportOverflow bpViewportOverflownObserver>
						{{ valueChip | bpDisplayValue: itemDisplayPropertyName | bpTranslate | async }}
					</span>
				</div>
			}

			<mat-icon matChipRemove class="material-icons-round">cancel</mat-icon>
		</mat-chip>

		<input
			#input
			[formControl]="_internalControl"
			[attr.name]="name"
			[placeholder]="placeholder ?? ''"
			[required]="!!required"
			[matAutocomplete]="autocomplete"
			[matChipInputFor]="chipList"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			(matChipInputTokenEnd)="add($event)"
		/>
	</mat-chip-list>

	<mat-autocomplete
		#autocomplete="matAutocomplete"
		[class]="_customAutocompleteOption?.bpAutocompleteOptionPanelClass ?? ''"
		(optionSelected)="selected($event)"
	>
		<div *ngIf="autocomplete.isOpen" @fadeInListStaggered>
			<mat-option *ngIf="!required" class="chip-autocomplete-option reset-option" [value]="_resetOption">
				{{ resetOptionText ?? 'None' | bpTranslate | async }}
			</mat-option>

			<mat-option *ngIf="!pending && !(filtered$ | async)?.length" disabled>No items</mat-option>

			<div *ngFor="let chip of filtered$ | async; trackBy: _trackByFn">
				<mat-option
					*bpDelayedRender="0; allStagger: true"
					class="chip-autocomplete-option"
					[class.has-description]="!_customAutocompleteOption && chip.description"
					[value]="chip"
				>
					<div>
						@if (_customAutocompleteOption) {
							<ng-container
								*ngTemplateOutlet="_customAutocompleteOption.tpl; context: { $implicit: chip }"
							/>
						} @else {
							{{ chip | bpDisplayValue: itemDisplayPropertyName | bpTranslate | async }}
						}
					</div>

					<div *ngIf="!_customAutocompleteOption && chip.description" class="description mat-caption">
						{{ chip.description }}
					</div>
				</mat-option>
			</div>
		</div>
		<!-- the mat option below is a trick not to render all the options on render of this component, mat-autocomplete needs options to be present to start render them in dom -->
		<mat-option class="hidden" disabled />
	</mat-autocomplete>

	<mat-hint *ngIf="hint">{{ hint }}</mat-hint>

	<mat-error [bpFieldError]="name" [bpFieldErrorSuppress]="hideErrorText" />
</mat-form-field>
