import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpResponse, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase, HttpContextToken, HttpContext } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Deployment, IApiResponse } from '@bp/shared/models/core';
import { hasOwnProperty } from '@bp/shared/utilities/core';

import { retryOnTimeoutOr5XXOrUnknownErrorWithScalingDelay } from '@bp/frontend/models/common';
import { BpError } from '@bp/frontend/models/core';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { noop } from '@bp/frontend/rxjs';

import { CORRELATION_ID_HEADER } from './http-config.service';
import { HttpBaseInterceptorService } from './http-base.interceptor.service';

const retryOn503ErrorContextToken = new HttpContextToken<boolean>(() => false);

export function retryOn503Error(context?: HttpContext): HttpContext {
	return (context ?? new HttpContext()).set(retryOn503ErrorContextToken, true);
}

const retryOnAnyTechnicalErrorContextToken = new HttpContextToken<boolean>(() => false);

export function retryOnAnyTechnicalError(context?: HttpContext): HttpContext {
	return (context ?? new HttpContext()).set(retryOnAnyTechnicalErrorContextToken, true);
}

const retryOnNoResponseErrorOnlyContextToken = new HttpContextToken<boolean>(() => false);

export function retryOnNoResponseErrorOnly(context?: HttpContext): HttpContext {
	return (context ?? new HttpContext()).set(retryOnNoResponseErrorOnlyContextToken, true);
}

@Injectable()
export class HttpResponseInterceptorService extends HttpBaseInterceptorService implements HttpInterceptor {

	private readonly __telemetry = inject(TelemetryService);

	private readonly __environment = inject(EnvironmentService);

	private readonly __capturedErrorMessages = new Set<string>();

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next
			.handle(request)
			.pipe(
				this._turnstileService.whenRequestChallengedShowChallengeDialog(),
				catchError(async (error: unknown) => {
					void this.__setDeploymentsRepositorySummariesToEnvironmentExtractedFromHeaders(error);

					this.__redirectOn3XX(error);

					throw await this.__coerceBpError(error);
				}),
				this.addRetryLogic(request),
				catchError((error: unknown) => {
					if (error instanceof BpError)
						this.__ifCriticalErrorReportIt(error, request);

					throw error;
				}),
				tap(httpEvent => {
					void this.__saveCorrelationIdFromResponseForNextRequests(httpEvent);

					void this.__setDeploymentsRepositorySummariesToEnvironmentExtractedFromHeaders(httpEvent);
				}),
				map(httpEvent => this.__normalizeApiResponse(httpEvent)),
			);
	}

	private addRetryLogic<T>(request: HttpRequest<unknown>): MonoTypeOperatorFunction<T> {
		if (request.context.get(retryOnNoResponseErrorOnlyContextToken))
			return retryOnTimeoutOr5XXOrUnknownErrorWithScalingDelay({ retryOnNoResponseOnly: true });

		if (request.method === 'GET' || request.context.get(retryOnAnyTechnicalErrorContextToken))
			return retryOnTimeoutOr5XXOrUnknownErrorWithScalingDelay();

		if (request.context.get(retryOn503ErrorContextToken))
			return retryOnTimeoutOr5XXOrUnknownErrorWithScalingDelay({ retryOn503Only: true });

		return noop();
	}

	private __redirectOn3XX(error: unknown): void {
		if (!(error instanceof HttpErrorResponse))
			return;

		const is3XX = error.status >= 300 && error.status < 400;
		const newLocation = error.headers.get('Location');

		if (is3XX && newLocation)
			globalThis.location.href = newLocation;
	}

	private __saveCorrelationIdFromResponseForNextRequests(httpEvent: HttpEvent<any>): void {
		if (httpEvent instanceof HttpResponse && httpEvent.headers.has(CORRELATION_ID_HEADER)) {
			this._httpConfigService.setHttpHeader(
				CORRELATION_ID_HEADER,
				httpEvent.headers.get(CORRELATION_ID_HEADER)!,
			);
		}
	}

	private __setDeploymentsRepositorySummariesToEnvironmentExtractedFromHeaders(httpEvent: unknown): void {
		if (!(httpEvent instanceof HttpResponseBase))
			return;

		const deployment = Deployment.parse(httpEvent.headers.get('x-powered-by'));

		if (!deployment)
			return;

		this.__environment.setDeploymentRepositorySummary(
			deployment,
			{
				...this.__environment.parseAppVersion(httpEvent.headers.get('x-app-version') ?? ''),
				pullRequestRepository: httpEvent.headers.get('x-pull-request-repository'),
				pullRequestTitle: httpEvent.headers.get('x-pull-request-title'),
				pullRequestNumber: httpEvent.headers.get('x-pull-request-number'),
				pullRequestUpdatedAt: httpEvent.headers.get('x-pull-request-updated-at'),
				pullRequestUpdatedBy: httpEvent.headers.get('x-pull-request-updated-by'),
			},
		);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	private __normalizeApiResponse(
		httpEvent: HttpEvent<IApiResponse<unknown>>,
	) {
		if (!(httpEvent instanceof HttpResponse))
			return httpEvent;

		const body = this._buildHttpEventBody(
			httpEvent,
			this._httpConfigService.getApiEndpointNamespaceConfig(httpEvent.url!),
		);

		return httpEvent.clone({
			body: body && hasOwnProperty(body, 'result') ? body.result : body,
		});
	}

	private async __coerceBpError(error: unknown): Promise<BpError> {
		let bpError: BpError;

		if (error instanceof HttpErrorResponse && error.error instanceof Blob) {
			try {
				bpError = new BpError(JSON.parse(
					await (new Response(error.error)).text(),
				));
			} catch {
				bpError = new BpError(error);
			}
		} else
			bpError = BpError.fromUnknown(error);

		return bpError;
	}

	private __ifCriticalErrorReportIt(error: BpError, request: HttpRequest<any>): void {
		const isCriticalError = error.is5XX && !error.isTimeout || !error.isNoResponse && error.message.toLowerCase().includes('error');

		if (!isCriticalError || this.__environment.isNotProduction && (error.is503 || error.is502))
			return;

		const resourceErrorMessage = `${ BpError.criticalErrorPrefix }: ${ error.status } ${ error.message } @ ${ request.method }: ${ this.__extractResource(error) }`;

		if (this.__capturedErrorMessages.has(resourceErrorMessage))
			return;

		this.__capturedErrorMessages.add(resourceErrorMessage);

		this.__telemetry.captureError(new BpError({
			...error,
			message: resourceErrorMessage,
		}));
	}

	private __extractResource(error: BpError): string {
		let resource = 'Unknown resource';

		if (error.requestUrl) {
			try {
				const requestUrl = new URL(error.requestUrl);

				resource = `${ requestUrl.origin }${ requestUrl.pathname }`;
			} catch {}
		}

		return resource;
	}
}
