import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IfGreaterThanDirective, IfLessThanDirective } from './directives';

const EXPOSED = [
	IfLessThanDirective,
	IfGreaterThanDirective,
];

@NgModule({
	imports: [ CommonModule ],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesMediaModule {}
