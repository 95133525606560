import { findLast, isNil } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, share, startWith } from 'rxjs/operators';
import { Memoize } from 'typescript-memoize';

import { Injectable } from '@angular/core';

import { observeInsideNgZone } from '@bp/frontend/rxjs';

import { MediaBreakpointDevice, MediaBreakpointDeviceLiteral } from '../models';

type MediaBreakpoint = [ device: MediaBreakpointDevice, width: number, minWidthMediaQuery: MediaQueryList ];

type MediaBreakpoints = Readonly<MediaBreakpoint>[];

@Injectable({
	providedIn: 'root',
})
export class MediaService {

	private static __mediaBreakpoints: MediaBreakpoints | undefined;

	private static get _breakpoints(): MediaBreakpoints {
		return (this.__mediaBreakpoints ??= MediaService._createBreakpointsBasedOnSCSSVariables());
	}

	private static _createBreakpointsBasedOnSCSSVariables(): MediaBreakpoints {
		return window.getComputedStyle(document.body, ':before').content
			.replace(/"/ug, '')
			.split('|')
			.map(breakpointStringDefinition => <[ MediaBreakpointDevice, string ]> breakpointStringDefinition.split(':'))
			.map(([ mediaDevice, breakpointMinWidth ]) => <MediaBreakpoint>[
				mediaDevice,
				Number.parseInt(breakpointMinWidth),
				window.matchMedia(`(min-width: ${ breakpointMinWidth })`),
			]);
	}

	static getBreakpointWidth(targetMediaDevice: MediaBreakpointDevice): number {
		return MediaService._breakpoints.find(([ deviceType ]) => targetMediaDevice === deviceType)![1];
	}

	get phoneXs(): boolean {
		return this._checkCurrentDeviceMatchesTarget(MediaBreakpointDevice.PhoneXs);
	}

	get phone(): boolean {
		return this._checkCurrentDeviceMatchesTarget(MediaBreakpointDevice.Phone);
	}

	get phoneLg(): boolean {
		return this._checkCurrentDeviceMatchesTarget(MediaBreakpointDevice.PhoneLg);
	}

	get tablet(): boolean {
		return this._checkCurrentDeviceMatchesTarget(MediaBreakpointDevice.Tablet);
	}

	get laptop(): boolean {
		return this._checkCurrentDeviceMatchesTarget(MediaBreakpointDevice.Laptop);
	}

	get widescreen(): boolean {
		return this._checkCurrentDeviceMatchesTarget(MediaBreakpointDevice.Widescreen);
	}

	get isHighDPR(): boolean {
		return this._highDPRMediaQuery.matches;
	}

	get scaleFactor(): 1 | 2 {
		return this.isHighDPR ? 2 : 1;
	}

	currentBreakpointDevice!: MediaBreakpointDevice;

	readonly isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);

	readonly currentBreakpointChange$ = this._createBreakpointDeviceChangeObserver();

	private readonly _highDPRMediaQuery = window.matchMedia('(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)');

	constructor() {
		this.currentBreakpointChange$
			.subscribe(currentBreakpointDevice => (this.currentBreakpointDevice = currentBreakpointDevice));
	}

	/**
	 * True if current device type is greater than the target device type
	 */
	@Memoize()
	greaterThan$(targetMediaDeviceLiteral: MediaBreakpointDeviceLiteral): Observable<boolean> {
		this._assertBreakpointDevice(targetMediaDeviceLiteral);

		return this.currentBreakpointChange$.pipe(
			startWith(null),
			map(() => this.greaterThan(targetMediaDeviceLiteral)),
			distinctUntilChanged(),
		);
	}

	/**
	 * True if current device type is greater than the targeted device type
	 */
	greaterThan(targetMediaDeviceLiteral: MediaBreakpointDeviceLiteral): boolean {
		this._assertBreakpointDevice(targetMediaDeviceLiteral);

		const targetWidth = MediaService.getBreakpointWidth(MediaBreakpointDevice[targetMediaDeviceLiteral]);
		const currentWidth = MediaService.getBreakpointWidth(this.currentBreakpointDevice);

		return currentWidth > targetWidth;
	}

	/**
	 * True if current device type is less than the targeted device type
	 */
	@Memoize()
	lessThan$(targetMediaDeviceLiteral: MediaBreakpointDeviceLiteral): Observable<boolean> {
		this._assertBreakpointDevice(targetMediaDeviceLiteral);

		return this.currentBreakpointChange$.pipe(
			startWith(null),
			map(() => this.lessThan(targetMediaDeviceLiteral)),
			distinctUntilChanged(),
		);
	}

	/**
	 * True if current device type is less than the targeted device type
	 */
	lessThan(targetMediaDeviceLiteral: MediaBreakpointDeviceLiteral): boolean {
		this._assertBreakpointDevice(targetMediaDeviceLiteral);

		const targetWeight = MediaService.getBreakpointWidth(MediaBreakpointDevice[targetMediaDeviceLiteral]);
		const currentWeight = MediaService.getBreakpointWidth(this.currentBreakpointDevice);

		return currentWeight < targetWeight;
	}

	private _createBreakpointDeviceChangeObserver(): Observable<MediaBreakpointDevice> {
		return new Observable<MediaBreakpointDevice>(observer => {
			observer.next(this._getCurrentBreakpointDevice());

			MediaService._breakpoints
				.forEach(([ , , minWidthQuery ]) => void minWidthQuery.addListener(
					() => void observer.next(this._getCurrentBreakpointDevice()),
				));
		})
			.pipe(
				observeInsideNgZone(),
				share(),
			);
	}

	private _getCurrentBreakpointDevice(): MediaBreakpointDevice {
		return findLast(MediaService._breakpoints, ([ , , minWidthQuery ]) => minWidthQuery.matches)?.[0]
			?? MediaBreakpointDevice.PhoneXs;
	}

	private _checkCurrentDeviceMatchesTarget(targetMediaDevice: MediaBreakpointDevice): boolean {
		return MediaService._breakpoints
			.reduce(
				(previousResult: boolean, [ device, , minWidthQuery ]) => device === targetMediaDevice
					? minWidthQuery.matches
					: previousResult && !minWidthQuery.matches,
				false,
			);
	}

	private _assertBreakpointDevice(device: MediaBreakpointDeviceLiteral): void {
		if (isNil(MediaBreakpointDevice[device]))
			throw new Error(`There is no breakpoint with the name '${ device }'`);
	}
}
