import type { MonoTypeOperatorFunction } from 'rxjs';
import { concat, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ZoneService } from '../zone.service';

type PendingCallback = (pending: boolean) => any;

export function pending<T>(callbackOrSubject$: PendingCallback | Subject<boolean>): MonoTypeOperatorFunction<T> {
	return (source$: Observable<T>) => {
		let currentState: boolean;

		const emit = (state: boolean): void => {
			if (state !== currentState) {
				callbackOrSubject$ instanceof Subject
					? callbackOrSubject$.next(state)
					: ZoneService.ngZone.runTask(() => callbackOrSubject$(state));
			}

			currentState = state;
		};

		return concat(
			new Observable<T>(subscriber => {
				emit(true);

				subscriber.complete();
			}),
			source$.pipe(tap({
				next: () => void emit(false),
				error: () => void emit(false),
				complete: () => void emit(false),
			})),
		);
	};
}
