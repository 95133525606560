import { without } from 'lodash-es';
import { AsYouType as PhoneFormatter, CountryCode as PhoneFormatterCountryCode } from 'libphonenumber-js';

import {
	Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, EventEmitter, Output, inject,
	HostBinding, OnInit
} from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { FocusOrigin } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';

import { Country, Countries, CountryCode, DialCode } from '@bp/shared/models/countries';
import { TextMaskConfig, TextMask } from '@bp/shared/features/text-mask';
import { isEmpty, uuid } from '@bp/shared/utilities/core';

import { FormFieldControlComponent } from '@bp/frontend/components/core';
import { takeUntilDestroyed } from '@bp/frontend/models/common';
import { FADE_IN_LIST_STAGGERED } from '@bp/frontend/animations';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

import { InputComponent } from '../input';

type CountryWithUniqueDialCode = {
	country: Country;
	dialCode: DialCode;
};

@Component({
	selector: 'bp-phone-input',
	templateUrl: './phone-input.component.html',
	styleUrls: [ './phone-input.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_LIST_STAGGERED ],
	host: {
		'(focusout)': 'onTouched()',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PhoneInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: PhoneInputComponent,
			multi: true,
		},
	],
})
export class PhoneInputComponent extends FormFieldControlComponent<string | null> implements OnChanges, OnInit {

	private readonly __directionality = inject(Directionality, { optional: true });

	@Input() initialDialCodeCountry!: Country;

	@Output() readonly dialCodeChange = new EventEmitter<string>();

	@ViewChild(InputComponent, { static: true }) input!: InputComponent;

	@ViewChild(MatAutocompleteTrigger, { static: true }) autocompleteTrigger!: MatAutocompleteTrigger;

	@ViewChild('dialCodeCountryInput', { static: true }) dialCodeCountryInputRef!: ElementRef<HTMLInputElement>;

	@ViewChild('searchCountryInput', { static: true }) searchCountryInput!: InputComponent;

	@HostBinding('class.rtl')
	get isRtl(): boolean {
		return this.__directionality?.value === 'rtl';
	}

	private readonly __countriesWithUniqueDialCodes = this.__buildCountriesWithUniqueDialCodes(
		without(Countries.listWithWorldwide, Countries.worldwide),
	);

	protected _filteredCountriesWithDialCodes: CountryWithUniqueDialCode[] = this.__countriesWithUniqueDialCodes;

	protected _dialCodeCountry!: CountryWithUniqueDialCode;

	protected _dialCodeCountrySearchControl = new UntypedFormControl();

	protected _phoneNumberMask!: TextMaskConfig;

	protected _valueToDisableNativeAutocomplete = `value-to-disable-native-autocomplete-${ uuid() }`;

	private _phoneFormatter!: PhoneFormatter;

	constructor() {
		super();

		this._onCountrySearchTryFindCountryAndFilterCountries();
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		const { initialDialCodeCountry } = changes;

		if (initialDialCodeCountry?.currentValue && this._internalControl.pristine && !this.value)
			this._setCountryAndPhoneFormatting(this.initialDialCodeCountry);
	}

	override ngOnInit(): void {
		super.ngOnInit();

		if (!(this.initialDialCodeCountry instanceof Country))
			throw new Error('Initial dial code country must be set for the phone input component');
	}

	focus(): void {
		this.input.focus();
	}

	protected _onSelectionChange(
		event: MatOptionSelectionChange, countryWithDialCode: CountryWithUniqueDialCode,
	): void {
		if (!event.isUserInput)
			return;

		this._setCountryAndPhoneFormatting(countryWithDialCode);

		this._internalControl.updateValueAndValidity();

		setTimeout(() => void this.focus());
	}

	protected _focusSearchCountryInput(): void {
		setTimeout(() => void this.searchCountryInput.focus());
	}

	protected _closePanelOnFocusOut(_event: FocusOrigin): void {
		if (!event)
			this.autocompleteTrigger.closePanel();
	}

	protected _openSearchCountryPanel(event: Event): void {
		event.preventDefault();

		event.stopPropagation();

		this._dialCodeCountrySearchControl.setValue('');

		this.autocompleteTrigger.openPanel();
	}

	private _setCountryAndPhoneFormatting(country: Country | CountryWithUniqueDialCode): void {
		this._dialCodeCountry = country instanceof Country
			? this._findCountryWithDialCodeByCountry(country)
			: country;

		this.dialCodeChange.emit(this._dialCodeCountry.dialCode.internationalDialCodePrefix);

		this._phoneNumberMask = new TextMaskConfig({
			placeholderChar: TextMaskConfig.whitespace,
			placeholderFromMask: true,
			guide: false,
			prefix: this._dialCodeCountry.dialCode.internationalDialCodePrefix,
			mask: (rawValue: string) => this._buildPhoneMask(rawValue),
		});

		this._phoneFormatter = new PhoneFormatter(<PhoneFormatterCountryCode> this._dialCodeCountry.country.code);
	}

	protected override _onInternalControlValueChange(value: string | null): void {
		if (isEmpty(value) || value === this._dialCodeCountry.dialCode.internationalDialCodePrefix) {
			this.setValue(null);

			return;
		}

		// on autofill the dialCode will be present on the value
		const phoneNumberWithDialCode = value.startsWith(
			this._dialCodeCountry.dialCode.internationalDialCodePrefix,
		)
			? value
			: `${ this._dialCodeCountry.dialCode.internationalDialCodePrefix }${ value }`;

		this.setValue(phoneNumberWithDialCode.replaceAll(/[^0-9+]/ug, ''));
	}

	private _buildPhoneMask(rawValue: string): TextMask {
		if (isEmpty(rawValue))
			return [ null ];

		this._phoneFormatter.reset();

		this._phoneFormatter.input(rawValue);

		const guessedCountryCode = this._phoneFormatter.getCountry();
		const guessedCountry = guessedCountryCode ? Countries.get(<CountryCode>guessedCountryCode) : null;

		// Some countries have same dial code (e.g. Åland and Finland), so no need to change existed one in
		// the case, because guesser returns only first found country.
		const isGuessedCountryHasNoCommonDialCodesWithCurrentOne = guessedCountry?.dialCodes?.every(
			({ code }) => code !== this._dialCodeCountry.dialCode.code,
		);

		if (isGuessedCountryHasNoCommonDialCodesWithCurrentOne) {
			this._onDifferentCountryDuringMaskBuildingPlanUpdateCurrentCountry(guessedCountry!, rawValue);

			return [ null ];
		}

		// Library formats number differently for international and local numbers.
		// So if we want to keep local number formatting, we need to strip international part.
		// Note at this moment PhoneFormatter already have initialized with default country inferred
		// from international part code +XXX (from `_onDifferentCountryDuringMaskBuildingPlanUpdateCurrentCountry`
		// method above), so it is safe to remove this part.
		const rawValueWithoutDialCode = rawValue.replace(
			this._dialCodeCountry.dialCode.internationalDialCodePrefix, '',
		);

		this._phoneFormatter.reset();

		this._phoneFormatter.input(rawValueWithoutDialCode);

		return this._buildPhoneMaskBasedOnPhoneNumberLibTemplate(rawValueWithoutDialCode);
	}

	private _buildPhoneMaskBasedOnPhoneNumberLibTemplate(rawValue: string): TextMask {
		const phoneMask = [ ...this._getPhoneTemplateWithoutDialCodeTemplate(rawValue) ]
			.map(char => char === 'x' ? /\d/u : char);

		if (phoneMask[0] !== ' ')
			phoneMask.unshift(' ');

		return phoneMask;
	}

	private _getPhoneTemplateWithoutDialCodeTemplate(rawValue: string): string {
		let phoneTemplate = this._phoneFormatter.getTemplate();

		if (rawValue.startsWith(this._dialCodeCountry.dialCode.internationalDialCodePrefix)) {
			phoneTemplate = phoneTemplate.slice(
				this._dialCodeCountry.dialCode.internationalDialCodePrefix.length,
			);
		}

		return phoneTemplate;
	}

	private _onDifferentCountryDuringMaskBuildingPlanUpdateCurrentCountry(
		country: Country,
		rawValue: string,
	): void {
		queueMicrotask(() => {
			this._setCountryAndPhoneFormatting(country);

			this._cdr.detectChanges();

			this._internalControl.setValue(rawValue);
		});
	}

	private _onCountrySearchTryFindCountryAndFilterCountries(): void {
		this._dialCodeCountrySearchControl.valueChanges
			.pipe(takeUntilDestroyed(this))
			.subscribe(countrySearchInput => void this._filterCountries(countrySearchInput));
	}

	private _filterCountries(countrySearchInput?: string | null): void {
		const loweredSearch = countrySearchInput?.toLowerCase();

		this._filteredCountriesWithDialCodes = loweredSearch
			? this.__countriesWithUniqueDialCodes.filter(
				({ country }) => !!country.lowerCaseName?.includes(loweredSearch)
					|| country.lowerCaseCode === loweredSearch,
			)
			: this.__countriesWithUniqueDialCodes;
	}

	private _findCountryWithDialCodeByCountry(country: Country): CountryWithUniqueDialCode {
		return this.__countriesWithUniqueDialCodes.find(
			countryWithDialCode => countryWithDialCode.country === country,
		)!;
	}

	private __buildCountriesWithUniqueDialCodes(countries: Country[]): CountryWithUniqueDialCode[] {
		return countries.flatMap(country => country.dialCodes!
			.map(dialCode => ({
				country,
				dialCode,
			})));
	}

}
