import { Observable, of, timer } from 'rxjs';
import { first } from 'rxjs/operators';

import { BpScheduler } from '../schedulers';

type TaskTrackingZoneSpec = { macroTasks: { source: string }[] };

// Inspired by Scully detector
// https://github.com/scullyio/scully/blob/84e886f8e3144ad55e73382a5fc830027fdc07a0/libs/ng-lib/src/lib/idleMonitor/idle-monitor.service.ts#L130
// Note that external sources which are fetched without XHR must create dumb Zone task. See FirebaseService for example.
export function fromWaitUntilZoneXhrMacrotasksQueueEmpty(): Observable<void> {
	if (Zone.current === Zone.root)
		return of(void 0);

	const taskTrackingZone = <TaskTrackingZoneSpec | undefined>Zone.current.get('TaskTrackingZone');

	if (!taskTrackingZone)
		throw new Error('TaskTrackingZone is not defined');

	return <Observable<void>> timer(0, 10, BpScheduler.asyncOutside)
		.pipe(first(() => !taskTrackingZone.macroTasks.some(({ source }) => source.includes('XMLHttpRequest'))));
}
