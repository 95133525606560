import { Observable } from 'rxjs';

import type { Writeable } from '@bp/shared/typings';

export function fromViewportIntersection(
	$target: HTMLElement,
	options: IntersectionObserverInit & {
		ignoreHorizontalScrolling?: boolean;
		ignoreVerticalScrolling?: boolean;
	},
): Observable<IntersectionObserverEntry> {
	const ignoreHorizontalScrolling = options.ignoreHorizontalScrolling ?? true;
	const ignoreVerticalScrolling = options.ignoreVerticalScrolling ?? false;

	return new Observable(observer => {
		const intersectionObserver = new IntersectionObserver(
			([ entry ]) => {
				const correctedEntry = cloneEntry(entry);

				if (ignoreHorizontalScrolling)
					correctedEntry.intersectionRatio = entry.intersectionRect.height / entry.boundingClientRect.height || 0;

				if (ignoreVerticalScrolling)
					correctedEntry.intersectionRatio = entry.intersectionRect.width / entry.boundingClientRect.width || 0;

				observer.next(correctedEntry);
			},
			options,
		);

		intersectionObserver.observe($target);

		return () => void intersectionObserver.disconnect();
	});

}

function cloneEntry(entry: IntersectionObserverEntry): Writeable<IntersectionObserverEntry> {
	return {
		boundingClientRect: entry.boundingClientRect,
		intersectionRatio: entry.intersectionRatio,
		intersectionRect: entry.intersectionRect,
		isIntersecting: entry.isIntersecting,
		rootBounds: entry.rootBounds,
		target: entry.target,
		time: entry.time,
	};
}
