export const TIMINGS = '{{ timing }}s {{ delay }}s {{ease}}';

export const EASE = 'cubic-bezier(0.390, 0.575, 0.565, 1.000)';

export const FAST = { params: { timing: 0.15, ease: EASE, delay: 0 } };

export const SEMI_FAST = { params: { timing: 0.2, ease: EASE, delay: 0 } };

export const NORMAL = { params: { timing: 0.25, ease: EASE, delay: 0 } };

export const SEMI_SLOW = { params: { timing: 0.3, ease: EASE, delay: 0 } };

export const SLOW = { params: { timing: 0.4, ease: EASE, delay: 0 } };
