import { ChangeDetectionStrategy, Component } from '@angular/core';

// Loading filters from external SVG doesn't work in some browsers, so inline them for now.

// Used approach: https://stackoverflow.com/a/14060104/1642142

// Note that we cant make component `display: none`, because FF will ignore all internal filters in the case.
// So set zero height to SVG element itself.
@Component({
	selector: 'bp-svg-shared-filters-definitions',
	templateUrl: './svg-shared-filters-definitions.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [ ':host { position: fixed; top: -999999; left: -99999; display: block; height: 0; }' ],
})
export class SvgSharedFiltersDefinitionsComponent { }
