import { toggleableStateKeptInSessionStorageFactory } from './toggleable-state-kept-in-session-storage-factory';
import { MockedBackendState } from './mocked-backend-state';

const LOCAL_FIREBASE_IS_ACTIVE_STORAGE_KEY = 'local-firebase-is-active';

export class LocalFirebaseState extends toggleableStateKeptInSessionStorageFactory(LOCAL_FIREBASE_IS_ACTIVE_STORAGE_KEY) {

	static override toggleAndReloadPage(): void {
		if (!MockedBackendState.isActive)
			MockedBackendState.set(true);

		super.toggleAndReloadPage();
	}

}
