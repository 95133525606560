import { identity } from 'lodash-es';
import { Observable, OperatorFunction, timer } from 'rxjs';
import { map, share, startWith, switchMap } from 'rxjs/operators';

export function repeatLatestWhen<T>(
	notifier$: Observable<any>,
	mapperOnNotifierEmit: (v: T) => T = identity,
): OperatorFunction<T, T> {
	// we need to reset the notifier on the next even loop so on the following switchMap we will reuse the notifier
	// instead of resubscribing
	const sharedNotifier$ = notifier$.pipe(share({ resetOnRefCountZero: () => timer(0) }));

	return (source$: Observable<T>) => source$.pipe(
		switchMap(sourceValue => sharedNotifier$.pipe(
			map(() => mapperOnNotifierEmit(sourceValue)),
			startWith(sourceValue),
		)),
	);
}
