import m from 'moment';
import { interval, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { Memoize } from 'typescript-memoize';

import { PipeTransform, Pipe } from '@angular/core';

import { momentMapper } from '@bp/shared/models/metadata';

import { MomentBasePipe } from './moment-base.pipe';

@Pipe({
	standalone: true,
	name: 'momentToNow',
})
export class MomentToNowPipe extends MomentBasePipe implements PipeTransform {

	@Memoize((momentInput: m.MomentInput, withoutSuffix?: 'withoutSuffix') => `${ momentInput }-${ withoutSuffix }`)
	transform(momentInput: m.MomentInput, withoutSuffix?: 'withoutSuffix'): Observable<string> {
		return this._momentTimezoneService.timezone$.pipe(
			switchMap(() => this.__momentToNowUpdatedEach30Seconds(momentInput, withoutSuffix)),
		);
	}

	private __momentToNowUpdatedEach30Seconds(
		momentInput: m.MomentInput,
		withoutSuffix: string | undefined,
	): Observable<string> {
		const toMoment = momentMapper(momentInput);
		const getMomentToNow = (): string => m().to(toMoment, !!withoutSuffix);

		return interval(1000 * 30)
			.pipe(
				map(getMomentToNow),
				startWith(getMomentToNow()),
			);
	}

}
