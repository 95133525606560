import { camelCase, forOwn, isEmpty, toPath } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, EMPTY, lastValueFrom, merge, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, skip, startWith, switchMap } from 'rxjs/operators';

import {
	ChangeDetectorRef, Directive, HostBinding, inject, InjectionToken, Input, isDevMode, Output, ViewChild
} from '@angular/core';
import {
	AbstractControl, AsyncValidatorFn, ValidatorFn, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, FormControl
} from '@angular/forms';

import { FormControls, FormGroupConfig, NonFunctionProperties, Typify } from '@bp/shared/typings';
import { IErrorMessage } from '@bp/shared/models/core';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { BpError, OnChanges, SimpleChanges } from '@bp/frontend/models/core';
import { filterPresent, takeFirstTruthy } from '@bp/frontend/rxjs';

type FormControlsValidators<T> = Partial<Typify<NonFunctionProperties<T>, ValidatorFn[]>>;

type FormControlsAsyncValidators<T> = Partial<Typify<NonFunctionProperties<T>, AsyncValidatorFn[]>>;

export type FormOptions = {
	updateOn?: 'blur' | 'change' | 'submit';
};

export const FORM_DEFAULT_OPTIONS = new InjectionToken<FormOptions>('form-default-options');

@Directive()

export abstract class FormBaseComponent<TEntity = any, TFormControls = FormControls<TEntity>>
	extends Destroyable
	implements OnChanges {

	protected _formBuilder = inject(UntypedFormBuilder);

	protected _cdr = inject(ChangeDetectorRef);

	protected _toaster = inject(ToastrService);

	protected _formDefaultOptions = inject<FormOptions>(FORM_DEFAULT_OPTIONS, { optional: true });

	@ViewChild(FormGroupDirective)
	private readonly __formGroupDirective?: FormGroupDirective;

	@Input()
	get form(): UntypedFormGroup | null {
		return this.__form$.value;
	}

	set form(value: UntypedFormGroup | null) {
		this.__form$.next(value);
	}

	private readonly __form$ = new BehaviorSubject<UntypedFormGroup | null>(null);

	readonly form$ = this.__form$.pipe(skip(1));

	get controls(): TFormControls | null {
		return <any> this.form!.controls ?? null;
	}

	@Input() controlsValidators?: FormControlsValidators<TEntity> | null;

	@Input() controlsAsyncValidators?: FormControlsAsyncValidators<TEntity> | null;

	@HostBinding('class.pending')
	@Input()
	get pending(): boolean | null {

		return this.__externalPending$.value || !!this.form?.pending;
	}

	set pending(value: boolean | null) {
		this.__externalPending$.next(!!value);
	}

	private readonly __externalPending$ = new BehaviorSubject(false);

	@HostBinding('class.disabled')
	@Input()
	get disabled(): boolean | null {
		return this.__disabled$.value;
	}

	set disabled(value: boolean | null) {
		this.__disabled$.next(!!value);
	}

	private readonly __disabled$ = new BehaviorSubject(false);

	private readonly __formPending$ = this.form$
		.pipe(
			switchMap(form => form
				? form.statusChanges
					.pipe(
						startWith(form.status),
						map(status => status === 'PENDING'),
					)
				: of(false)),
			distinctUntilChanged(),
			shareReplay({ refCount: false, bufferSize: 1 }),
		);

	pending$ = combineLatest(
		this.__externalPending$,
		this.__formPending$,
	)
		.pipe(map(([ externalPending, formPending ]) => externalPending || formPending));

	disabled$ = combineLatest(
		this.pending$,
		this.__disabled$,
	)
		.pipe(map(([ pending, disabled ]) => pending || disabled));

	@Input()
	get error(): BpError | null | undefined {
		return this.__error;
	}

	set error(value: BpError | null | undefined) {
		this.__error = value;

		if (!value) {
			this.errors = null;

			return;
		}

		void this.__setGlobalAndControlErrors(value.messages);
	}

	private __error!: BpError | null | undefined;

	errors!: IErrorMessage[] | null;

	private readonly __submittedValidFormValue$ = new Subject<TEntity>();

	@Output('submitted')
	readonly submittedValidFormValue$ = this.__submittedValidFormValue$.asObservable();

	private readonly __formEnabled$ = new BehaviorSubject(true);

	@Output('formEnabled')
	readonly formEnabled$ = this.__formEnabled$.asObservable();

	private readonly __formDirtyAndValid$ = new BehaviorSubject(false);

	@Output('formDirtyAndValid')
	readonly formDirtyAndValid$ = this.__formDirtyAndValid$.asObservable();

	private readonly __formInvalid$ = new BehaviorSubject(false);

	@Output('formInvalid')
	readonly formInvalid$ = this.__formInvalid$.asObservable();

	readonly formValid$ = this.__formInvalid$.pipe(map(v => !v));

	private readonly __formDirty$ = new BehaviorSubject(false);

	@Output('formDirty')
	readonly formDirty$ = this.__formDirty$.asObservable();

	readonly formPristine$ = this.__formDirty$.pipe(map(v => !v));

	readonly formDirtyAndPending$ = combineLatest([
		this.formDirty$,
		this.pending$,
	]);

	@Output('canSave')
	readonly canSave$ = combineLatest([
		this.formDirtyAndValid$,
		this.pending$,
	])
		.pipe(map(([ dirtyAndValid, pending ]) => dirtyAndValid && !pending));

	readonly canNotSave$ = this.canSave$
		.pipe(map(canSave => !canSave));

	private __canSave = false;

	get canSave(): boolean {
		return this.__canSave;
	}

	@Output('canCreate')
	readonly canCreate$ = combineLatest([
		this.formValid$,
		this.pending$,
	])
		.pipe(map(([ valid, pending ]) => valid && !pending));

	private __canCreate = false;

	get canCreate(): boolean {
		return this.__canCreate;
	}

	onSubmitShowInvalidInputsToast = true;

	submitIfPristineAndValid = false;

	private __updateFormControlsValidatorsSubscription = Subscription.EMPTY;

	private __updateFormControlsAsyncValidatorsSubscription = Subscription.EMPTY;

	constructor() {
		super();

		this.__disableFormOnExternalPending();

		this.__observeFormEnabled();

		this.__observeFormInvalid();

		this.__observeFormDirty();

		this.__observeFormDirtyAndValid();

		this.__updateCanCreateOnStreamChange();

		this.__updateCanSaveOnStreamChange();

		this.__resetGlobalErrorsOnPending();
	}

	ngOnChanges(changes: SimpleChanges<this>): void {
		if (changes.controlsValidators) {
			this.__updateFormControlsValidators(
				changes.controlsValidators.previousValue,
				changes.controlsValidators.currentValue,
			);
		}

		if (changes.controlsAsyncValidators) {
			this.__updateFormControlsAsyncValidators(
				changes.controlsAsyncValidators.previousValue,
				changes.controlsAsyncValidators.currentValue,
			);
		}

	}

	submit(): void {
		isDevMode() && console.warn('submit', this.form);

		const formDirtyStateOnSubmit = this.form!.dirty;

		this.__revalidateAndMarkAsDirtyAndMarkAsTouchedRecursivelyToShowAllControlErrors(this.__getRootForm(this.form!));

		if (this.form!.valid) {
			if (this.submitIfPristineAndValid || formDirtyStateOnSubmit)
				this.__submittedValidFormValue$.next(this._getSubmittedValidFormValue());
			else
				this._toaster.info('Nothing to save');
		} else if (this.onSubmitShowInvalidInputsToast && this.form!.invalid)
			this._toaster.error('Some inputs are invalid!');

		this._cdr.detectChanges();

		this._cdr.markForCheck();
	}

	markFormAsPristineAndUntouched(): void {
		this.markAsPristine();

		this.form!.markAsUntouched();

		this.form!.updateValueAndValidity(); // To invoke changes
	}

	resetForm(): void {
		this.__formGroupDirective?.resetForm();
	}

	markAsDirty(): void {
		this.form!.markAsDirty();

		this.form?.updateValueAndValidity();// To invoke changes

		this.__formDirty$.next(true);
	}

	markAsPristine(): void {
		this.form!.markAsPristine();

		this.form?.updateValueAndValidity();// To invoke changes

		this.__formDirty$.next(false);
	}

	protected _getControls<UEntity>(): FormControls<UEntity> | null {
		return <any> this.form!.controls ?? null;
	}

	protected _getSubmittedValidFormValue(): TEntity {
		return this.form!.value;
	}

	protected _createFormGroup<U = TEntity>(config: FormGroupConfig<U>, options?: FormOptions): UntypedFormGroup {
		return this._formBuilder.group(config, {
			...this._formDefaultOptions,
			...options,
		});
	}

	private __getRootForm(form: UntypedFormArray | UntypedFormGroup): UntypedFormArray | UntypedFormGroup {
		let rootForm = form;

		while (rootForm.parent)
			rootForm = rootForm.parent;

		return rootForm;
	}

	private __revalidateAndMarkAsDirtyAndMarkAsTouchedRecursivelyToShowAllControlErrors(
		control: AbstractControl,
	): void {
		control.markAsTouched({ onlySelf: true });

		control.markAsDirty({ onlySelf: true });

		// Order is important, because some observables (e.g. formDirty$) relies on status change to update
		// its status. So it always must be the last one in state change statements list.
		// Also controls error displaying relies on their state, so validity should be updated after.
		control.updateValueAndValidity({ onlySelf: true });

		if (control instanceof UntypedFormGroup) {
			forOwn(
				control.controls,
				cntrl => void this.__revalidateAndMarkAsDirtyAndMarkAsTouchedRecursivelyToShowAllControlErrors(cntrl),
			);
		} else if (control instanceof UntypedFormArray) {
			control.controls.forEach(
				cntrl => void this.__revalidateAndMarkAsDirtyAndMarkAsTouchedRecursivelyToShowAllControlErrors(cntrl),
			);
		}
	}

	private __disableFormOnExternalPending(): void {
		merge(
			this.__externalPending$,
			this.__disabled$,
			this.form$,
		)
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => {
				if (!this.form)
					return;

				if (this.pending || this.disabled)
					this.form.disable({ emitEvent: false });
				else
					this.form.enable({ emitEvent: false });

			});
	}

	private __resetGlobalErrorsOnPending(): void {
		this.pending$
			.pipe(
				filter(pending => !!pending),
				takeUntilDestroyed(this),
			)
			.subscribe(() => (this.errors = null));
	}

	private __observeFormEnabled(): void {
		this.form$
			.pipe(
				switchMap(form => form
					? merge(form.statusChanges, this.pending$)
						.pipe(
							startWith(null),
							map(() => form.enabled),
						)
					: of(false)),
				distinctUntilChanged(),
				takeUntilDestroyed(this),
			)
			.subscribe(this.__formEnabled$);
	}

	private __observeFormInvalid(): void {
		this.form$
			.pipe(
				switchMap(form => form
					? combineLatest(form.statusChanges, this.pending$)
						.pipe(
							startWith([ form.status ]),
							filter(() => !this.pending),
							map(([ status ]) => status === 'INVALID' || isEmpty(form.value)),
						)
					: of(false)),
				distinctUntilChanged(),
				takeUntilDestroyed(this),
			)
			.subscribe(this.__formInvalid$);
	}

	private __observeFormDirty(): void {
		this.form$
			.pipe(
				switchMap(form => form
					? merge(form.statusChanges, this.pending$)
						.pipe(
							startWith(null),
							map(() => !!form.dirty),
						)
					: EMPTY),
				distinctUntilChanged(),
				takeUntilDestroyed(this),
			)
			.subscribe(this.__formDirty$);
	}

	private __observeFormDirtyAndValid(): void {
		combineLatest([
			this.formValid$,
			this.formDirty$,
		])
			.pipe(
				map(([ valid, dirty ]) => valid && dirty),
				takeUntilDestroyed(this),
			)
			.subscribe(this.__formDirtyAndValid$);
	}

	private async __setGlobalAndControlErrors(errors?: IErrorMessage[]): Promise<void> {
		await lastValueFrom(this.formEnabled$.pipe(takeFirstTruthy));

		if (errors && this.form) {
			const { errorAndControlPairs, errorsWithoutControls } = this.__partitionErrorsWithControlsAndWithout(errors);

			errorAndControlPairs
				.forEach(([ error, control ]) => void control.setErrors({ server: error.message }));

			this.errors = [
				...errors.filter(it => !it.field),
				...errorsWithoutControls,
			];
		}

		if (isEmpty(errors))
			this.__error = this.errors = null;

		this._cdr.detectChanges();
	}

	private __partitionErrorsWithControlsAndWithout(errors: IErrorMessage[]): {
		errorAndControlPairs: [IErrorMessage, FormControl][];
		errorsWithoutControls: IErrorMessage[];
	} {
		const errorAndControlPair = errors
			.filter(responseError => !!responseError.field)
			.map(responseError => {
				const abstractControl = this.form!.get(this.__buildFormControlPath(responseError.field!));

				return <const>[
					responseError,
					abstractControl instanceof FormControl ? abstractControl : null,
				];
			});

		return {
			errorAndControlPairs: errorAndControlPair
				.filter((pair): pair is [IErrorMessage, FormControl] => !!pair[1]),
			errorsWithoutControls: errorAndControlPair
				.filter(([ , control ]) => !control)
				.map(([ error ]) => error),
		};
	}

	private __buildFormControlPath(errorField: string): (number | string)[] {
		return toPath(errorField).map(camelCase);
	}

	private __updateCanCreateOnStreamChange(): void {
		this.canCreate$
			.pipe(takeUntilDestroyed(this))
			.subscribe(canCreate => (this.__canCreate = canCreate));
	}

	private __updateCanSaveOnStreamChange(): void {
		this.canSave$
			.pipe(takeUntilDestroyed(this))
			.subscribe(canSave => (this.__canSave = canSave));
	}

	private __updateFormControlsValidators(
		previousValidators?: FormControlsValidators<TEntity> | null,
		newValidators?: FormControlsValidators<TEntity> | null,
	): void {
		this.__updateFormControlsValidatorsSubscription.unsubscribe();

		this.__updateFormControlsValidatorsSubscription = this.__form$
			.pipe(
				filterPresent,
				takeUntilDestroyed(this),
			)
			.subscribe(form => {
				let shouldUpdate = false;

				for (const [ controlName, validators ] of Object.entries(previousValidators ?? {})) {
					shouldUpdate ||= (<ValidatorFn[]>validators)
						.some(validator => form.get(controlName)?.hasValidator(validator));

					form.get(controlName)?.removeValidators(<ValidatorFn[]>validators);
				}

				for (const [ controlName, validators ] of Object.entries(newValidators ?? {})) {
					shouldUpdate ||= (<ValidatorFn[]>validators)
						.some(validator => !form.get(controlName)?.hasValidator(validator));

					form.get(controlName)?.addValidators(<ValidatorFn[]>validators);
				}

				shouldUpdate && form.updateValueAndValidity();
			});
	}

	private __updateFormControlsAsyncValidators(
		previousAsyncValidators?: FormControlsAsyncValidators<TEntity> | null,
		newAsyncValidators?: FormControlsAsyncValidators<TEntity> | null,
	): void {
		this.__updateFormControlsAsyncValidatorsSubscription.unsubscribe();

		this.__updateFormControlsAsyncValidatorsSubscription = this.__form$
			.pipe(
				filterPresent,
				takeUntilDestroyed(this),
			)
			.subscribe(form => {
				let shouldUpdate = false;

				for (const [ controlName, validators ] of Object.entries(previousAsyncValidators ?? {})) {
					shouldUpdate ||= (<AsyncValidatorFn[]>validators)
						.some(validator => form.get(controlName)?.hasAsyncValidator(validator));

					form.get(controlName)?.removeAsyncValidators(<AsyncValidatorFn[]>validators);
				}

				for (const [ controlName, validators ] of Object.entries(newAsyncValidators ?? {})) {
					shouldUpdate ||= (<AsyncValidatorFn[]>validators)
						.some(validator => !form.get(controlName)?.hasAsyncValidator(validator));

					form.get(controlName)?.addAsyncValidators(<AsyncValidatorFn[]>validators);
				}

				shouldUpdate && form.updateValueAndValidity();
			});
	}

}
