/* eslint-disable @typescript-eslint/naming-convention */
import { Subregions } from './countries-by-sub-region';
import { Regions } from './countries-by-region';

export const SUBREGIONS_BY_REGION: Record<Regions, Subregions[]> = {
	Africa: [
		'Northern Africa',
		'Middle Africa',
		'Western Africa',
		'Southern Africa',
		'Eastern Africa',
	],
	Americas: [
		'Caribbean',
		'South America',
		'Central America',
		'Northern America',
	],
	Asia: [
		'Southern Asia',
		'Western Asia',
		'South-eastern Asia',
		'Eastern Asia',
		'Central Asia',
	],
	Europe: [
		'Northern Europe',
		'Southern Europe',
		'Western Europe',
		'Eastern Europe',
	],
	Oceania: [
		'Polynesia',
		'Australia and New Zealand',
		'Melanesia',
		'Micronesia',
	],
};
