import { isEqual, isString } from 'lodash-es';
import { of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { AfterViewInit, ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import type { AbstractControlDirective, ControlContainer, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { MatLegacyFormField as MatFormField } from '@angular/material/legacy-form-field';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { OptionalBehaviorSubject } from '@bp/frontend/rxjs';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: '[bpFieldError]',
	templateUrl: './field-error.component.html',
	styleUrls: [ './field-error.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldErrorComponent extends Destroyable implements OnChanges, AfterViewInit {

	private readonly __formField = inject(MatFormField, { optional: true });

	private readonly __formGroupDirective = inject(FormGroupDirective, { optional: true });

	@Input('bpFieldError') formControlOrName?: AbstractControl | string;

	@Input('bpFieldErrorSuppress') suppress?: boolean | '' | null = false;

	@Input('bpFieldErrorAnimate') animate = true;

	protected readonly _errors$ = new OptionalBehaviorSubject<ValidationErrors | null>();

	protected readonly _formControlName$ = new OptionalBehaviorSubject<number | string | null>();

	private readonly __formControl$ = new OptionalBehaviorSubject<AbstractControl | AbstractControlDirective | null>();

	private get __form(): UntypedFormGroup | undefined {
		return this.__formGroupDirective?.form;
	}

	constructor() {
		super();

		this.__emitErrorsOnFormControlErrorStatusChange();
	}

	ngOnChanges({ formControlOrName }: SimpleChanges<this>): void {
		if (formControlOrName && this.formControlOrName)
			this.__updateFormControlBasedOnInput();
	}

	ngAfterViewInit(): void {
		this.__formField && this.__updateFormControlBasedOnInjectedFormField();
	}

	private __emitErrorsOnFormControlErrorStatusChange(): void {
		this.__formControl$
			.pipe(
				switchMap(formControl => formControl?.statusChanges
					? (<AbstractControl>formControl).statusChanges.pipe(map(() => formControl.errors))
					: of(null)),
				distinctUntilChanged(isEqual),
				takeUntilDestroyed(this),
			)
			.subscribe(errors => void this._errors$.next(errors));
	}

	private __updateFormControlBasedOnInput(): void {
		if (this.formControlOrName instanceof AbstractControl) {
			this.__formControl$.next(this.formControlOrName);

			if (this.__form)
				this.__findControlNameAndPushToFormControlNameStream();

		} else {
			this.__formControl$.next(this.__form?.controls[this.formControlOrName!] ?? null);

			this._formControlName$.next(this.formControlOrName!);
		}

	}

	private __updateFormControlBasedOnInjectedFormField(): void {
		const ngControl = <ControlContainer> this.__formField!._control.ngControl;

		this.__formControl$.next(ngControl.control);

		if (!isString(this.formControlOrName))
			this._formControlName$.next(ngControl.name);
	}

	private __findControlNameAndPushToFormControlNameStream(): void {
		const [ formControlName ] = Object.entries(this.__form!.controls)
			.find(([ ,control ]) => control === this.formControlOrName) ?? [];

		if (formControlName)
			this._formControlName$.next(formControlName);
	}
}
