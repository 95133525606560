import { isNil, isUndefined } from 'lodash-es';

import type { TextMask } from './text-mask.config';
import { TextMaskConfig } from './text-mask.config';

export abstract class MaskPipe {
	readonly prefix: string;

	readonly suffix: string;

	readonly prefixLength: number;

	readonly suffixLength: number;

	readonly caretTrap = '[]';

	formatChars: string[] = [];

	get mask() {
		return this.config.mask;
	}

	get prefixRegExp() {
		return this.config.prefixRegExp;
	}

	get suffixRegExp() {
		return this.config.suffixRegExp;
	}

	constructor(public config = new TextMaskConfig()) {
		this.prefix = isNil(config.prefix) ? '' : config.prefix;

		this.suffix = isNil(config.suffix) ? '' : config.suffix;

		this.prefixLength = this.prefix.length;

		this.suffixLength = this.suffix.length;
	}

	transform(rawValue: string): TextMask | undefined {
		const bodyMask = this._transformBody(isNil(rawValue) ? '' : rawValue);

		return bodyMask && this._tryAddPrefixAndSuffix(bodyMask)
			.filter(char => !isUndefined(char));
	}

	protected abstract _transformBody(rawValue: string): TextMask | undefined;

	/**
	 * Method is getting rid of prefix and suffix chars from input value
	 */
	protected _removePrefixAndSuffix(rawValue: string) {
		const refinedRawValue = this.prefixRegExp?.test(rawValue)
			? rawValue.slice(Math.max(0, this.prefixLength))
			: rawValue;

		return this.suffixRegExp?.test(refinedRawValue)
			? refinedRawValue.slice(0, Math.max(0, refinedRawValue.length - this.suffixLength))
			: refinedRawValue;
	}

	protected _tryAddPrefixAndSuffix(mask: TextMask): TextMask {
		let fixedMask = mask;

		if (this.prefixLength > 0) {
			fixedMask = [
				...this.prefix,
				...fixedMask,
			];
		}

		if (this.suffixLength > 0) {
			fixedMask = [
				...fixedMask,
				...this.suffix,
			];
		}

		return fixedMask;
	}
}
