import moment, { Moment } from 'moment';
import { Observable, of, timer } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export function isMomentReached$(targetMoment: Moment): Observable<boolean> {
	if (targetMoment.isSameOrBefore(moment()))
		return of(true);

	return timer(targetMoment.toDate())
		.pipe(

			map(() => true),
			startWith(false),
		);
}
