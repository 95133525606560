import type { IndividualConfig } from 'ngx-toastr';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone } from '@angular/core';

import { FADE, SEMI_FAST, TIMINGS } from '@bp/frontend/animations';

export interface IToastButton {

	text: string;

	href?: string | null;

	routerLink?: any[] | null;

	onClick?: (() => void) | null;

}

export type ToastConfig = IndividualConfig & { undoBtn?: boolean; button?: IToastButton | null };

export const TOAST_ANIMATION_CONFIG = SEMI_FAST;

@Component({
	selector: 'bp-toast',
	templateUrl: './toast.component.html',
	styleUrls: [ './toast.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'[class.has-close-btn]': 'options.closeButton',
	},
	// eslint-disable-next-line @angular-eslint/component-max-inline-declarations
	animations: [
		FADE,
		trigger('flyInOut', [
			state('inactive', style({ opacity: 0 })),
			state('active', style({ opacity: 1 })),
			state('removed', style({ opacity: 0 })),
			transition(
				'inactive => active',
				animate(TIMINGS, keyframes([
					style({
						opacity: 0,
						transform: 'translateX(100%)',
					}),
					style({
						transform: 'translateX(0)',
						opacity: 1,
					}),
				])),
				TOAST_ANIMATION_CONFIG,
			),
			transition(
				'active => removed',
				animate(
					TIMINGS,
					keyframes([
						style({
							opacity: 1,
						}),
						style({
							transform: 'translateX(100%)',
							opacity: 0,
						}),
					]),
				),
				TOAST_ANIMATION_CONFIG,
			),
		]),
	],
})
export class ToastComponent extends Toast {

	override options!: ToastConfig;

	hasUndid = false;

	constructor(
		public toasterPackage: ToastPackage,
		protected _toasterService: ToastrService,
		private readonly _cdr: ChangeDetectorRef,
		zone: NgZone,
	) {
		super(_toasterService, toasterPackage, zone);
	}

	override updateProgress() {
		super.updateProgress();

		this._cdr.detectChanges();
	}

	undo(event: Event) {
		event.stopPropagation();

		this.hasUndid = true;

		this.toastPackage.triggerAction();

		return false;
	}
}
