import type { MonoTypeOperatorFunction, Observable, Operator, SchedulerLike, Subscription, TeardownLogic } from 'rxjs';
import { asyncScheduler, Subscriber } from 'rxjs';

/**
 * Emits first value straight away and debounces each next emitted value during the due time after the first value was emitted
 */
export function debounceTimeAfterFirst<T>(
	dueTime: number,
	scheduler: SchedulerLike = asyncScheduler,
): MonoTypeOperatorFunction<T> {
	return debounceTimeAfter(1, dueTime, scheduler);
}

export function debounceTimeAfter<T>(
	nonDebouncingEmitsAmount: number,
	dueTime: number,
	scheduler: SchedulerLike = asyncScheduler,
): MonoTypeOperatorFunction<T> {
	return (source$: Observable<T>) => source$.lift(
		new DebounceTimeAfterOperator(nonDebouncingEmitsAmount, dueTime, scheduler),
	);
}

// Based on https://github.com/ReactiveX/rxjs/blob/78032157f5c1655436829017bbda787565b48c30/src/internal/operators/debounceTime.ts#L64
class DebounceTimeAfterOperator<T> implements Operator<T, T> {
	constructor(
		private readonly _nonDebouncingEmitsAmount: number,
		private readonly _dueTime: number,
		private readonly _scheduler: SchedulerLike,
	) { }

	call(subscriber: Subscriber<T>, source$: Observable<T>): TeardownLogic {
		return source$.subscribe(new DebounceTimeAfterSubscriber(
			this._nonDebouncingEmitsAmount,
			this._dueTime,
			this._scheduler,
			subscriber,
		));
	}
}

class DebounceTimeAfterSubscriber<T> extends Subscriber<T> {

	private _debouncedSubscription: Subscription | null = null;

	private _resetEmissionsCounterSubscription: Subscription | null = null;

	private _lastValue: T | null = null;

	private _hasValue = false;

	private _emissionsCounter = 0;

	private get _shouldDebounce(): boolean {
		return this._emissionsCounter > this._nonDebouncingEmitsAmount;
	}

	constructor(
		private readonly _nonDebouncingEmitsAmount: number,
		private readonly _dueTime: number,
		private readonly _scheduler: SchedulerLike,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		protected override destination: Subscriber<T | null>,
	) {
		super(destination);
	}

	protected override _next(value: T): void {
		this._clearDebounceSubscription();

		this._emissionsCounter++;

		this._lastValue = value;

		this._hasValue = true;

		this._resetEmissionsCounterSubscription?.unsubscribe();

		this._resetEmissionsCounterSubscription = this._scheduler.schedule(s => void s.resetEmissionsCounter(), this._dueTime, this);

		if (this._shouldDebounce) {
			this.add(this._debouncedSubscription = this._scheduler.schedule(
				s => void s.debouncedNext(),
				this._dueTime,
				this,
			));
		} else
			this.debouncedNext();
	}

	protected override _complete(): void {
		this.debouncedNext();

		this.destination.complete();
	}

	debouncedNext(): void {
		this._clearDebounceSubscription();

		if (!this._hasValue)
			return;

		const { _lastValue: lastValue } = this;

		/*
		 * This must be done *before* passing the value
		 * along to the destination because it's possible for
		 * the value to synchronously re-enter this operator
		 * recursively when scheduled with things like
		 * VirtualScheduler/TestScheduler.
		 */
		this._lastValue = null;

		this._hasValue = false;

		// eslint-disable-next-line unicorn/consistent-destructuring
		this.destination.next(lastValue);
	}

	resetEmissionsCounter(): void {
		this._clearResetEmissionsCounterSubscription();

		this._emissionsCounter = 0;
	}

	private _clearResetEmissionsCounterSubscription(): void {
		this._resetEmissionsCounterSubscription?.unsubscribe();

		this._resetEmissionsCounterSubscription = null;
	}

	private _clearDebounceSubscription(): void {
		const debouncedSubscription = this._debouncedSubscription;

		if (debouncedSubscription === null)
			return;

		this.remove(debouncedSubscription);

		debouncedSubscription.unsubscribe();

		this._debouncedSubscription = null;

	}

}
