<!--
	For some reason if the cmpt is in another animated component,
	it will duplicate the error if animation trigger is present, event if [@.disabled] is blocking it.
	So separate these cases completely.
-->

<ng-container *ngIf="animate; else noAnimateTpl">
	<div *ngIf="errorText$ | async as errorText" @slide>{{ errorText }}</div>
</ng-container>

<ng-template #noAnimateTpl>
	<div *ngIf="errorText$ | async as errorText">{{ errorText }}</div>
</ng-template>
