import { query, stagger, style, transition, trigger, useAnimation } from '@angular/animations';

import { fadeIn } from './fade';
import { NORMAL } from './variables';

export const FADE_IN_STAGGERING_CHILDREN = trigger('fadeInStaggeringChildren', [
	transition('* => *', [ // Each time the binding value changes
		query(
			'*',
			[
				style({ opacity: 0 }),
				stagger(10, useAnimation(fadeIn, NORMAL)),
			],
			{ optional: true },
		),
	]),
]);
