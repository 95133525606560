import type { AfterViewInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import type { DateRangeInputValue } from '@bp/shared/models/core';
import { DateRange, DateRangeShortcut } from '@bp/shared/models/core';
import { bpQueueMicrotask } from '@bp/shared/utilities/core';

import { ControlComponent } from '@bp/frontend/components/core';

@Component({
	selector: 'bp-date-range-shortcuts[dateRangeShortcuts]',
	templateUrl: './date-range-shortcuts.component.html',
	styleUrls: [ './date-range-shortcuts.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DateRangeShortcutsComponent,
			multi: true,
		},
	],
})
export class DateRangeShortcutsComponent extends ControlComponent<DateRange | null> implements AfterViewInit {

	@Input() mode: 'buttons-group' | 'interactive-links' | 'select' = 'interactive-links';

	@Input() panelClass!: string;

	@Input() defaultShortcut: DateRangeShortcut | null = DateRangeShortcut.thisMonth;

	@Input() dateRangeShortcuts!: DateRangeShortcut[];

	@HostBinding('class.interactive-links')
	get isInteractiveLinks() {
		return this.mode === 'interactive-links';
	}

	selected!: DateRangeShortcut | null;

	writtenValue!: DateRangeInputValue | null;

	ngAfterViewInit() {

		/**
		 * In case on init no one is written a value we set the default one
		 */
		setTimeout(() => !this.writtenValue && this.defaultShortcut && void this.select(this.defaultShortcut));
	}

	// #region Implementation of the ControlValueAccessor interface
	override writeValue(value: DateRangeInputValue | null): void {
		bpQueueMicrotask(() => {
			this.writtenValue = value;
			const inputDateRage = value ? DateRange.parse(value) : null;

			let shortcut = !inputDateRage || inputDateRage.empty ? this.defaultShortcut : null;

			if (inputDateRage?.fullRange)
				shortcut = this.dateRangeShortcuts.find(v => v.dateRange.isSame(inputDateRage)) || null;

			this.selected = shortcut;

			this.setValue(shortcut?.dateRange ?? null, { emitChange: false });
		});
	}
	// #endregion Implementation of the ControlValueAccessor interface

	select(value: DateRangeShortcut | null) {
		if (value === this.selected)
			return;

		this.selected = value;

		this.setValue(value?.dateRange ?? null);
	}

}
