import { Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { bypassAuthorizationHeaderCheck } from './http-request.interceptor.service';

@Injectable()
export class BypassAuthorizationHeaderCheckInterceptorService implements HttpInterceptor {

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request.clone({
			context: bypassAuthorizationHeaderCheck(request.context),
		}));
	}

}
