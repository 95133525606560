import { animationFrameScheduler, timer } from 'rxjs';
import { first, map, share, startWith } from 'rxjs/operators';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import type { OnInit } from '@angular/core';
import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { attrBoolValue, bpQueueMicrotask } from '@bp/shared/utilities/core';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { ZoneService, fromMutation } from '@bp/frontend/rxjs';

/**
 * Rendering a significant amount of complex components in one event loop can create visible freezes of the UI
 * so this directive batches components to be rendered between event loops, so each one of them will contain
 * a manageable amount of work
 */
@Directive({ selector: '[bpDelayedRender]' })
export class DelayedRenderStructuralDirective extends Destroyable implements OnInit {

	private static readonly _bodyMutations$ = fromMutation(document.body, { subtree: true, childList: true })
		.pipe(share());

	private static readonly _maxInstantRenderedViews = 0;

	private static _instantViewsRenderingCounter = 0;

	private _delay = 0;

	@Input()
	get bpDelayedRender(): number | string {
		return this._delay;
	}

	set bpDelayedRender(value: number | string) {
		this._delay = coerceNumberProperty(value, 0);

		this._isAllStagger = !!this._delay;
	}

	private _isAllStagger!: boolean;

	@Input()
	get bpDelayedRenderStagger(): number | string {
		return this._stagger;
	}

	set bpDelayedRenderStagger(value: number | string) {
		this._stagger = coerceNumberProperty(value, 5);
	}

	private _stagger = 5;

	@Input()
	get bpDelayedRenderAllStagger(): boolean | '' {
		return this._isAllStagger;
	}

	set bpDelayedRenderAllStagger(value: boolean | '') {
		this._isAllStagger = attrBoolValue(value);
	}

	private get _$host(): Comment {
		return <Comment> this._hostRef.nativeElement;
	}

	private get _maxInstantRenderedViews(): number {
		return this._isAllStagger
			? 0
			: DelayedRenderStructuralDirective._maxInstantRenderedViews;
	}

	constructor(
		private readonly _hostRef: ElementRef,
		private readonly _viewContainerRef: ViewContainerRef,
		private readonly _tplRef: TemplateRef<any>,
	) {
		super();
	}

	ngOnInit(): void {
		this._waitTillParentElementAttachedToDom$()
			.subscribe(() => void this._scheduleCmptRendering());
	}

	private _waitTillParentElementAttachedToDom$() {
		return DelayedRenderStructuralDirective._bodyMutations$.pipe(
			map(() => this._$host.parentElement?.isConnected),
			startWith(this._$host.parentElement?.isConnected),
			first(v => !!v),
			takeUntilDestroyed(this),
		);
	}

	private _scheduleCmptRendering(): void {
		if (!this._isAllStagger && DelayedRenderStructuralDirective._instantViewsRenderingCounter <= this._maxInstantRenderedViews)
			this._renderCmpt(); // To render in the current event loop a set number of views
		else {
			timer(this._calcNextRenderDueTime(), animationFrameScheduler)
				.pipe(takeUntilDestroyed(this))
				.subscribe(() => void this._renderCmpt());
		}

		DelayedRenderStructuralDirective._instantViewsRenderingCounter++;

		bpQueueMicrotask(() => (DelayedRenderStructuralDirective._instantViewsRenderingCounter = 0));
	}

	private _renderCmpt(): void {
		ZoneService.runInAngularZone(() => void this._viewContainerRef
			.createEmbeddedView(this._tplRef)
			.detectChanges());
	}

	private _calcNextRenderDueTime(): number {
		return this._delay
			+ (DelayedRenderStructuralDirective._instantViewsRenderingCounter - this._maxInstantRenderedViews)
			* this._stagger;
	}
}
