import { AsyncSubject, BehaviorSubject } from 'rxjs';

import { Enumeration } from '@bp/shared/models/core/enum';

import { AsyncVoidSubject } from '@bp/frontend/rxjs';
import { BpError } from '@bp/frontend/models/core';

export class ProcessingFileStatus extends Enumeration {

	static inProgress = new ProcessingFileStatus();

	static finish = new ProcessingFileStatus();

	static canceled = new ProcessingFileStatus();

	static error = new ProcessingFileStatus();

}

export class ProcessingFile {

	private readonly _startProgressValue = 25; // Imitate TTFB

	isDownload = this._type === 'download';

	isUpload = this._type === 'upload';

	private readonly _progress$ = new BehaviorSubject(this._startProgressValue);

	progress$ = this._progress$.asObservable();

	get progress() {
		return this._progress$.value;
	}

	set progress(value: number) {
		value > this._startProgressValue && this._progress$.next(value);
	}

	private readonly _status$ = new BehaviorSubject<ProcessingFileStatus>(ProcessingFileStatus.inProgress);

	status$ = this._status$.asObservable();

	get status() {
		return this._status$.value;
	}

	get isInProgress() {
		return this.status === ProcessingFileStatus.inProgress;
	}

	get isFinished() {
		return this.status === ProcessingFileStatus.finish;
	}

	get isCanceled() {
		return this.status === ProcessingFileStatus.canceled;
	}

	get hasError() {
		return this.status === ProcessingFileStatus.error;
	}

	private readonly _error$ = new AsyncSubject<BpError>();

	error$ = this._error$.asObservable();

	private readonly _cancel$ = new AsyncVoidSubject();

	cancel$ = this._cancel$.asObservable();

	constructor(
		public name: string,
		private readonly _type: 'download' | 'upload',
	) {
		this.name = name.replace(/\//ug, ' ̸ ');
	}

	finish() {
		if (this.progress < 100)
			this.progress = 100;

		this._status$.next(ProcessingFileStatus.finish);
	}

	error(value: BpError) {
		this._error$.next(value);

		this._error$.complete();

		this._status$.next(ProcessingFileStatus.error);
	}

	cancel() {
		if (this.isInProgress) {
			this._cancel$.complete();

			this._status$.next(ProcessingFileStatus.canceled);
		}
	}
}
