import { PrimitiveConstructable } from '@bp/shared/models/core';
import { NonFunctionProperties } from '@bp/shared/typings';

export class State extends PrimitiveConstructable {

	readonly code!: string;

	readonly name!: string;

	readonly countryCode!: string;

	constructor(dto: NonFunctionProperties<State>) {
		super(`${ dto.countryCode }_${ dto.code }`);

		if (this._isCached())
			return this;

		Object.assign(this, dto);

		this._cacheAndFreezeInstance();
	}

	protected _getScope(): string {
		return 'state';
	}

	override toString(): string {
		return this.code;
	}

	override valueOf(): string {
		return this.code;
	}

	override toJSON(): string {
		return this.code;
	}
}
