import { isObject, isString, startCase } from 'lodash-es';
import moment from 'moment';

import { PrimitiveConstructable } from '@bp/shared/models/core';

export class Timezone extends PrimitiveConstructable {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	static UTC = new Timezone({
		name: 'Etc/UTC',
		displayName: 'UTC',
		utcOffset: '+00:00',
	});

	readonly name!: string;

	readonly displayName!: string;

	readonly utcOffset!: string;

	constructor(dtoOrZoneName: string | { name: string; utcOffset: string; displayName?: string }) {
		super(isString(dtoOrZoneName) ? dtoOrZoneName : dtoOrZoneName.name);

		if (this._isCached())
			return this;

		if (isObject(dtoOrZoneName)) {
			this.name = dtoOrZoneName.name;

			this.utcOffset = dtoOrZoneName.utcOffset!;

			this.displayName = dtoOrZoneName.displayName ?? this._buildDisplayName();
		} else {
			this.name = dtoOrZoneName;

			this.utcOffset = moment.tz(dtoOrZoneName).format('Z');

			this.displayName = this._buildDisplayName();
		}

		this._cacheAndFreezeInstance();
	}

	protected _getScope(): string {
		return 'timezone';
	}

	private _buildDisplayName(): string {
		const name = this.name
			.split('/')
			.map(segment => startCase(segment))
			.join(' / ');

		return `(UTC${ this.utcOffset }) ${ name }`;
	}

}
