import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'bp-chevron-rotatable-180-degrees',
	templateUrl: './chevron-rotatable-180-degrees.component.html',
	styleUrls: [ './chevron-rotatable-180-degrees.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChevronRotatable180DegreesComponent {

	@Input()
	@HostBinding('class.rotated')
	rotated!: boolean | null;

}
