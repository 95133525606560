<h2 mat-dialog-title>{{ _actionConfirmDialogData.title }}</h2>

<mat-dialog-content>
	<p *ngIf="_actionConfirmDialogData.descriptionComponent">
		<ng-container
			*ngComponentOutlet="_actionConfirmDialogData.descriptionComponent; injector: _descriptionComponentInjector"
		/>
	</p>

	<p *ngIf="_actionConfirmDialogData.descriptionText" [innerHtml]="_actionConfirmDialogData.descriptionText"></p>

	<ng-container *ngIf="_actionConfirmDialogData.requireTypedConfirm">
		<p class="mb-0">
			Please type
			<b class="pre-wrap">{{ _actionConfirmDialogData.typedConfirmText ?? _actionConfirmDialogData.name }}</b> to
			confirm.
		</p>

		<bp-input
			class="w-100 mt-3"
			[label]="
				_actionConfirmDialogData.typedConfirmText ? '' : (_actionConfirmDialogData.type | titleCase) + ' Name'
			"
			[color]="_actionConfirmDialogData.color"
			[required]="true"
			[nativeAutocomplete]="false"
			[(ngModel)]="_typedName"
		/>
	</ng-container>
</mat-dialog-content>

<mat-dialog-actions>
	<button
		*ngIf="_isConfirmKind"
		mat-stroked-button
		class="cancel mr-auto bp-round-button"
		type="button"
		[mat-dialog-close]="false"
	>
		Cancel
	</button>

	<button
		mat-stroked-button
		class="bp-round-button"
		type="button"
		[color]="_actionConfirmDialogData.color"
		[disabled]="!_canCloseWithTrueOnEnter()"
		[mat-dialog-close]="true"
	>
		{{ _isConfirmKind ? _actionConfirmDialogData.confirmButtonText : 'Acknowledge' }}
	</button>
</mat-dialog-actions>
