import { JsonNamingStrategy } from '@bp/shared/models/common';
import { DTO, MapFromDTO, MetadataEntity } from '@bp/shared/models/metadata';

export class HttpConfig extends MetadataEntity {

	@MapFromDTO()
	bodyNamingStrategy?: JsonNamingStrategy | `${ JsonNamingStrategy }`;

	constructor(dto?: DTO<HttpConfig>) {
		super(dto);
	}

}
