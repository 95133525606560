import type { Moment } from 'moment';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendar, MatCalendarHeader, MatDatepicker, MatDatepickerContent, MatDatepickerIntl } from '@angular/material/datepicker';

@Component({
	selector: 'bp-datepicker-calendar-header',
	templateUrl: './datepicker-calendar-header.component.html',
	styleUrls: [ './datepicker-calendar-header.component.scss' ],
	exportAs: 'matCalendarHeader',
	// eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerCalendarHeaderComponent extends MatCalendarHeader<Moment> {

	private readonly dateAdapter: DateAdapter<Moment>;

	constructor(
		@Inject(MatDatepicker) public picker: MatDatepicker<Moment>,
		@Inject(MatDatepickerContent) public pickerContent: MatDatepickerContent<Moment>,
		intl: MatDatepickerIntl,
		@Inject(MatCalendar) calendar: MatCalendar<Moment>,
		@Optional() dateAdapter: DateAdapter<Moment>,
		// TODO: Check on the upcoming versions of material the bug with exporting of the type
		@Optional() @Inject(MAT_DATE_FORMATS) dateFormats: any,
		changeDetectorReference: ChangeDetectorRef,
	) {
		super(intl, calendar, dateAdapter, dateFormats, changeDetectorReference);

		this.dateAdapter = dateAdapter;

		/*
		 * Dirty workaround of the mat pickers inability to set a panel class on
		 * the overlay
		 * this.picker._popupRef.addPanelClass(this.picker.panelClass);
		 */
	}

	clear() {
		this.picker.select(null!);

		this.picker.close();
	}

	moveToToday(): void {
		this.calendar.activeDate = this.dateAdapter.today();

		this.calendar.focusActiveCell();
	}
}
