import { upperFirst } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'upperFirst',
})
export class UpperFirstPipe implements PipeTransform {
	transform(value: string): string {
		return upperFirst(value);
	}
}
