/* eslint-disable complexity */
import { escapeRegExp, isNumber } from 'lodash-es';

import { MaskPipe } from './mask-pipe';
import type { TextMask } from './text-mask.config';
import { NumberMaskConfig } from './text-mask.config';

const NON_DIGITS_REGEXP = /\D+/ug;
const DIGIT_REGEXP = /\d/u;

export class NumberMaskPipe extends MaskPipe {

	get thousandsSeparatorSymbolLength() {
		return this.config.thousandsSeparatorSymbol.length;
	}

	readonly decimalSymbolRegExp = new RegExp(escapeRegExp(this.config.decimalSeparatorSymbol), 'u');

	readonly digitRegExp = DIGIT_REGEXP;

	constructor(public override config = new NumberMaskConfig()) {
		super(config);
	}

	protected _transformBody(rawValue: string): TextMask {
		if (rawValue === '' || (rawValue.startsWith(this.prefix[0]) && rawValue.length === 1))
			return [ DIGIT_REGEXP ];

		if (rawValue === this.config.decimalSeparatorSymbol && this.config.allowDecimal)
			return [ '0', this.decimalSymbolRegExp, DIGIT_REGEXP ];

		let integer: string;
		let fraction!: TextMask | string;
		let mask: TextMask;
		const refinedValue = integer = this._removePrefixAndSuffix(rawValue);

		const indexOfLastDecimal = refinedValue.lastIndexOf(this.config.decimalSeparatorSymbol);
		const hasDecimal = indexOfLastDecimal !== -1;
		const isNegative = (refinedValue.startsWith('-')) && this.config.allowNegative;

		if (hasDecimal && (this.config.allowDecimal || this.config.requireDecimal)) {
			integer = refinedValue.slice(0, indexOfLastDecimal);

			fraction = refinedValue.slice(indexOfLastDecimal + 1);

			fraction = this._convertToMask(fraction.replace(NON_DIGITS_REGEXP, ''));
		}

		if (isNumber(this.config.integerLimit)) {
			const thousandsSeparatorRegex = this.config.thousandsSeparatorSymbol === '.' ? '[.]' : `${ this.config.thousandsSeparatorSymbol }`;
			const numberOfThousandSeparators = (integer.match(new RegExp(thousandsSeparatorRegex, 'ug')) || []).length;

			integer = integer.slice(
				0,
				this.config.integerLimit + (numberOfThousandSeparators * this.thousandsSeparatorSymbolLength),
			);
		}

		integer = integer.replace(NON_DIGITS_REGEXP, '');

		if (!this.config.allowLeadingZeroes)
			integer = integer.replace(/^0+(0$|[^0])/u, '$1');

		integer = this.config.includeThousandsSeparator
			? this._addThousandsSeparator(integer)
			: integer;

		mask = integer ? this._convertToMask(integer) : [ DIGIT_REGEXP ];

		if ((hasDecimal && this.config.allowDecimal) || this.config.requireDecimal) {
			if (refinedValue[indexOfLastDecimal - 1] !== this.config.decimalSeparatorSymbol)
				mask.push(this.caretTrap);

			mask.push(this.decimalSymbolRegExp, this.caretTrap);

			if (fraction) {
				if (isNumber(this.config.decimalLimit))
					fraction = fraction.slice(0, this.config.decimalLimit);

				mask = [ ...mask, ...(Array.isArray(fraction) ? fraction : [ fraction ]) ];
			}

			if (this.config.requireDecimal
				&& refinedValue[indexOfLastDecimal - 1] === this.config.decimalSeparatorSymbol
			)
				mask.push(DIGIT_REGEXP);
		}

		if (isNegative)
			// If user is entering a negative number, add a mask placeholder spot to attract the caret to it.
			mask = [ /-/u, ...mask ];

		return mask;
	}

	private _convertToMask(text: string) {
		return [ ...text ]
			.map(char => DIGIT_REGEXP.test(char) ? DIGIT_REGEXP : char);
	}

	// http://stackoverflow.com/a/10899795/604296
	private _addThousandsSeparator(n: { replace: (argument0: RegExp, argument1: string) => string }) {
		return n.replace(/\B(?=(\d{3})+(?!\d))/ug, this.config.thousandsSeparatorSymbol);
	}
}
