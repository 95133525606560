<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<svg version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<symbol id="transaction-status-arrow-icon" viewBox="0 0 15 15" style="fill: var(--arrow-color, #0f213c)">
			<mask id="a" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="4" y="3" width="7" height="8">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M4.15 10.7V3.65h6.55v7.05H4.15Z" fill="#fff" />
			</mask>
			<g mask="url(#a)">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="m7.2 3.8-3.03 6.54c-.04.1-.02.23.07.3.1.07.22.08.31 0L7.42 8.5l2.87 2.16a.25.25 0 0 0 .38-.31L7.65 3.8a.25.25 0 0 0-.46 0Z"
				/>
			</g>
		</symbol>

		<symbol id="transaction-status-declined-icon" viewBox="0 0 15 15">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M4.2 7.3h6.44H4.2Z" fill="#fff" />
			<path d="M4.2 7.3h6.44" stroke="#fff" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
		</symbol>
	</defs>
</svg>
