/* eslint-disable max-classes-per-file */
import { isEmpty } from 'lodash-es';

import { ChangeDetectionStrategy, Component, ContentChild, Directive, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

import { NumberMaskConfig, TextMaskConfig, TextMaskConfigInput } from '@bp/shared/features/text-mask';
import { hasMaskSourceChar, replaceMaskSourceChars } from '@bp/shared/utilities/core';
import { InputBasedControlOptions } from '@bp/shared/models/metadata';

import { TextMaskDirective } from '@bp/frontend/features/text-mask';
import { FormFieldControlComponent } from '@bp/frontend/components/core';

/**
 * Allows the user to customize the label.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'bp-input-label',
})
export class InputLabelDirective { }

/**
 * Allows the user to customize the hint.
 */
@Directive({
	selector: 'bp-input-hint, [bpInputHint]',
})
export class InputHintDirective { }

/**
 * Allows the user to add prefix.
 */
@Directive({
	selector: 'bp-input-prefix, [bpInputPrefix]',
})
export class InputPrefixDirective { }

@Directive({
	selector: 'bp-input-suffix, [bpInputSuffix]',
})
export class InputSuffixDirective { }

@Component({
	selector: 'bp-input',
	templateUrl: './input.component.html',
	styleUrls: [ './input.component.scss' ],
	host: {
		'(focusout)': 'onTouched()',
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: InputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: InputComponent,
			multi: true,
		},
	],
})
export class InputComponent<TInputValue extends (number | string | null) = number | string | null> extends FormFieldControlComponent<TInputValue> implements OnInit {

	static numberMask = new NumberMaskConfig({
		placeholderChar: TextMaskConfig.whitespace,
		allowDecimal: true,
		decimalLimit: 2,
		guide: false,
		maskOnFocus: true,
	});

	@Input() textarea?: boolean;

	@Input() number?: boolean;

	@Input() type?: string;

	/**
	 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-inputmode
	 */
	@Input() inputmode: InputBasedControlOptions['inputmode'] = 'text';

	@Input() mask?: TextMaskConfigInput | null;

	@Input() autocomplete?: MatAutocomplete;

	@Input() hasSearchIcon?: boolean;

	@Input() maxLength?: number;

	@Input() isSecret = false;

	@Input()
	get autoGrow(): boolean {
		return this.__autoGrow;
	}

	set autoGrow(value: BooleanInput) {
		this.__autoGrow = coerceBooleanProperty(value);
	}

	private __autoGrow = false;

	@ViewChild(MatAutocompleteTrigger) autocompleteTrigger?: MatAutocompleteTrigger;

	@ViewChild(MatInput) matInput!: MatInput;

	@ViewChild(MatInput, { read: ElementRef }) inputRef!: ElementRef<HTMLInputElement>;

	@ViewChild(TextMaskDirective) maskDirective?: TextMaskDirective;

	/** User-supplied override of the label element. */
	@ContentChild(InputLabelDirective) customLabel?: InputLabelDirective;

	@ContentChild(InputHintDirective) customHint?: InputHintDirective;

	@ContentChild(InputPrefixDirective) prefix?: InputPrefixDirective;

	@ContentChild(InputSuffixDirective) suffix?: InputSuffixDirective;

	get $$mask(): TextMaskConfigInput | null | undefined {
		return this.number ? (this.mask ?? InputComponent.numberMask) : this.mask;
	}

	get $$inputType(): string {
		return this.nativeAutocomplete
			? this.type ?? 'text'
			: 'search';
	}

	get $input(): HTMLInputElement {
		return this.inputRef.nativeElement;
	}

	get shouldAddSecretAttribute(): true | null {
		return this.isSecret ? true : null;
	}

	get isIncomingSecretValueUsedAsPlaceholder(): boolean {
		return this.isSecret && !!this.$$incomingSecretValue && this._internalControl.pristine;
	}

	$$incomingSecretValue: string | null = null;

	override ngOnInit(): void {
		super.ngOnInit();

		setTimeout(() => void this.__setValueFromBrowserAutofill());
	}

	override writeValue(value: TInputValue): void {
		if (this.isSecret && (value !== null) && hasMaskSourceChar(value.toString())) {

			this.$$incomingSecretValue = this.__tryToReplaceMaskSourceChars(value);

			// to clear any old value in the internal control in case if the control had non secret value before
			this._setIncomingValue(null);

			this._setIncomingValueToInternalControl(null);
		} else
			super.writeValue(value);
	}

	focus(): void {
		this.matInput.focus();

		// at this point the mask may not be applied yet
		setTimeout(() => this.maskDirective?.onFocus());
	}

	onInputBlur(): void {
		this.__resetSecretLogicIfControlPristine();
	}

	protected override _setIncomingValueToInternalControl<U = TInputValue>(value: U | null): void {
		super._setIncomingValueToInternalControl(value);

		this.__whenAutoGrowOnSetDataValueAttribute(value);
	}

	/**
	 * If the autocomplete is present, the value of the internal control could
	 * be as string as an item of the autocomplete list which is any
	 */
	protected override _onInternalControlValueChange(value: any | string): void {
		this.__whenAutoGrowOnSetDataValueAttribute(value);

		this.setValue(this.__isControlValueTypeNumber()
			&& !isEmpty(value)
			? Number(value)
			: value);
	}

	private __tryToReplaceMaskSourceChars(value: number | string | null): string | null {
		return value === null ? null : replaceMaskSourceChars(value.toString());
	}

	private __resetSecretLogicIfControlPristine(): void {
		if (this.isSecret && this._internalControl.pristine)
			this._internalControl.markAsUntouched();
	}

	protected override _eraseInternalControlValue(): void {
		if (this.isIncomingSecretValueUsedAsPlaceholder)
			this.__eraseOnlyExternalControlValue();
		else
			super._eraseInternalControlValue();
	}

	private __eraseOnlyExternalControlValue(): void {
		this._internalControl.markAsDirty();

		this.setValue(null, { skipEqualCheck: true });
	}

	// Check if browser has inserted some value automatically
	// (e.g. on page restoration from history) and apply this value.
	private __setValueFromBrowserAutofill(): void {
		if (!this.$input.value)
			return;

		const currentInputValue = this.__isControlValueTypeNumber()
			? Number(this.$input.value)
			: this.$input.value;

		if (this._internalControl.value === currentInputValue)
			return;

		this.$input.dispatchEvent(new Event('input'));
	}

	private __whenAutoGrowOnSetDataValueAttribute<U = TInputValue>(value: U | null): void {
		if (!this.autoGrow || !(this.$input.parentNode instanceof HTMLElement))
			return;

		this.$input.parentNode.dataset['inputValue'] = `${ value ?? '' }`;
	}

	private __isControlValueTypeNumber(): boolean {
		return this.maskDirective?.config instanceof NumberMaskConfig
			&& !this.maskDirective.config.allowLeadingZeroes;
	}
}
