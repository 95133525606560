import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgSharedIconsDefinitionsComponent } from './svg-shared-icons-definitions';
import { SvgSharedFiltersDefinitionsComponent } from './svg-shared-filters-definitions';
import { SvgIconComponent } from './svg-icon';
import { SvgSharedTransactionStatusIconsDefinitionsComponent } from './svg-shared-transaction-status-icons-definitions';

const EXPOSED = [
	SvgIconComponent,
	SvgSharedFiltersDefinitionsComponent,
	SvgSharedIconsDefinitionsComponent,
	SvgSharedTransactionStatusIconsDefinitionsComponent,
];

@NgModule({
	imports: [ CommonModule ],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesSvgIconsModule { }
