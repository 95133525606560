import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Pipe({
	name: 'bpEnsureFormGroup',
})
export class EnsureFormGroupPipe implements PipeTransform {
	transform(value: unknown): value is UntypedFormGroup {
		return value instanceof UntypedFormGroup;
	}
}
