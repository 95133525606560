import { CommonModule } from '@angular/common';
import { OnInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Countries, Country, CountryCode } from '@bp/shared/models/countries';
import { attrBoolValue } from '@bp/shared/utilities/core';

import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';

@Component({
	selector: 'bp-country',
	standalone: true,
	imports: [
		CommonModule,

		SharedFeaturesSvgIconsModule,
		SharedFeaturesTooltipModule,
	],
	templateUrl: './country.component.html',
	styleUrls: [ './country.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryComponent implements OnInit {

	@Input()
	get src(): Country | CountryCode | object | string {
		return this._src;
	}

	set src(value: Country | CountryCode | object | string) {
		this._src = value;

		this.country = this._parseCountrySource();

		this.countryIsWorldwide = this.country === Countries.worldwide;
	}

	private _src!: Country | CountryCode | object | string;

	@Input() compact!: boolean | '';

	@Input() hideTooltip!: boolean | '';

	@Input() round!: boolean | '';

	@Input() worldwide!: boolean | '';

	country!: Country | null;

	countryIsWorldwide!: boolean;

	ngOnInit() {
		this.worldwide = attrBoolValue(this.worldwide);

		this.compact = attrBoolValue(this.compact);

		this.hideTooltip = attrBoolValue(this.hideTooltip);

		this.round = attrBoolValue(this.round);
	}

	private _parseCountrySource(): Country | null {
		return this.src instanceof Country
			? this.src
			: Countries.findByCodeString(<string> this.src.valueOf());
	}
}
