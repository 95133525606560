import { MonoTypeOperatorFunction, Observable, timer } from 'rxjs';
import { retry } from 'rxjs/operators';

import { BpError } from '@bp/frontend/models/core';

type RetryConfig = {
	maxAttempts?: number;
	scalingDelayDuration?: number;
	retryOn503Only?: boolean;
	retryOnNoResponseOnly?: boolean;
};

export function retryOnTimeoutOr5XXOrUnknownErrorWithScalingDelay<T>(retryConfig: RetryConfig = {}): MonoTypeOperatorFunction<T> {
	const maxRetryAttempts = retryConfig.maxAttempts ?? 5;
	const scalingDelayDuration = retryConfig.scalingDelayDuration ?? 1000;

	return (source$: Observable<T>) => source$.pipe(
		retry({
			count: maxRetryAttempts,
			// Should be false, as response interceptor processes all events, thus resetting it every time
			resetOnSuccess: false,
			delay(error: BpError, retryCount: number) {
				const isRetriableError = retryConfig.retryOnNoResponseOnly
					? error.isNoResponse
					: (retryConfig.retryOn503Only
						? error.is503
						: error.isTimeout || error.isNoResponse || error.is5XX);

				if (isRetriableError)
					return timer(retryCount * scalingDelayDuration);

				throw error;
			},
		}),
	);
}
