import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { Type } from '@angular/core';

import { isConstructor } from '@bp/shared/utilities/core';

export function mapTo<TMappedType, TMappingType>(
	constructorOrFactoryFunc: Type<TMappedType> | ((value: TMappingType) => TMappedType),
): OperatorFunction<TMappingType, TMappedType> {
	const factoryFunc = (value: TMappingType): TMappedType => isConstructor(constructorOrFactoryFunc)
		? new constructorOrFactoryFunc(value)
		: constructorOrFactoryFunc(value);

	return (source$: Observable<TMappingType>) => source$.pipe(
		map(sourceValue => factoryFunc(sourceValue)),
	);
}

export function mapToArray<TMappedType, TMappingType>(
	constructorOrFactoryFunc: Type<TMappedType> | ((value: TMappingType) => TMappedType),
): OperatorFunction<TMappingType[], TMappedType[]> {
	const factoryFunc = (value: TMappingType): TMappedType => isConstructor(constructorOrFactoryFunc)
		? new constructorOrFactoryFunc(value)
		: constructorOrFactoryFunc(value);

	return (source$: Observable<TMappingType[]>) => source$.pipe(
		map(sourceValue => sourceValue.map(item => factoryFunc(item))),
	);
}
