import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';

import { ShowOnDirtyErrorStateMatcher } from './show-on-dirty-error-state-matcher';
import { FieldErrorComponent } from './field-error';
import { ValidationErrorComponent } from './validation-error';

const EXPOSED_COMPONENTS = [
	FieldErrorComponent,
	ValidationErrorComponent,
];

@NgModule({
	imports: [ CommonModule ],
	exports: EXPOSED_COMPONENTS,
	declarations: EXPOSED_COMPONENTS,
})
export class SharedFeaturesValidationModule {

	static forRoot(): ModuleWithProviders<SharedFeaturesValidationModule> {
		return {
			ngModule: SharedFeaturesValidationModule,
			providers: [
				{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
			],
		};
	}

	// TODO: required until https://github.com/angular/components/issues/10084 is finished.
	static forLib(): ModuleWithProviders<SharedFeaturesValidationModule> {
		return SharedFeaturesValidationModule.forRoot();
	}

}
