import { isString } from 'lodash-es';

import { CryptoCurrencyCode, CryptoCurrency } from './crypto';
import { ICurrency } from './currency.interface';
import { FiatCurrencyCode, FiatCurrency } from './fiat';

export function currencyFactory(currencyCodeOrCurrency: ICurrency | string): ICurrency {
	if (isString(currencyCodeOrCurrency)) {
		try {
			return new FiatCurrency(<FiatCurrencyCode> currencyCodeOrCurrency);
		} catch {
			return new CryptoCurrency(<CryptoCurrencyCode> currencyCodeOrCurrency);
		}
	}

	return currencyCodeOrCurrency;
}

export function currenciesFactory(currencyCodesOrCurrencies: (ICurrency | string)[] = []): ICurrency[] {
	return currencyCodesOrCurrencies.map(currencyFactory);
}
