import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'bpValueOf',
})
export class ValueOfPipe implements PipeTransform {
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	transform(value: any): any {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
		return value?.valueOf();
	}
}
