import { isEmpty } from 'lodash-es';
import type { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isPresent } from '@bp/shared/utilities/core';

export function filterNonEmpty<T>(source$: Observable<T | null | undefined>): Observable<NonNullable<T>> {
	return <Observable<NonNullable<T>>> <unknown> source$.pipe(
		filter(sourceValue => isPresent(sourceValue) && !isEmpty(sourceValue)),
	);
}
