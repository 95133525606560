import { asyncInside } from './async-inside-angular.scheduler';
import { asyncOutside } from './async-outside-angular.scheduler';
import { inside } from './inside-angular.scheduler';
import { outside } from './outside-angular.scheduler';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BpScheduler = {

	asyncOutside,

	asyncInside,

	outside,

	inside,

};
