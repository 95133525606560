import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { isPresent } from '@bp/shared/utilities/core';

@Pipe({
	name: 'takePresent',
})
export class TakePresentPipe implements PipeTransform {
	transform<T>(array?: (T | null | undefined)[] | null): T[] {
		return array?.filter(isPresent) ?? [];
	}
}
