import { get, last } from 'lodash-es';

import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { attrBoolValue } from '@bp/shared/utilities/core';

import { MediaBreakpointDevice, MediaService } from '@bp/frontend/features/media';

@Component({
	selector: 'bp-picture',
	templateUrl: './picture.component.html',
	styleUrls: [ './picture.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PictureComponent implements OnInit {

	@Input() src!: string;

	@Input() alt?: string;

	@Input() responsive: boolean | '' = false;

	@Input() skipPhone: boolean | '' = false;

	@Input() skipTablet: boolean | '' = false;

	@Input() skipLaptop: boolean | '' = false;

	@Input() svg: boolean | '' = false;

	@Input() png: boolean | '' = false;

	@Input() tablet: number | string = MediaService.getBreakpointWidth(MediaBreakpointDevice.Tablet);

	@Input() laptop: number | string = MediaService.getBreakpointWidth(MediaBreakpointDevice.Laptop);

	@Input() eager: boolean | '' = false;

	sources: MediaSource[] = [];

	lastSource!: MediaSource;

	@HostBinding('class')
	imageName!: string;

	ngOnInit(): void {
		this.responsive = attrBoolValue(this.responsive);

		this.skipLaptop = attrBoolValue(this.skipLaptop);

		this.skipTablet = attrBoolValue(this.skipTablet);

		this.skipPhone = attrBoolValue(this.skipPhone);

		this.svg = attrBoolValue(this.svg);

		this.png = attrBoolValue(this.png);

		this.eager = attrBoolValue(this.eager);

		this.imageName = last(this.src.split('/'))!;

		this.sources = this.responsive
			? this._getBreakpointsName()
				.map(breakpointName => this._createMediaSource(breakpointName))
			: [ this._createMediaSource() ];
	}

	private _getBreakpointsName() {
		return [ MediaBreakpointDevice.Laptop, MediaBreakpointDevice.Tablet, MediaBreakpointDevice.Phone ]
			.filter(v => !(this.skipLaptop && v === MediaBreakpointDevice.Laptop))
			.filter(v => !(this.skipTablet && v === MediaBreakpointDevice.Tablet))
			.filter(v => !(this.skipPhone && v === MediaBreakpointDevice.Phone));
	}

	private _createMediaSource(breakpointName?: string): MediaSource {
		const suffix = breakpointName ? `-${ breakpointName }` : '';
		const imagePath = `${ this.src }${ suffix }`;

		return {
			breakpoint: breakpointName ? `(min-width: ${ Number.parseFloat(get(this, breakpointName)) || 0 }px)` : null,
			...(this.svg
				? {
					svg: `${ imagePath }.svg`,
				}
				: {
					...this.png ? {} : {
						webp: `${ imagePath }.webp`,
						webp2x: `${ imagePath }@2x.webp`,
					},
					png: `${ imagePath }.png`,
					png2x: `${ imagePath }@2x.png`,
				}
			),
		};
	}
}

type MediaSource = {
	svg?: string;
	webp?: string;
	webp2x?: string;
	png?: string;
	png2x?: string;
	breakpoint: string | null;
};
