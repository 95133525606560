import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DestroyRef, Directive, inject } from '@angular/core';

import { AsyncVoidSubject } from '@bp/frontend/rxjs';

export interface IDestroyable {

	destroyed$: Observable<void>;

}

export function takeUntilDestroyed<T>(destroyable: IDestroyable): MonoTypeOperatorFunction<T> {
	return (source$: Observable<T>) => source$
		.pipe(takeUntil(destroyable.destroyed$));
}

@Directive()
export abstract class Destroyable implements IDestroyable {

	private readonly __destroyed$ = new AsyncVoidSubject();

	destroyed$ = this.__destroyed$.asObservable();

	constructor() {
		this.__onDestroyEmitDestroyedEvent();
	}

	private __onDestroyEmitDestroyedEvent(): void {
		inject(DestroyRef).onDestroy(() => void this.__destroyed$.complete());
	}

}
