import { of, Observable, OperatorFunction } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Action as NgrxAction } from '@ngrx/store';

import { Action } from '@bp/shared/typings';

import { reportJsErrorIfAny } from '@bp/frontend/rxjs';
import { BpError } from '@bp/frontend/models/core';

import { ErrorActionPayload, ResultActionPayload } from '../ngrx-action-payloads';

export function apiResult<TResult>(
	success: Action<ResultActionPayload<TResult>>,
	failure: Action<ErrorActionPayload>,
): OperatorFunction<TResult | null, NgrxAction & (ErrorActionPayload | ResultActionPayload<TResult>)> {
	return (source$: Observable<TResult | null>) => source$.pipe(
		map(result => result
			? success({ result })
			: failure({ error: BpError.notFound })),
		reportJsErrorIfAny,
		catchError((error: unknown) => of(failure({ error: BpError.fromUnknown(error) }))),
	);
}

export function apiNullableResult<TResult>(
	success: Action<ResultActionPayload<TResult | null>>,
	failure: Action<ErrorActionPayload>,
): OperatorFunction<TResult | null, NgrxAction & (ErrorActionPayload | ResultActionPayload<TResult | null>)> {
	return (source$: Observable<TResult | null>) => source$.pipe(
		map(result => success({ result })),
		reportJsErrorIfAny,
		catchError((error: unknown) => of(failure({ error: BpError.fromUnknown(error) }))),
	);
}

export function apiResultWithRequest<TResult, TApiRequest>(
	{
		request,
		successAction,
		failureAction,
	}: {
		request: TApiRequest;
		successAction: Action<ResultActionPayload<TResult> & { request: TApiRequest }>;
		failureAction: Action<ErrorActionPayload & { request: TApiRequest }>;
	},
): OperatorFunction<TResult, NgrxAction & (ErrorActionPayload | object)> {
	return (source$: Observable<TResult>) => source$.pipe(
		map(result => result
			? successAction({ request, result })
			: failureAction({ request, error: BpError.notFound })),
		reportJsErrorIfAny,
		catchError((error: unknown) => of(failureAction({
			request,
			error: BpError.fromUnknown(error),
		}))),
	);
}

export function apiVoidResult<TStreamPayload>(
	success: Action,
	failure: Action<ErrorActionPayload>,
): OperatorFunction<TStreamPayload, NgrxAction & (ErrorActionPayload | object)> {
	return (source$: Observable<TStreamPayload>) => source$.pipe(
		map(success),
		reportJsErrorIfAny,
		catchError((error: unknown) => of(failure({ error: BpError.fromUnknown(error) }))),
	);
}

export function apiVoidResultWithRequest<TStreamPayload, TApiRequest>(
	{
		request,
		successAction,
		failureAction,
	}: {
		request: TApiRequest;
		successAction: Action<{ request: TApiRequest }>;
		failureAction: Action<ErrorActionPayload & { request: TApiRequest }>;
	},
): OperatorFunction<TStreamPayload, NgrxAction & (ErrorActionPayload | object)> {
	return (source$: Observable<TStreamPayload>) => source$.pipe(
		map(() => successAction({ request })),
		reportJsErrorIfAny,
		catchError((error: unknown) => of(failureAction({
			error: BpError.fromUnknown(error),
			request,
		}))),
	);
}
