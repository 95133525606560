import { some } from 'lodash-es';
import type { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isPresent } from '@bp/shared/utilities/core';
import type { NonNullableRecords } from '@bp/shared/typings';

export function filterIfAllRecordsPresent<T extends Record<string, unknown>>(source$: Observable<T>): Observable<NonNullableRecords<T>> {
	return <Observable<NonNullableRecords<T>>>source$.pipe(
		filter(sourceValue => !some(sourceValue, value => !isPresent(value))),
	);
}
