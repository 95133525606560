<div
	*ngIf="show"
	class="alert"
	@slide
	[class.alert-with-border]="withBorder"
	[ngClass]="'alert-' + type"
	(@slide.done)="onSlideAnimationEnd()"
>
	<button *ngIf="closable" type="button" class="btn-icon-close" mat-icon-button (click)="close()">
		<bp-svg-icon name="close" />
	</button>

	<bp-error-messages *ngIf="errors" class="messages" [src]="errors" [showType]="showType" />

	<bp-alert-messages *ngIf="messages" class="messages" [src]="messages" />

	<ng-content />
</div>
