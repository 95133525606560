import type { Observable } from 'rxjs';
import { of } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TranslatePipeProviderService {

	get(query: string, ..._args: any[]): Observable<string> {
		return of(query);
	}

}
