import { animate, animation, keyframes, state, style, transition, trigger, useAnimation } from '@angular/animations';

import { NORMAL, SEMI_SLOW, TIMINGS } from './variables';

export const fadeIn = animation(animate(
	TIMINGS,
	keyframes([
		style({ opacity: 0 }),
		style({ opacity: 1 }),
	]),
));

export const fadeOut = animation(animate(
	TIMINGS,
	keyframes([
		style({ opacity: 1 }),
		style({ opacity: 0 }),
	]),
));

export const FADE = trigger('fade', [
	transition(':enter', useAnimation(fadeIn, NORMAL)),
	transition(':leave', useAnimation(fadeOut, NORMAL)),
]);

export const FADE_IN = trigger('fadeIn', [
	transition(':enter', useAnimation(fadeIn, NORMAL)),
]);

export const FADE_IN_SEMI_SLOW = trigger('fadeInSemiSlow', [
	transition(':enter', useAnimation(fadeIn, SEMI_SLOW)),
]);

export const FADE_OUT_DURATION = NORMAL;

export const FADE_OUT = trigger('fadeOut', [
	transition(':leave', useAnimation(fadeOut, FADE_OUT_DURATION)),
]);

const floatFadeOut = animation([
	style({ position: 'absolute' }),
	animate(
		TIMINGS,
		keyframes([
			style({ opacity: 1 }),
			style({ opacity: 0 }),
		]),
	),
]);

export const FADE_IN_FLOAT_FADE_OUT = trigger('fadeInFloatFadeOut', [
	transition(':enter', useAnimation(fadeIn, NORMAL)),
	transition(':leave', useAnimation(floatFadeOut, NORMAL)),
]);

export const FADE_IN_FIXED_TRIGGERED = trigger('fadeInFixedTriggered', [
	state('false', style({ opacity: 0 })),
	transition('0 => 1', useAnimation(fadeIn, NORMAL)),
]);
