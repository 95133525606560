import type { Observable, OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { fromMutate } from './mutate.static';

export function mutate<T>(mutateCallback: (measured: T) => void): OperatorFunction<T, T> {
	return (source$: Observable<T>) => source$.pipe(
		switchMap(v => fromMutate(() => void mutateCallback(v))
			.pipe(map(() => v))),
	);
}
