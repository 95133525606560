import { compact, isArray, isEmpty, uniq } from 'lodash-es';

import { MASK_SUBSTITUTION_CHAR } from '@bp/shared/utilities/core';

import { PaymentCardBrand, PaymentCardBrandPattern, PaymentCardBrandRangePattern } from './payment-card-brand';

// inspired by https://github.com/braintree/credit-card-type/commit/8abd60b7be4b466807827584eb9956702742fbbb

type PaymentCardBrandMatch = [ cardBrand: PaymentCardBrand, matchStrength: number | null];

export class PaymentCardsUtils {

	private static readonly __testOrder = [
		PaymentCardBrand.visa,
		PaymentCardBrand.masterCard,
		PaymentCardBrand.amex,
		PaymentCardBrand.dinersclub,
		PaymentCardBrand.discover,
		PaymentCardBrand.jcb,
		PaymentCardBrand.unionpay,
		PaymentCardBrand.maestro,
		PaymentCardBrand.elo,
		PaymentCardBrand.mir,
		PaymentCardBrand.hiper,
		PaymentCardBrand.hipercard,
		PaymentCardBrand.isracard,
		PaymentCardBrand.verve,
	];

	private static readonly __currentMonth = new Date().getMonth() + 1;

	private static readonly __currentYear = Number(new Date()
		.getFullYear()
		.toString());

	static readonly maskDotChar = MASK_SUBSTITUTION_CHAR;

	private static readonly __allMaskDotCharsRegexp = new RegExp(`[${ this.maskDotChar }]+`, 'ug');

	static readonly brands = PaymentCardBrand.getList();

	static findBrand(name: string): PaymentCardBrand | null {
		return PaymentCardBrand.parse(name);
	}

	static guessBrandsByCardNumber(cardNumber: string): PaymentCardBrand[] {
		if (cardNumber.length === 0)
			return [];

		cardNumber = cardNumber
			.replace(this.__allMaskDotCharsRegexp, '0')
			.replace(/\D/gu, '');

		const cardBrandMatches = compact(
			this.__testOrder.flatMap(
				testCardBrand => this.__matchPaymentCardBrand(cardNumber, testCardBrand),
			),
		);

		const bestMatch = this.__findBestMatch(cardBrandMatches);

		return bestMatch
			? [ bestMatch ]
			: uniq(cardBrandMatches.map(([ cardBrand ]) => cardBrand));
	}

	static guessBestMatchingBrandByCardNumber(cardNumber: string): PaymentCardBrand | null {
		const matches = this.guessBrandsByCardNumber(cardNumber);

		return matches.length === 1 ? matches[0] : null;
	}

	static formatMaskedCardNumber(number: string): string {
		return number
			.replace(/\s+/ug, '')
			.replace(/\*/ug, this.maskDotChar)
			.replace(new RegExp(`([\\d|${ this.maskDotChar }]{4})`, 'ug'), '$1 ')
			.trim();
	}

	static toExpireDateString(month: number, year: number): string {
		return `${ month < 10 ? '0' : '' }${ month }/${ year < 100 ? year + 2000 : year }`;
	}

	static parseExpireDateString(expire: string | null | undefined): { month: number; year: number } {
		const [ rawMonth, rawYear ] = <[ string, string ]> (expire ?? '')
			.replace(/\s/ug, '')
			.split('/');

		const month = Number(rawMonth);

		let year = rawYear && (rawYear.length === 2 || rawYear.length === 4)
			? Number(rawYear)
			: Number.NaN;

		if (year < 100)
			year += 2000;

		return { month, year };
	}

	static isExpired(expire: string): boolean {
		const { month, year } = this.parseExpireDateString(expire);

		return year < this.__currentYear || year === this.__currentYear && month < this.__currentMonth;
	}

	private static __findBestMatch(cardBrandMatches: PaymentCardBrandMatch[]): PaymentCardBrand | null {
		if (!this.__hasEnoughMatchingCardBrandsToDetermineBestMatch(cardBrandMatches))
			return null;

		return cardBrandMatches.reduce((bestCardBrandMatch, cardBrandMatch) => {
			if (bestCardBrandMatch[1]! < cardBrandMatch[1]!)
				return cardBrandMatch;

			return bestCardBrandMatch;
		})[0];
	}

	private static __hasEnoughMatchingCardBrandsToDetermineBestMatch(
		cardBrandMatches: PaymentCardBrandMatch[],
	): boolean {
		const numberOfCardBrandsMatchesWithMatchStrength = cardBrandMatches
			.filter(([ , matchStrength ]) => matchStrength !== null)
			.length;

		return numberOfCardBrandsMatchesWithMatchStrength > 0
			&& numberOfCardBrandsMatchesWithMatchStrength === cardBrandMatches.length;
	}

	private static __matchPaymentCardBrand(
		cardNumber: string,
		testCardBrand: PaymentCardBrand,
	): PaymentCardBrandMatch[] | null {
		const results: PaymentCardBrandMatch[] = [];

		for (const pattern of testCardBrand.scheme.patterns) {
			if (!this.__matchCardNumberAgainstCardBrandPatterns(cardNumber, pattern))
				continue;

			const patternLength = isArray(pattern)
				? String(pattern[0]).length
				: String(pattern).length;

			results.push([
				testCardBrand,
				cardNumber.length >= patternLength ? patternLength : null,
			]);
		}

		if (isEmpty(results)) {
			if (testCardBrand === PaymentCardBrand.isracard && testCardBrand.scheme.lengths.includes(cardNumber.length))
				results.push([ testCardBrand, cardNumber.length ]);
			else
				return null;
		}

		return results;
	}

	private static __matchCardNumberAgainstCardBrandPatterns(
		cardNumber: string,
		cardBrandPattern: PaymentCardBrandPattern,
	): boolean {
		return isArray(cardBrandPattern)
			? this.__matchCardNumberAgainstCardBrandRangePattern(cardNumber, cardBrandPattern)
			: this.__matchCardNumberAgainstCardBrandRegularPattern(cardNumber, cardBrandPattern);
	}

	private static __matchCardNumberAgainstCardBrandRangePattern(
		cardNumber: string,
		[ startRange, endRange ]: PaymentCardBrandRangePattern,
	): boolean {
		const maxLengthToCheck = String(startRange).length;
		const cardNumberMatchingSegment = cardNumber.slice(0, maxLengthToCheck);
		const integerCardNumberMatchingSegment = Number.parseInt(cardNumberMatchingSegment);

		const croppedStartRange = Number.parseInt(
			String(startRange).slice(0, cardNumberMatchingSegment.length),
		);
		const croppedEndRange = Number.parseInt(
			String(endRange).slice(0, cardNumberMatchingSegment.length),
		);

		return integerCardNumberMatchingSegment >= croppedStartRange
			&& integerCardNumberMatchingSegment <= croppedEndRange;
	}

	private static __matchCardNumberAgainstCardBrandRegularPattern(
		cardNumber: string,
		cardBrandRegularPattern: number,
	): boolean {
		const stringCardBrandRegularPattern = String(cardBrandRegularPattern);

		return cardNumber.startsWith(stringCardBrandRegularPattern)
			|| stringCardBrandRegularPattern.startsWith(cardNumber);
	}
}
