/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/angular-ivy';
import { lastValueFrom } from 'rxjs';

import { MetaReducer } from '@ngrx/store';

import { IEnvironmentConfig } from '@bp/shared/typings';
import { isPresent, toSnakeCaseKeys } from '@bp/shared/utilities/core';

import { AsyncVoidSubject, OptionalBehaviorSubject, ZoneService } from '@bp/frontend/rxjs';

import { SentryReporter } from './sentry.reporter';
import { IReporter, IReporterUser, ReporterError } from './reporter.interface';
import { ReportEventSource } from './report-event-source.enum';

export class TelemetryReporter implements IReporter {

	readonly logMetaReducer: MetaReducer | null;

	private readonly __reportersMaps = new Map<string, IReporter | null>();

	private readonly __reportersReady$ = new AsyncVoidSubject();

	private readonly __userSessionRecordingURL$ = new OptionalBehaviorSubject<string>();

	userSessionRecordingUrl$ = this.__userSessionRecordingURL$.asObservable();

	private readonly __environment = this._options.environment;

	private readonly __captureConsoleErrors: boolean = this._options.captureConsoleErrors;

	constructor(private readonly _options: {
		environment: IEnvironmentConfig;
		captureConsoleErrors: boolean;
		shouldSanitizeSensitiveData: boolean;
	}) {
		this.__initDefaultReporters();

		this.logMetaReducer = this.__createReportersMetaReducer();
	}

	identifyUser(user: IReporterUser): void {
		void ZoneService.runOutsideAngular(async () => {
			await this.__reportersReady();

			void this.__reportersMaps.forEach(
				reporter => void reporter?.identifyUser(toSnakeCaseKeys(user)),
			);
		});
	}

	captureError(error: ReporterError, source: ReportEventSource): void {
		void ZoneService.runOutsideAngular(async () => {
			await this.__reportersReady();

			void this.__reportersMaps.forEach(
				reporter => void reporter?.captureError(error, source),
			);
		});
	}

	captureMessage(message: string): void {
		void ZoneService.runOutsideAngular(async () => {
			await this.__reportersReady();

			void this.__reportersMaps.forEach(
				reporter => void reporter?.captureMessage(message),
			);
		});
	}

	warn(message: string, ...payload: any[]): void {
		void ZoneService.runOutsideAngular(async () => {
			await this.__reportersReady();

			void this.__reportersMaps.forEach(
				reporter => void reporter?.warn(message, ...payload),
			);
		});
	}

	log(message: string, ...payload: any[]): void {
		void ZoneService.runOutsideAngular(async () => {
			await this.__reportersReady();

			void this.__reportersMaps.forEach(
				reporter => void reporter?.log(message, ...payload),
			);
		});
	}

	track(eventName: string, payload: any): void {
		void ZoneService.runOutsideAngular(async () => {
			await this.__reportersReady();

			void this.__reportersMaps.forEach(
				reporter => void reporter?.track(eventName, payload),
			);
		});
	}

	setTags(tags: Parameters<IReporter['setTags']>[0]): void {
		void ZoneService.runOutsideAngular(async () => {
			await this.__reportersReady();

			void this.__reportersMaps.forEach(
				reporter => void reporter?.setTags(toSnakeCaseKeys(tags)),
			);
		});
	}

	registerReporter(name: string, reporter: IReporter): void {
		this.__reportersMaps.set(name, reporter);
	}

	initLogrocketReporterOnDemand(): void {
		if (!this.__environment.logrocket || !this.__environment.logrocketOnDemand || this.__reportersMaps.has(this.__environment.logrocket))
			return;

		this.__reportersMaps.set(this.__environment.logrocket, null);

		void ZoneService.runOutsideAngular(async () => this.__reportersMaps.set(
			this.__environment.logrocket!,
			await this.__initLogrocket(this.__environment.logrocket!),
		));
	}

	private async __reportersReady(): Promise<void> {
		await lastValueFrom(this.__reportersReady$);
	}

	private __initDefaultReporters(): void {
		void ZoneService.runOutsideAngular(async () => {
			const defaultReporters = await Promise.all([
				this.__tryInitDefaultSentry(),

				this.__tryInitDefaultLogrocket(),
			]);

			defaultReporters
				.filter(isPresent)
				.forEach(([ id, reporter ]) => void this.__reportersMaps.set(id, reporter));

			this.__reportersReady$.complete();
		});
	}

	private __tryInitDefaultSentry(): [ id: string, reporter: IReporter ] | null {
		if (!this.__environment.sentry)
			return null;

		const useReplay = this.__environment.sentryReplay && !this.__environment.sentryReplayExcludedInitialLocationPaths?.some(path => location.pathname.includes(path));

		return [
			this.__environment.sentry,
			new SentryReporter({
				appId: this.__environment.sentry,
				environment: this.__environment.name,
				release: this.__environment.appVersion.releaseTitle,
				useReplay,
				captureConsoleErrors: this.__captureConsoleErrors,
			}),
		];
	}

	private async __tryInitDefaultLogrocket(): Promise<[ id: string, reporter: IReporter ] | null> {
		if (!this.__environment.logrocket || this.__environment.logrocketOnDemand)
			return Promise.resolve(null);

		return [
			this.__environment.logrocket,
			await this.__initLogrocket(this.__environment.logrocket),
		];
	}

	private async __initLogrocket(appId: string): Promise<IReporter> {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { LogRocketReporter } = await import('./logrocket.reporter');

		return new LogRocketReporter({
			appId,
			release: this.__environment.appVersion.releaseTitle,
			captureConsoleErrors: this.__captureConsoleErrors,
			shouldSanitizeSensitiveData: this._options.shouldSanitizeSensitiveData,
			onSessionURLChange: (url: string) => {
				this.__userSessionRecordingURL$.next(url);

				void Sentry.configureScope(
					scope => scope.setExtra('userSessionRecording', url),
				);
			},
		});
	}

	private __createReportersMetaReducer(): MetaReducer | null {
		const buildReportersMetaReducer: () => MetaReducer = () => metaReducer => [ ...this.__reportersMaps.values() ]
			.filter(reporter => !!reporter?.logMetaReducer)
			.reduce(
				(reducer, reporter) => reporter!.logMetaReducer!(reducer),
				metaReducer,
			);

		const cacheTable: Record<number, MetaReducer | undefined> = {};

		return ZoneService.runOutsideAngular(() => metaReducer => (state, action) => {
			const reportersMetaReducer = cacheTable[this.__reportersMaps.size] ??= buildReportersMetaReducer();

			return reportersMetaReducer(metaReducer)(state, action);
		});
	}

}
