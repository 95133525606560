<div
	*ngIf="!hiddenOnFailure || (_loadingFailed$ | async) === false"
	class="downloading flex"
	[class.downloading]="_isDownloading$ | async"
	[style.width.px]="size"
	[style.height.px]="size"
	[style.border-radius.px]="size"
>
	<div *ngIf="_hasPlaceholder && (_loadingFailed$ | async) === false" class="placeholder">
		@if (_imgPlaceholderDirective) {
			<ng-content select="bp-img-placeholder, [bpImgPlaceholder]" />
		} @else {
			<bp-svg-icon name="image" />
		}
	</div>

	<div *ngIf="(_loadingFailed$ | async) === true" class="loading-failed">
		@if (_imgErrorDirective) {
			<ng-content select="bp-img-error, [bpImgError]" />
		} @else {
			<bp-svg-icon name="broken-image" />
		}
	</div>

	<img *ngIf="_src$ | async as src" [src]="src" [attr.alt]="alt" [style.border-radius.px]="size" />
</div>
