import { QuicklinkDirective } from 'ngx-quicklink';

import { NgModule } from '@angular/core';
import { MAT_SORT_DEFAULT_OPTIONS } from '@angular/material/sort';

import { ViewportOverflownObserverDirective } from '@bp/frontend/directives/viewport-overflow-observer';

import { DelayedRenderStructuralDirective } from './delayed-render.structural.directive';
import { DisabledDirective } from './disabled.directive';
import { ElementRefDirective } from './element-ref.directive';
import { FeatureUnderDevelopmentStructuralDirective } from './feature-under-development.structural.directive';
import { HoverDirective } from './hover.directive';
import { RenderWhenIdleStructuralDirective } from './render-when-idle.structural.directive';
import {
	BpQuicklinkDirective, OutletLinkRelativeToTargetDirective, OutletLinkRelativeToTargetWithHrefDirective,
	RouterLinkNoOutletsWithHrefDirective
} from './router-links';
import { SortDirective } from './sort.directive';
import { TargetBlankDirective } from './target-blank.directive';
import { RouterLinkActiveOnIdInUrlDirective } from './router-link-active-on-id-in-url.directive';
import { TrimOnPasteDirective } from './trim-on-paste.directive';
import { IfEmptyNgForDirective } from './if-empty-ng-for.directive';
import { ImagesLoadingHandlerDirective } from './images-loading-handler.directive';
import { StopClickPropagationDirective } from './stop-click-propagation.directive';
import { ScrollIntoViewDirective } from './scroll-into-view.directive';

const exposedDirectives = [
	IfEmptyNgForDirective,
	RouterLinkNoOutletsWithHrefDirective,
	OutletLinkRelativeToTargetDirective,
	OutletLinkRelativeToTargetWithHrefDirective,
	TargetBlankDirective,
	SortDirective,
	DelayedRenderStructuralDirective,
	DisabledDirective,
	HoverDirective,
	FeatureUnderDevelopmentStructuralDirective,
	RenderWhenIdleStructuralDirective,
	ElementRefDirective,
	BpQuicklinkDirective,
	TrimOnPasteDirective,
	RouterLinkActiveOnIdInUrlDirective,
	StopClickPropagationDirective,
	ImagesLoadingHandlerDirective,
	ScrollIntoViewDirective,
];

const standalone = [
	ViewportOverflownObserverDirective,
];

@NgModule({
	imports: [ standalone ],
	exports: [ exposedDirectives, standalone ],
	declarations: exposedDirectives,
	providers: [
		{ provide: MAT_SORT_DEFAULT_OPTIONS, useValue: { disableClear: true } },
		{ provide: QuicklinkDirective, useClass: BpQuicklinkDirective },
	],
})
export class SharedDirectivesCoreModule { }
