<bp-alert-messages [src]="errors">
	<ng-template let-error>
		<ng-container
			*ngIf="error.message === _internalServerError || error.message === _noResponseError; else errorMessageTpl"
		>
			Oops, something has gone wrong.
			<small> Please check your connection and try again later or contact support if the problem persists </small>
		</ng-container>

		<ng-template #errorMessageTpl>
			{{ error.message }}
			<small *ngIf="error.type && showType">{{ error.type }}</small>
		</ng-template>
	</ng-template>
</bp-alert-messages>
