import countriesList from 'countries-list';
import { isString } from 'lodash-es';

import { Country } from './country';
import type { CountryCode } from './country-codes';

const { countries } = countriesList;

export class Countries {

	private static readonly __skippedCountryCodes = [ 'BV', 'GS', 'HM' ]; // these countries don't exist in dotnet countries lib

	static list = Countries.__buildCountries();

	static listWithWorldwide = [
		...Countries.list,

		new Country({ code: 'ALL', name: 'Worldwide' }), // ALL backend value for worldwide
	];

	private static readonly __countriesByCodeMap = new Map(Countries.listWithWorldwide.map(
		country => [ country.code, country ],
	));

	static worldwide = Countries.get('ALL');

	private static readonly __lowerCaseCountryNames = Countries.listWithWorldwide.map(v => v.lowerCaseName);

	private static __buildCountries(): Country[] {
		return Object
			.entries(countries)
			.map(([ code, country ]) => new Country({
				code: <CountryCode> code,
				name: country.name,
				displayName: country.name === country.native
					? country.name
					: `${ country.name } (${ country.native })`,
				currency: country.currency,
				dialCodeOrCodes: country.phone,
			}))
			.filter(country => !Countries.__skippedCountryCodes.includes(country.code))
			.sort((a, b) => a.displayName.localeCompare(b.displayName));
	}

	static findByName(countryName: string | null): Country | null {
		const lowerCaseCountryName = countryName?.toLowerCase();

		return lowerCaseCountryName
			? Countries.listWithWorldwide.find(v => v.lowerCaseName === lowerCaseCountryName) ?? null
			: null;
	}

	static get(code: CountryCode): Country {
		return Countries.__countriesByCodeMap.get(code)!;
	}

	static findByCodeString(code: unknown): Country | null {
		return isString(code)
			? Countries.__countriesByCodeMap.get(<CountryCode> code.toUpperCase()) ?? null
			: null;
	}

	static fromCodes(codes: CountryCode[]): Country[] {
		return codes.map(code => Countries.get(code));
	}

	static includes(countryName: string): boolean {
		return Countries.__lowerCaseCountryNames.includes(countryName.toLowerCase());
	}

	static includesCode(code?: string | null): code is CountryCode {
		return !!code && !!Countries.findByCodeString(code);
	}

}
