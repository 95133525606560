import m from 'moment';
import { Observable, interval } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { Memoize } from 'typescript-memoize';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { momentMapper } from '@bp/shared/models/metadata';

import { MomentBasePipe } from './moment-base.pipe';

@Pipe({
	standalone: true,
	name: 'momentIsSameOrBeforeNow',
})
export class MomentIsSameOrBeforeNowPipe extends MomentBasePipe implements PipeTransform {

	@Memoize((momentInput: m.MomentInput) => `${ momentInput }`)
	transform(momentInput: m.MomentInput): Observable<boolean> {
		return this._momentTimezoneService.timezone$.pipe(
			switchMap(() => this.__momentIsSameOrBeforeNowCheckedEach30Seconds(momentInput)),
		);
	}

	private __momentIsSameOrBeforeNowCheckedEach30Seconds(
		momentInput: m.MomentInput,
	): Observable<boolean> {
		const moment = momentMapper(momentInput);
		const getMomentIsSameOrBeforeNow = (): boolean => moment.isSameOrBefore(m());

		return interval(1000 * 30)
			.pipe(
				map(getMomentIsSameOrBeforeNow),
				startWith(getMomentIsSameOrBeforeNow()),
			);
	}

}
