import { Response } from 'miragejs';
import { snakeCase } from 'lodash-es';

import { RecordsPage } from '@bp/shared/models/common';
import { IErrorMessage, RESPONSE_STATUS_CODE_MESSAGES, ResponseStatusCode } from '@bp/shared/models/core';

export const MOCK_EMPTY_OK_API_RESPONSE = new Response(200, {}, {
	response: {
		status: 'OK',
		code: 200,
	},
});

export function buildMockOkDotnetApiResponse<T = any>(result: T): Response {
	return new Response(
		200,
		{
			'x-app-version': '0.0.0-mock',
			'x-powered-by': 'Dazzling backend',
			// 'x-pull-request-repository': 'dotnet',
			// 'x-pull-request-title': 'BP-000, PSM-000 Dotnet Mock Pull Request',
			// 'x-pull-request-number': '000',
			// 'x-pull-request-updated-at': '2024-01-01T00:00:00.000Z',
			// 'x-pull-request-updated-by': 'mock',
		},
		{
			response: {
				status: 'OK',
				code: 200,
			},
			result,
		},
	);
}

export function buildMockOkNestApiResponse<T = any>(result: T): Response {
	return new Response(
		200,
		{
			'x-app-version': '0.0.0-mock',
			'x-powered-by': 'Noble backend',
			// 'x-pull-request-repository': 'nest',
			// 'x-pull-request-title': 'BP-000 Nest Mock Pull Request',
			// 'x-pull-request-number': '000',
			// 'x-pull-request-updated-at': '2024-01-01T00:00:00.000Z',
			// 'x-pull-request-updated-by': 'mock',
		},
		{
			response: {
				status: 'OK',
				code: 200,
			},
			result,
		},
	);
}

export function buildMockOkRecordsApiResponse<T = any>(records: T[]): Response {
	return buildMockOkDotnetApiResponse<RecordsPage>({ firstPage: null, nextPageCursor: null, records });
}

export function buildMockBadRequestApiResponse(errors?: IErrorMessage | IErrorMessage[]): Response {
	return buildMockErrorApiResponse(400, errors);
}

export function buildMockErrorApiResponse(
	code: ResponseStatusCode,
	errors?: IErrorMessage | IErrorMessage[],
): Response {
	const message = `Mock ${ RESPONSE_STATUS_CODE_MESSAGES[code] } Api Response`;

	return new Response(code, {}, {
		response: {
			code,
			status: RESPONSE_STATUS_CODE_MESSAGES[code],
			message,
		},
		result: errors ?? {
			type: snakeCase(message),
			field: null,
			message: `${ code }: ${ message }`,
		},
	});
}

export const MOCK_NOT_FOUND_API_RESPONSE = buildMockErrorApiResponse(404);

export const MOCK_FORBIDDEN_API_RESPONSE = buildMockErrorApiResponse(403);

export const MOCK_UNAUTHORIZED_API_RESPONSE = buildMockErrorApiResponse(401);

export const MOCK_INTERNAL_SERVER_ERROR_API_RESPONSE = buildMockErrorApiResponse(500);
