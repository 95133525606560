import { fromEvent, merge, Observable, of, timer } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

export function networkIsOnline$(): Observable<boolean> {
	return merge(
		fromEvent(window, 'online'),
		fromEvent(window, 'offline'),
	)
		.pipe(
			map(() => navigator.onLine),
			startWith(navigator.onLine),
		);
}

export function whenNetworkIsOfflineFor$(timeout: number): Observable<boolean> {
	return networkIsOnline$()
		.pipe(
			switchMap(isOnline => isOnline
				? of(true)
				: timer(timeout).pipe(map(() => false))),
			map(isOnline => !isOnline),
		);
}
