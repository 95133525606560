import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import type { PropertyMetadata } from '@bp/shared/models/metadata';

@Pipe({
	name: 'colspan',
})
export class PropertiesMetadataColspanPipe implements PipeTransform {
	transform(columnsMetadata: PropertyMetadata[], columnIndex: number, additionalSpan: number = 0): number | null {
		let counter = additionalSpan + 1; // 1 is the spanning cell itself
		let headlessColumnIndex = columnIndex;

		headlessColumnIndex--;

		while (columnsMetadata[headlessColumnIndex]?.table!.headless) {
			counter++;

			headlessColumnIndex--;
		}

		return counter === 1 ? null : counter;
	}
}
