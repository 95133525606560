import { isNil, last } from 'lodash-es';

import { paginateArray, IPageQueryParams, RecordsPage } from '@bp/shared/models/common';
import { IApiResponse } from '@bp/shared/models/core';

export function buildMockPagedApiResponse(
	records: Record<string, unknown>[],
	{ page, limit, nextSyncTimestamp, lastSyncTimestamp }: IPageQueryParams & Pick<RecordsPage, 'lastSyncTimestamp' | 'nextSyncTimestamp'>,
): IApiResponse<RecordsPage> {
	const currentPage = `${ Number.isNaN(Number(page)) ? 1 : Number(page) }`;
	const paginated = paginateArray(records, { page: currentPage, limit });
	const hasMoreRecordsLeft = !isNil(records[records.indexOf(last(paginated)!) + 1]);
	const nextPageCursor: string | null = hasMoreRecordsLeft
		? `${ Number(currentPage) + 1 }`
		: null;

	return {
		response: {
			status: 'success',
			code: 200,
		},
		result: {
			firstPage: !!page,
			nextPageCursor,
			records: paginated,
			lastSyncTimestamp,
			nextSyncTimestamp,
		},
	};
}
