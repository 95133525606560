
import { combineLatest, map } from 'rxjs';

import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

import { Destroyable } from '@bp/frontend/models/common';
import { FADE } from '@bp/frontend/animations';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';

import { TurnstileService } from '../../turnstile.service';

@Component({
	selector: 'bp-turnstile-widget-spinner',
	standalone: true,
	imports: [ CommonModule, MatIconModule, SharedFeaturesTooltipModule ],
	templateUrl: './turnstile-widget-spinner.component.html',
	styleUrls: [ './turnstile-widget-spinner.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class TurnstileWidgetSpinnerComponent extends Destroyable {

	private readonly __turnstileService = inject(TurnstileService);

	@HostBinding('class.enabled')
	protected readonly _enabled = this.__turnstileService.enabled;

	protected readonly _noToken$ = this.__turnstileService.token$.pipe(map(token => !token));

	protected readonly _pending$ = combineLatest(
		this._noToken$,
		this.__turnstileService.visitorChallenged$,
	)
		.pipe(map(([ noToken, visitorChallenged ]) => noToken && !visitorChallenged));

}
